export const priceFromatter = (price: string | number | null) =>
  price
    ? price
        .toString()
        .split("")
        .reverse()
        .map((e, i) => (i % 3 == 0 ? `${e} ` : e))
        .reverse()
        .join("")
    : "";

export const rounder = (number: number, k: number) =>
  Math.round(number / k) * k;
