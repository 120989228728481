import { useQuery } from "react-query";
import request from "../request";

interface Tariff {
  id: number;
  name: string;
  alias: string;
  desc: string;
  amount_of_money: number | null;
  category: number | null;
  tariff_image: string | null;
  car_image: string | null;
  pin_default: string | null;
  pin_busy: string | null;
  fixed_interest_rate: null;
  air_conditioner: true;
  conditioner_enabled_by_default: false;
  is_fixed_price: false;
  group: {
    id: number | null;
    title: string | null;
    "is-hidden": boolean;
  };
  minimal_cost: number | null;
  minimal_km: number | null;
  price_per_km_in: number | null;
  price_per_km_out: number | null;
  free_waiting_time: number | null;
  price_per_minute: number | null;
  driving_cost_per_minute: number | null;
  door_to_door_price: number | null;
  drivers_minimal_cost: number | null;
}

export const useGetTariffsInfo = ({
  tariff_ids,
}: {
  tariff_ids?: Array<number>;
}) =>
  useQuery(
    ["tariffs-info", tariff_ids],
    () =>
      request.public
        .get<{ tariffs: Array<Tariff> }>(
          `/v1/tariffs/${
            tariff_ids ? `?tariff_ids=${tariff_ids.join(",")}` : ""
          }`
        )
        .then((res) => res.data),
    { enabled: !!tariff_ids?.length }
  );
