import React, { useMemo } from "react";
import { PropsWithChildren } from "react";
import useMultilevelMenuReducer from "./multi-level-menu.reducer";
import {
  MultiLevelMenuActions,
  MultiLevelMenuState,
} from "./multi-level-menu.types";

interface MultilevelMenuContext extends MultiLevelMenuState {
  dispatch: React.Dispatch<MultiLevelMenuActions>;
}

const MultilevelMenuCtx = React.createContext<MultilevelMenuContext>(
  {} as MultilevelMenuContext
);

const MultiLevelMenuProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [multilevelMenuState, dispatch] = useMultilevelMenuReducer();

  const stateValues = useMemo(() => ({ dispatch, ...multilevelMenuState }), [
    multilevelMenuState,
    dispatch,
  ]);

  return (
    <MultilevelMenuCtx.Provider value={stateValues}>
      {children}
    </MultilevelMenuCtx.Provider>
  );
};

export default MultiLevelMenuProvider;

export const useMultiLevelMenu = () => {
  const context = React.useContext(MultilevelMenuCtx);
  if (context === undefined) {
    throw new Error(
      "useMultiLevelMenu must be used within a NavigationProvider"
    );
  }
  return context;
};
