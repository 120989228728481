import { IconType } from "../../types/utility.types";

const AppStoreIcon: IconType = (props) => (
  <svg
    width={120}
    height={40}
    viewBox="0 0 120 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2012_145517)">
      <g filter="url(#filter0_b_2012_145517)">
        <path
          d="M7.7055 39.9979C7.39544 39.9979 7.09287 39.9938 6.78525 39.9867C6.14797 39.9782 5.51219 39.9212 4.88311 39.8162C4.29652 39.7124 3.72828 39.5193 3.19712 39.2433C2.67083 38.9696 2.19081 38.6106 1.77545 38.1803C1.35408 37.755 1.00327 37.2617 0.736925 36.72C0.467642 36.1748 0.281283 35.5904 0.184338 34.9872C0.0796461 34.339 0.0230035 33.6836 0.0148985 33.0266C0.00844657 32.8061 0 32.0718 0 32.0718V7.91685C0 7.91685 0.00899612 7.19389 0.0149494 6.98148C0.0227099 6.32553 0.0790232 5.67114 0.183402 5.02396C0.280526 4.41908 0.467029 3.83299 0.736457 3.286C1.00183 2.74468 1.35069 2.25105 1.76952 1.82424C2.18788 1.39337 2.66943 1.03261 3.19662 0.755098C3.72656 0.479972 4.29373 0.288241 4.87913 0.18633C5.51027 0.0802662 6.14827 0.022923 6.78775 0.0147815L7.706 0.00201416H112.289L113.218 0.0152939C113.852 0.0230308 114.484 0.0798624 115.109 0.185305C115.701 0.288495 116.274 0.481563 116.81 0.758162C117.866 1.31742 118.725 2.20222 119.267 3.28855C119.533 3.83177 119.716 4.41307 119.812 5.01273C119.918 5.66522 119.977 6.32475 119.989 6.98608C119.992 7.28221 119.992 7.6003 119.992 7.91685C120 8.30897 120 8.68219 120 9.05848V30.9424C120 31.3223 120 31.693 119.992 32.0667C119.992 32.4068 119.992 32.7183 119.988 33.0388C119.976 33.6884 119.918 34.3361 119.814 34.9769C119.719 35.5845 119.534 36.1735 119.264 36.7232C118.996 37.259 118.647 37.748 118.231 38.1721C117.815 38.6048 117.334 38.9658 116.807 39.2413C116.272 39.5194 115.7 39.7132 115.109 39.8162C114.48 39.9218 113.845 39.9788 113.207 39.9867C112.909 39.9938 112.597 39.9979 112.294 39.9979L111.191 40L7.7055 39.9979Z"
          fill="#171725"
          fillOpacity="0.5"
        />
      </g>
      <path
        d="M24.8389 20.3007C24.8496 19.466 25.072 18.6477 25.4851 17.9217C25.8983 17.1957 26.4889 16.5858 27.202 16.1486C26.749 15.5035 26.1514 14.9725 25.4566 14.598C24.7619 14.2234 23.9891 14.0156 23.1997 13.991C21.5157 13.8147 19.8832 14.9958 19.0251 14.9958C18.1504 14.9958 16.8292 14.0085 15.4064 14.0376C14.4862 14.0673 13.5893 14.3341 12.8033 14.8122C12.0172 15.2903 11.3687 15.9632 10.921 16.7655C8.98154 20.1139 10.4282 25.035 12.286 27.7416C13.2155 29.0669 14.3018 30.5474 15.7232 30.4949C17.1142 30.4374 17.6337 29.6104 19.3127 29.6104C20.9761 29.6104 21.4635 30.4949 22.9138 30.4615C24.4063 30.4374 25.3467 29.1303 26.2436 27.7924C26.9115 26.848 27.4254 25.8043 27.7663 24.6999C26.8992 24.3341 26.1592 23.722 25.6386 22.9397C25.118 22.1574 24.8399 21.2396 24.8389 20.3007Z"
        fill="white"
      />
      <path
        d="M22.0994 12.2109C22.9132 11.2367 23.3142 9.98451 23.2171 8.72028C21.9738 8.8505 20.8253 9.44306 20.0005 10.3799C19.5972 10.8376 19.2884 11.37 19.0916 11.9468C18.8948 12.5235 18.8139 13.1333 18.8536 13.7413C19.4754 13.7477 20.0907 13.6132 20.6529 13.3482C21.2151 13.0831 21.7097 12.6942 22.0994 12.2109Z"
        fill="white"
      />
      <path
        d="M42.4215 27.1396H37.6748L36.5349 30.4961H34.5244L39.0204 18.0781H41.1093L45.6052 30.4961H43.5604L42.4215 27.1396ZM38.1664 25.5908H41.929L40.0742 20.1435H40.0223L38.1664 25.5908Z"
        fill="white"
      />
      <path
        d="M55.315 25.9697C55.315 28.7832 53.8049 30.5908 51.5261 30.5908C50.9488 30.6209 50.3747 30.4883 49.8695 30.2082C49.3643 29.928 48.9484 29.5117 48.6694 29.0068H48.6263V33.4912H46.7627V21.4423H48.5666V22.9482H48.6009C48.8927 22.4457 49.3156 22.0316 49.8246 21.7497C50.3337 21.4679 50.9098 21.3289 51.4918 21.3476C53.796 21.3476 55.315 23.164 55.315 25.9697ZM53.3995 25.9697C53.3995 24.1367 52.4495 22.9316 51.0002 22.9316C49.5763 22.9316 48.6185 24.1621 48.6185 25.9697C48.6185 27.7939 49.5763 29.0156 51.0002 29.0156C52.4495 29.0156 53.3995 27.8193 53.3995 25.9697Z"
        fill="white"
      />
      <path
        d="M65.3081 25.9697C65.3081 28.7832 63.7981 30.5908 61.5192 30.5908C60.942 30.621 60.3678 30.4883 59.8626 30.2082C59.3574 29.9281 58.9415 29.5117 58.6626 29.0068H58.6195V33.4912H56.7559V21.4424H58.5597V22.9483H58.594C58.8858 22.4458 59.3087 22.0316 59.8177 21.7498C60.3268 21.4679 60.903 21.329 61.4849 21.3477C63.7892 21.3477 65.3081 23.1641 65.3081 25.9697ZM63.3926 25.9697C63.3926 24.1367 62.4427 22.9317 60.9933 22.9317C59.5694 22.9317 58.6117 24.1621 58.6117 25.9697C58.6117 27.794 59.5694 29.0156 60.9933 29.0156C62.4427 29.0156 63.3926 27.8194 63.3926 25.9697H63.3926Z"
        fill="white"
      />
      <path
        d="M71.9126 27.0361C72.0507 28.2676 73.2503 29.0761 74.8897 29.0761C76.4605 29.0761 77.5906 28.2675 77.5906 27.1572C77.5906 26.1933 76.909 25.6162 75.2951 25.2207L73.6812 24.833C71.3946 24.2822 70.333 23.2158 70.333 21.4853C70.333 19.3427 72.2054 17.871 74.8642 17.871C77.4956 17.871 79.2995 19.3427 79.3602 21.4853H77.4789C77.3663 20.246 76.339 19.498 74.8378 19.498C73.3365 19.498 72.3092 20.2548 72.3092 21.3564C72.3092 22.2343 72.9653 22.7509 74.5704 23.1464L75.9424 23.4823C78.4974 24.0849 79.559 25.1083 79.559 26.9247C79.559 29.248 77.7032 30.703 74.7516 30.703C71.9899 30.703 70.1253 29.2821 70.0049 27.036L71.9126 27.0361Z"
        fill="white"
      />
      <path
        d="M83.5802 19.2998V21.4424H85.3067V22.9141H83.5802V27.9053C83.5802 28.6807 83.9259 29.042 84.6849 29.042C84.8898 29.0384 85.0945 29.0241 85.2979 28.999V30.4619C84.9567 30.5255 84.6099 30.5543 84.2628 30.5478C82.4246 30.5478 81.7078 29.8593 81.7078 28.1035V22.9141H80.3877V21.4424H81.7078V19.2998H83.5802Z"
        fill="white"
      />
      <path
        d="M86.3066 25.9697C86.3066 23.1211 87.9891 21.3311 90.6126 21.3311C93.245 21.3311 94.9196 23.1211 94.9196 25.9697C94.9196 28.8262 93.2538 30.6084 90.6126 30.6084C87.9724 30.6084 86.3066 28.8262 86.3066 25.9697ZM93.0207 25.9697C93.0207 24.0156 92.1227 22.8623 90.6126 22.8623C89.1025 22.8623 88.2055 24.0244 88.2055 25.9697C88.2055 27.9316 89.1025 29.0762 90.6126 29.0762C92.1227 29.0762 93.0207 27.9316 93.0207 25.9697H93.0207Z"
        fill="white"
      />
      <path
        d="M96.457 21.4424H98.2345V22.9834H98.2776C98.3979 22.5021 98.6806 22.0768 99.0783 21.7789C99.476 21.481 99.9644 21.3287 100.461 21.3476C100.676 21.3469 100.89 21.3702 101.1 21.417V23.1553C100.829 23.0726 100.546 23.0347 100.263 23.043C99.9919 23.032 99.722 23.0796 99.4714 23.1825C99.2209 23.2854 98.9956 23.4411 98.8111 23.639C98.6266 23.8369 98.4873 24.0723 98.4026 24.3289C98.318 24.5856 98.29 24.8575 98.3206 25.126V30.4961H96.457L96.457 21.4424Z"
        fill="white"
      />
      <path
        d="M109.692 27.8369C109.441 29.4805 107.836 30.6084 105.782 30.6084C103.141 30.6084 101.502 28.8437 101.502 26.0127C101.502 23.1729 103.15 21.3311 105.704 21.3311C108.216 21.3311 109.796 23.0518 109.796 25.7969V26.4336H103.383V26.5459C103.354 26.8791 103.395 27.2148 103.506 27.5306C103.616 27.8464 103.793 28.1352 104.024 28.3778C104.255 28.6203 104.535 28.8111 104.846 28.9374C105.156 29.0637 105.49 29.1226 105.826 29.1103C106.266 29.1515 106.708 29.0498 107.086 28.8203C107.463 28.5909 107.757 28.246 107.922 27.8369L109.692 27.8369ZM103.392 25.1348H107.931C107.948 24.8352 107.902 24.5354 107.797 24.2541C107.692 23.9729 107.53 23.7164 107.321 23.5006C107.112 23.2849 106.86 23.1145 106.582 23.0003C106.304 22.8861 106.005 22.8305 105.704 22.8369C105.401 22.8351 105.1 22.8933 104.819 23.008C104.538 23.1227 104.283 23.2918 104.068 23.5054C103.853 23.7191 103.683 23.973 103.567 24.2527C103.451 24.5323 103.391 24.8321 103.392 25.1348V25.1348Z"
        fill="white"
      />
      <path
        d="M37.9328 8.73101C38.3235 8.70305 38.7156 8.76191 39.0807 8.90335C39.4458 9.04478 39.7749 9.26526 40.0443 9.54889C40.3136 9.83251 40.5165 10.1722 40.6382 10.5435C40.76 10.9148 40.7976 11.3083 40.7483 11.6959C40.7483 13.6021 39.7151 14.6979 37.9328 14.6979H35.7715V8.73101H37.9328ZM36.7008 13.854H37.829C38.1082 13.8707 38.3876 13.825 38.6468 13.7204C38.9061 13.6158 39.1387 13.4548 39.3277 13.2493C39.5168 13.0437 39.6574 12.7987 39.7395 12.5321C39.8216 12.2655 39.8431 11.9839 39.8023 11.708C39.8401 11.4332 39.8165 11.1534 39.7331 10.8887C39.6498 10.6241 39.5088 10.3811 39.3202 10.1771C39.1316 9.97322 38.9001 9.81341 38.6423 9.70917C38.3845 9.60494 38.1068 9.55885 37.829 9.57422H36.7008V13.854Z"
        fill="white"
      />
      <path
        d="M41.7982 12.4443C41.7698 12.1484 41.8037 11.8499 41.8978 11.5678C41.9919 11.2857 42.1441 11.0264 42.3446 10.8064C42.5451 10.5864 42.7895 10.4107 43.0621 10.2904C43.3347 10.1701 43.6296 10.108 43.9277 10.108C44.2258 10.108 44.5206 10.1701 44.7932 10.2904C45.0658 10.4107 45.3102 10.5864 45.5107 10.8064C45.7112 11.0264 45.8634 11.2857 45.9575 11.5678C46.0516 11.8499 46.0855 12.1484 46.0572 12.4443C46.0861 12.7406 46.0525 13.0396 45.9587 13.3221C45.8648 13.6046 45.7127 13.8644 45.5122 14.0848C45.3116 14.3052 45.067 14.4814 44.7942 14.6019C44.5213 14.7225 44.2261 14.7847 43.9277 14.7847C43.6292 14.7847 43.334 14.7225 43.0612 14.6019C42.7883 14.4814 42.5437 14.3052 42.3431 14.0848C42.1426 13.8644 41.9905 13.6046 41.8966 13.3221C41.8028 13.0396 41.7692 12.7406 41.7982 12.4443ZM45.1405 12.4443C45.1405 11.4683 44.7008 10.8975 43.9291 10.8975C43.1545 10.8975 42.7187 11.4683 42.7187 12.4444C42.7187 13.4282 43.1545 13.9946 43.9291 13.9946C44.7008 13.9946 45.1405 13.4243 45.1405 12.4443H45.1405Z"
        fill="white"
      />
      <path
        d="M51.7181 14.6978H50.7937L49.8604 11.3813H49.7899L48.8605 14.6978H47.9449L46.7002 10.1948H47.6041L48.413 13.6308H48.4796L49.408 10.1948H50.2629L51.1913 13.6308H51.2618L52.0668 10.1948H52.9579L51.7181 14.6978Z"
        fill="white"
      />
      <path
        d="M54.0049 10.1948H54.8628V10.9101H54.9293C55.0423 10.6532 55.2329 10.4378 55.4744 10.294C55.716 10.1502 55.9965 10.0852 56.2769 10.1079C56.4966 10.0914 56.7172 10.1244 56.9224 10.2045C57.1275 10.2846 57.312 10.4097 57.4621 10.5706C57.6122 10.7314 57.7241 10.9239 57.7895 11.1337C57.8549 11.3435 57.8721 11.5653 57.8398 11.7827V14.6977H56.9487V12.0058C56.9487 11.2822 56.6333 10.9223 55.9743 10.9223C55.8251 10.9154 55.6762 10.9407 55.5377 10.9966C55.3993 11.0524 55.2746 11.1374 55.1722 11.2458C55.0698 11.3542 54.9921 11.4834 54.9444 11.6245C54.8967 11.7656 54.8802 11.9154 54.896 12.0635V14.6977H54.0049L54.0049 10.1948Z"
        fill="white"
      />
      <path
        d="M59.2598 8.43701H60.1509V14.6978H59.2598V8.43701Z"
        fill="white"
      />
      <path
        d="M61.3899 12.4444C61.3616 12.1484 61.3955 11.8499 61.4897 11.5678C61.5838 11.2857 61.736 11.0263 61.9365 10.8064C62.137 10.5864 62.3815 10.4106 62.6541 10.2903C62.9267 10.17 63.2216 10.1079 63.5197 10.1079C63.8178 10.1079 64.1126 10.17 64.3853 10.2903C64.6579 10.4106 64.9023 10.5864 65.1028 10.8064C65.3034 11.0263 65.4556 11.2857 65.5497 11.5678C65.6438 11.8499 65.6778 12.1484 65.6494 12.4444C65.6783 12.7406 65.6447 13.0396 65.5508 13.3221C65.457 13.6046 65.3048 13.8645 65.1043 14.0849C64.9037 14.3053 64.6591 14.4814 64.3862 14.6019C64.1133 14.7225 63.8181 14.7847 63.5197 14.7847C63.2212 14.7847 62.926 14.7225 62.6531 14.6019C62.3802 14.4814 62.1356 14.3053 61.9351 14.0849C61.7345 13.8645 61.5824 13.6046 61.4885 13.3221C61.3946 13.0396 61.361 12.7406 61.3899 12.4444ZM64.7323 12.4444C64.7323 11.4683 64.2926 10.8975 63.5209 10.8975C62.7463 10.8975 62.3105 11.4683 62.3105 12.4444C62.3105 13.4283 62.7463 13.9947 63.5209 13.9947C64.2926 13.9947 64.7323 13.4244 64.7323 12.4444H64.7323Z"
        fill="white"
      />
      <path
        d="M66.5879 13.4243C66.5879 12.6138 67.1931 12.1465 68.2674 12.0801L69.4905 12.0098V11.6211C69.4905 11.1455 69.1752 10.877 68.5661 10.877C68.0686 10.877 67.7239 11.0591 67.625 11.3774H66.7622C66.8533 10.604 67.5829 10.1079 68.6072 10.1079C69.7393 10.1079 70.3778 10.6699 70.3778 11.6211V14.6977H69.5199V14.0649H69.4494C69.3063 14.2919 69.1053 14.477 68.867 14.6012C68.6288 14.7254 68.3617 14.7843 68.0931 14.7719C67.9035 14.7916 67.7119 14.7714 67.5307 14.7127C67.3494 14.654 67.1825 14.5581 67.0406 14.4311C66.8988 14.3042 66.7853 14.149 66.7073 13.9756C66.6293 13.8021 66.5886 13.6144 66.5879 13.4243ZM69.4905 13.0395V12.6631L68.3878 12.7334C67.766 12.7749 67.4839 12.9858 67.4839 13.3828C67.4839 13.7881 67.8365 14.0239 68.3213 14.0239C68.4633 14.0382 68.6068 14.0239 68.7432 13.9819C68.8796 13.9398 69.0061 13.8707 69.1152 13.7789C69.2243 13.6871 69.3137 13.5743 69.3782 13.4473C69.4427 13.3202 69.4809 13.1816 69.4905 13.0395Z"
        fill="white"
      />
      <path
        d="M71.5488 12.4444C71.5488 11.0215 72.2823 10.1201 73.4232 10.1201C73.7054 10.1072 73.9855 10.1746 74.2307 10.3145C74.4759 10.4544 74.6761 10.661 74.808 10.9101H74.8746V8.43701H75.7657V14.6978H74.9118V13.9863H74.8413C74.6993 14.2338 74.4921 14.4378 74.2421 14.5763C73.9922 14.7148 73.709 14.7825 73.4232 14.772C72.2745 14.772 71.5488 13.8706 71.5488 12.4444ZM72.4694 12.4444C72.4694 13.3994 72.9209 13.9741 73.6759 13.9741C74.427 13.9741 74.8912 13.3911 74.8912 12.4483C74.8912 11.5098 74.4221 10.9185 73.6759 10.9185C72.9257 10.9185 72.4694 11.4971 72.4694 12.4444H72.4694Z"
        fill="white"
      />
      <path
        d="M79.4525 12.4443C79.4241 12.1484 79.458 11.8499 79.5521 11.5678C79.6462 11.2857 79.7984 11.0264 79.9989 10.8064C80.1994 10.5864 80.4438 10.4107 80.7164 10.2904C80.989 10.1701 81.2839 10.108 81.582 10.108C81.8801 10.108 82.1749 10.1701 82.4475 10.2904C82.7201 10.4107 82.9645 10.5864 83.165 10.8064C83.3655 11.0264 83.5177 11.2857 83.6118 11.5678C83.7059 11.8499 83.7398 12.1484 83.7115 12.4443C83.7404 12.7406 83.7068 13.0396 83.613 13.3221C83.5191 13.6046 83.367 13.8644 83.1665 14.0848C82.9659 14.3052 82.7213 14.4814 82.4485 14.6019C82.1756 14.7225 81.8804 14.7847 81.582 14.7847C81.2835 14.7847 80.9883 14.7225 80.7154 14.6019C80.4426 14.4814 80.198 14.3052 79.9974 14.0848C79.7969 13.8644 79.6448 13.6046 79.5509 13.3221C79.4571 13.0396 79.4235 12.7406 79.4525 12.4443ZM82.7948 12.4443C82.7948 11.4683 82.3551 10.8975 81.5834 10.8975C80.8088 10.8975 80.373 11.4683 80.373 12.4444C80.373 13.4282 80.8088 13.9946 81.5834 13.9946C82.3551 13.9946 82.7948 13.4243 82.7948 12.4443Z"
        fill="white"
      />
      <path
        d="M84.9072 10.1948H85.7651V10.9101H85.8317C85.9447 10.6532 86.1352 10.4378 86.3767 10.294C86.6183 10.1502 86.8988 10.0852 87.1792 10.1079C87.3989 10.0914 87.6195 10.1244 87.8247 10.2045C88.0299 10.2846 88.2144 10.4097 88.3645 10.5706C88.5146 10.7314 88.6265 10.9239 88.6918 11.1337C88.7572 11.3435 88.7744 11.5653 88.7422 11.7827V14.6977H87.851V12.0058C87.851 11.2822 87.5357 10.9223 86.8766 10.9223C86.7274 10.9154 86.5785 10.9407 86.4401 10.9966C86.3016 11.0524 86.1769 11.1374 86.0745 11.2458C85.9721 11.3542 85.8944 11.4834 85.8467 11.6245C85.7991 11.7656 85.7826 11.9154 85.7984 12.0635V14.6977H84.9072V10.1948Z"
        fill="white"
      />
      <path
        d="M93.7779 9.07373V10.2153H94.7563V10.9639H93.7779V13.2793C93.7779 13.751 93.9728 13.9575 94.4164 13.9575C94.53 13.9572 94.6435 13.9503 94.7563 13.937V14.6772C94.5962 14.7058 94.4341 14.721 94.2715 14.7226C93.2805 14.7226 92.8858 14.375 92.8858 13.5068V10.9638H92.1689V10.2153H92.8858V9.07373H93.7779Z"
        fill="white"
      />
      <path
        d="M95.9736 8.43701H96.857V10.9185H96.9275C97.0459 10.6591 97.2417 10.4425 97.4881 10.2982C97.7345 10.1539 98.0195 10.0888 98.3043 10.1118C98.5229 10.1 98.7414 10.1364 98.9442 10.2184C99.1471 10.3004 99.3293 10.4261 99.4778 10.5864C99.6264 10.7468 99.7376 10.9378 99.8035 11.146C99.8695 11.3541 99.8886 11.5742 99.8595 11.7905V14.6978H98.9674V12.0098C98.9674 11.2905 98.6315 10.9263 98.0018 10.9263C97.8486 10.9137 97.6946 10.9347 97.5504 10.9878C97.4062 11.0408 97.2754 11.1247 97.1671 11.2334C97.0588 11.3421 96.9756 11.4732 96.9234 11.6173C96.8712 11.7614 96.8512 11.9152 96.8648 12.0679V14.6977H95.9737L95.9736 8.43701Z"
        fill="white"
      />
      <path
        d="M105.056 13.4819C104.935 13.8935 104.673 14.2495 104.315 14.4876C103.957 14.7258 103.527 14.8309 103.099 14.7847C102.802 14.7925 102.506 14.7357 102.233 14.6182C101.96 14.5006 101.715 14.3252 101.517 14.1041C101.318 13.8829 101.171 13.6214 101.084 13.3376C100.997 13.0537 100.973 12.7544 101.013 12.4605C100.974 12.1656 100.998 11.8656 101.085 11.581C101.172 11.2963 101.319 11.0335 101.516 10.8105C101.714 10.5874 101.957 10.4092 102.229 10.288C102.502 10.1668 102.797 10.1054 103.095 10.1079C104.352 10.1079 105.11 10.9639 105.11 12.3779V12.688H101.921V12.7378C101.907 12.9031 101.928 13.0694 101.982 13.2261C102.037 13.3829 102.123 13.5266 102.236 13.6481C102.35 13.7695 102.487 13.866 102.64 13.9314C102.793 13.9968 102.958 14.0297 103.124 14.0278C103.337 14.0533 103.553 14.0151 103.744 13.9178C103.936 13.8206 104.094 13.6689 104.198 13.4819L105.056 13.4819ZM101.921 12.0308H104.202C104.213 11.8796 104.193 11.7278 104.142 11.5851C104.09 11.4424 104.01 11.3119 103.905 11.2021C103.801 11.0922 103.674 11.0054 103.534 10.9472C103.393 10.8891 103.242 10.8608 103.09 10.8643C102.936 10.8623 102.783 10.8912 102.641 10.9491C102.498 11.0071 102.368 11.0929 102.259 11.2017C102.15 11.3104 102.064 11.4397 102.006 11.5821C101.948 11.7245 101.919 11.8771 101.921 12.0308H101.921Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_2012_145517"
        x={-62}
        y="-61.998"
        width={244}
        height="163.998"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={31} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2012_145517"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2012_145517"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_2012_145517">
        <rect width={120} height={40} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppStoreIcon;
