import React, { useContext, useState } from "react";
import { ModeTypes } from "../types";
import { WelcomeModalWrapper, IconWrapper } from "./styles";
import ICMytaxiIcon from "../../../../../components/icons/ic-mytaxi";
import Button from "../../../../../components/buttons/button";
import { TextWrapper, WelcomeAuthSubTitle, WelcomeAuthTitle } from "../styles";
import Input from "../../../../../components/input";
import { AuthCtx } from "../context/auth";
import { setPhoneNumber } from "../context/auth-actions";
import { useSentSMS } from "../../../../../services/mutations/use-sent-sms";
import * as yup from "yup";
import { modes } from "..";
import FlagUz from "../../../../../components/icons/flag-uz";
import { Box } from "@mui/material";
import {
  formatUZB,
  numberCheck,
  numberCleaner,
} from "../../../../../utils/number-formatter";
import { useTranslation } from "react-i18next";

export const WelcomeAuth: React.FC<ModeTypes> = ({ setCurrentMode }) => {
  const { phone_number, dispatch } = useContext(AuthCtx);
  const [phone_num, setPhone_num] = useState(formatUZB(phone_number));
  const [error, setError] = useState("");
  const { mutate, isLoading } = useSentSMS();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    phone_number: yup
      .string()
      .required(t("required field"))
      .min(12, t("please enter valid phone number"))
      .max(13, t("please enter valid phone number")),
  });

  const nextStep = (e: any) => {
    e.preventDefault();

    let number = numberCleaner(phone_num);

    dispatch(setPhoneNumber(number));

    schema
      .validate({ phone_number: number })
      .then((valid) => {
        return (
          valid &&
          number &&
          mutate(number, { onSuccess: () => setCurrentMode(modes.SEND_CODE) })
        );
      })
      .catch((err) => setError(err.message));
  };

  return (
    <React.Fragment>
      <WelcomeModalWrapper>
        <IconWrapper>
          <ICMytaxiIcon />
        </IconWrapper>
        <TextWrapper>
          <WelcomeAuthTitle>{t("welcome")}</WelcomeAuthTitle>
          <WelcomeAuthSubTitle>{t("complete order")}</WelcomeAuthSubTitle>
        </TextWrapper>
        <form style={{ width: "100%" }} onSubmit={nextStep}>
          <Input
            sx={{ mb: (theme) => theme.spacing(2), fontWeight: 500 }}
            variant="filled"
            fullWidth
            inputProps={{
              autoFocus: true,
              value: phone_num,
              placeholder: t("phone_number"),
              startAdornment: (
                <React.Fragment>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FlagUz />
                  </Box>
                </React.Fragment>
              ),
              onChange: (e) => {
                setError("");

                setPhone_num((p) => numberCheck(e.target.value) || p);
              },
            }}
            error={!!error}
            errorMessage={error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={nextStep}
            loading={isLoading}
          >
            {t("next")}
          </Button>
        </form>
      </WelcomeModalWrapper>
    </React.Fragment>
  );
};
