import { Box } from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../../configs/colors.style";

export const ModalOverlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  padding: 30px 0;
  display: flex;
  animation: opacityAnimation ease 0.3s;
  @keyframes opacityAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ModalCard = styled.div`
  margin: auto;
  background: ${COLORS.white};
  position: relative;
  min-width: 0;
  min-height: 50px;
  width: 440px;
  max-width: calc(100vw - 40px);
  z-index: 100;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  animation: fadein ease 0.3s;

  @keyframes fadein {
    0% {
      bottom: -10px;
      opacity: 0;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 44px;
  height: 44px;
  right: -15px;
  top: -16px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.divider};
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const ModanIconWrapper = styled(Box)`
  margin-left: auto;
  margin-right: auto;
`;

export const ModalBtnsWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  padding-top: 20px;
  width: 100%;
  button {
    padding: 20px;
    font-weight: 700;
    font-size: 17px;
  }
`;

export const ModalChildrenWrapper = styled(Box)`
  max-height: 85vh;
  overflow-y: auto;
  scrollbar-width: 0;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0;
  }
`;
