import styled from "styled-components";

export const WelcomeModalWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.span`
  position: relative;
  margin-bottom: 24px;
`;
