import { IconType } from "../../types/utility.types";

const SignInIcon: IconType = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="12.5"
      y="9.16667"
      width="1.66667"
      height="10"
      rx="0.833333"
      transform="rotate(90 12.5 9.16667)"
      fill="#434343"
    />
    <path
      d="M8.33848 5.83333C8.33848 6.29356 7.96554 6.66666 7.5055 6.66666C7.04547 6.66666 6.67253 6.29356 6.67253 5.83333V4.99999C6.67253 3.15905 8.16427 1.66666 10.0044 1.66666H15.0015C16.8417 1.66666 18.3334 3.15905 18.3334 4.99999V15C18.3334 16.8409 16.8417 18.3333 15.0015 18.3333H9.99864C8.15849 18.3333 6.66675 16.8409 6.66675 15V14.1667C6.66675 13.7064 7.03968 13.3333 7.49972 13.3333C7.95976 13.3333 8.33269 13.7064 8.33269 14.1667V15C8.33269 15.9205 9.07856 16.6667 9.99864 16.6667H15.0015C15.9216 16.6667 16.6675 15.9205 16.6675 15V4.99999C16.6675 4.07952 15.9216 3.33333 15.0015 3.33333H10.0044C9.08434 3.33333 8.33848 4.07952 8.33848 4.99999V5.83333Z"
      fill="#434343"
    />
    <path
      d="M10.2441 8.08925C9.91864 7.76381 9.91864 7.23618 10.2441 6.91074C10.5695 6.5853 11.0972 6.5853 11.4226 6.91074L13.9226 9.41074C14.248 9.73618 14.248 10.2638 13.9226 10.5893L11.4226 13.0893C11.0972 13.4147 10.5695 13.4147 10.2441 13.0893C9.91864 12.7638 9.91864 12.2362 10.2441 11.9107L12.1548 9.99999L10.2441 8.08925Z"
      fill="#434343"
    />
  </svg>
);

export default SignInIcon;
