import { Box } from "@mui/material";
import styled from "styled-components";

export const MenuListItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  color: ${({ theme }) => theme.palette.text.primary};
  :hover {
    color: ${({ theme }) => theme.palette.error.main};
  }
  white-space: nowrap;
  cursor: pointer;
`;
