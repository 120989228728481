import { IconType } from "../../types/utility.types";

const Money: IconType = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.17787 3.89447C3.6305 3.7478 3.06787 4.07264 2.9212 4.62001L0.265544 14.5311C0.118875 15.0784 0.443711 15.6411 0.991083 15.7877L19.8221 20.8335C20.3695 20.9802 20.9321 20.6553 21.0788 20.1079L23.7344 10.1969C23.8811 9.64952 23.5562 9.08689 23.0089 8.94022L4.17787 3.89447ZM15.9644 13.4262C15.3778 15.6157 13.1272 16.915 10.9377 16.3284C8.74825 15.7417 7.44891 13.4912 8.03558 11.3017C8.62225 9.11219 10.8728 7.81285 13.0623 8.39952C15.2518 8.9862 16.5511 11.2367 15.9644 13.4262Z"
      fill="#50BD7B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25239 6.20752C1.6857 6.20752 1.22632 6.66691 1.22632 7.23359V17.4943C1.22632 18.0609 1.6857 18.5203 2.25239 18.5203H21.7477C22.3144 18.5203 22.7737 18.0609 22.7737 17.4943V7.23359C22.7737 6.66691 22.3144 6.20752 21.7477 6.20752H2.25239ZM14.0521 12.6204C14.0521 13.8955 13.0185 14.9291 11.7435 14.9291C10.4684 14.9291 9.43483 13.8955 9.43483 12.6204C9.43483 11.3454 10.4684 10.3118 11.7435 10.3118C13.0185 10.3118 14.0521 11.3454 14.0521 12.6204Z"
      fill="#50BD7B"
    />
  </svg>
);

export default Money;
