import { Box } from "@mui/material";
import styled from "styled-components";
import AdaptiveBg from "../../assets/images/adaptive-bg.png";

export const AdaptiveWrapper = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${AdaptiveBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
`;
