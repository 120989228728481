import { useReducer } from "react";
import { AuthActions, initialStateType } from "./auth-types";

const initialState: Partial<initialStateType> = {
  phone_number: "998",
  back_button: false,
  is_modal_open: false,
};

const AuthReducer = (
  state: initialStateType,
  action: AuthActions
): initialStateType => {
  switch (action.type) {
    case "SET_PHONE_NUMBER":
      return {
        ...state,
        phone_number: action.payload.phone_number,
      };
    case "SET_BACK_BUTTON":
      return {
        ...state,
        back_button: action.payload.back_button,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        is_modal_open: false,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        is_modal_open: true,
      };
    default:
      return state;
  }
};

export const useAuthReducer = () => useReducer(AuthReducer, initialState);
