import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import Button from "../../../../components/buttons/button";
import LogoutIcon from "../../../../components/icons/logout-icon";
import { Modal } from "../../../../components/modal/modal";
import queryClient from "../../../../configs/react-query.config";
import { useAppDispatch } from "../../../../redux/hook";
import { logout, resetAuthSlice } from "../../../../redux/slices/auth-slice";
import { resetModalSlice } from "../../../../redux/slices/modal-slice";
import { resetOrderSlice } from "../../../../redux/slices/order-slice";
import { resetRouteSlice } from "../../../../redux/slices/route-slice";

const LogoutModal: React.FC<{ close: () => void; isOpen: boolean }> = ({
  close,
  isOpen,
}) => {
  const appDispatch = useAppDispatch();
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      closeIcon={false}
      style={{ padding: "20px", width: "100%", maxWidth: "440px" }}
    >
      <LogoutIcon style={{ margin: "0 auto" }} />
      <Typography
        variant="body1"
        fontWeight="600"
        sx={{ textAlign: "center", mt: "20px" }}
      >
        {t("sign out subtitle")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: theme.spacing(1.5),
          mt: theme.spacing(2.5),
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{ width: "50%" }}
          onClick={close}
        >
          {t("cancellation")}
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ width: "50%" }}
          onClick={() => {
            localStorage.clear();
            appDispatch(resetAuthSlice());
            appDispatch(resetModalSlice());
            appDispatch(resetOrderSlice());
            appDispatch(resetRouteSlice());
            appDispatch(logout());
            queryClient.invalidateQueries();
            close();
          }}
        >
          {t("sign out")}
        </Button>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
