import styled from "styled-components";
import { COLORS } from "../../../../configs/colors.style";

export const AuthWrapper = styled.div`
  /* position: relative; */
`;
export const WelcomeAuthTitle = styled.span`
  font-weight: 800;
  font-size: 27px;
  margin-bottom: 10px;
  color: ${COLORS.textMain};
`;

export const WelcomeAuthSubTitle = styled.span`
  position: relative;
  color: ${COLORS.textGray};
  font-weight: 500;
  font-size: 15px;
  max-width: 286px;
  margin-bottom: 24px;
`;
export const TextWrapper = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
