import { IconType } from "../../types/utility.types";

const AppleStoreIcon: IconType = (props) => (
  <svg
    width={140}
    height={47}
    viewBox="0 0 140 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_482_372269)">
      <path
        d="M128.851 3.06797e-05H11.155C10.726 3.06797e-05 10.3021 3.06797e-05 9.87425 0.00238068C9.51607 0.00473068 9.16075 0.0115574 8.79913 0.0173032C8.01353 0.0265878 7.22978 0.0960003 6.45469 0.224937C5.68068 0.356681 4.93092 0.605057 4.23077 0.961662C3.53147 1.32128 2.8925 1.78858 2.33704 2.34659C1.77867 2.90303 1.31322 3.5462 0.958592 4.25137C0.602988 4.95513 0.356412 5.70928 0.227378 6.48777C0.097118 7.26527 0.0270238 8.05174 0.0177246 8.84012C0.00686756 9.20037 0.00572102 9.56183 0 9.92213V37.0814C0.00572102 37.4462 0.00686756 37.7997 0.0177246 38.1646C0.0270266 38.953 0.0971208 39.7394 0.227378 40.5168C0.356055 41.2958 0.602647 42.0504 0.958592 42.7544C1.31306 43.4573 1.77857 44.0979 2.33704 44.6512C2.89039 45.2117 3.52978 45.6793 4.23077 46.0362C4.93092 46.3937 5.68059 46.6436 6.45469 46.7774C7.22991 46.9053 8.01358 46.9747 8.79913 46.9851C9.16075 46.9931 9.51607 46.9977 9.87425 46.9977C10.3021 47 10.726 47 11.155 47H128.851C129.272 47 129.699 47 130.119 46.9977C130.476 46.9977 130.842 46.9931 131.198 46.9851C131.982 46.9753 132.764 46.9058 133.538 46.7774C134.315 46.6427 135.067 46.3929 135.77 46.0362C136.471 45.6791 137.11 45.2115 137.662 44.6512C138.219 44.0957 138.686 43.4556 139.045 42.7544C139.398 42.0499 139.642 41.2953 139.769 40.5168C139.9 39.7393 139.972 38.953 139.986 38.1646C139.991 37.7997 139.991 37.4462 139.991 37.0814C140 36.6545 140 36.23 140 35.7962V11.205C140 10.7747 140 10.3478 139.991 9.92213C139.991 9.56183 139.991 9.20037 139.986 8.84007C139.972 8.05163 139.9 7.26533 139.769 6.48772C139.642 5.70969 139.398 4.9556 139.045 4.25132C138.323 2.83789 137.178 1.68736 135.77 0.961557C135.067 0.605822 134.314 0.357513 133.538 0.224832C132.764 0.0953264 131.982 0.0258898 131.198 0.0171387C130.842 0.0114047 130.476 0.00451918 130.119 0.00222793C129.699 -0.00012207 129.272 -0.00012207 128.851 -0.00012207V3.06797e-05Z"
        fill="#EFEDED"
      />
      <path
        d="M9.88203 45.9719C9.52557 45.9719 9.17772 45.9673 8.82406 45.9593C8.09142 45.9497 7.3605 45.8857 6.63728 45.7677C5.96291 45.651 5.30963 45.4341 4.69899 45.1239C4.09394 44.8163 3.54209 44.413 3.06458 43.9294C2.58015 43.4515 2.17684 42.8972 1.87064 42.2885C1.56106 41.6758 1.34681 41.0191 1.23536 40.3413C1.115 39.6129 1.04988 38.8764 1.04057 38.1382C1.03315 37.8904 1.02344 37.0653 1.02344 37.0653V9.92211C1.02344 9.92211 1.03378 9.1097 1.04062 8.87103C1.04955 8.13393 1.11429 7.39858 1.23428 6.67135C1.34594 5.99163 1.56036 5.33304 1.8701 4.71838C2.17518 4.1101 2.57625 3.5554 3.05776 3.07579C3.53872 2.59162 4.09234 2.18622 4.69841 1.87438C5.30765 1.56522 5.9597 1.34977 6.6327 1.23525C7.3583 1.11607 8.09176 1.05163 8.82694 1.04248L9.8826 1.02814H130.116L131.184 1.04306C131.913 1.05175 132.639 1.11562 133.358 1.2341C134.038 1.35006 134.697 1.56701 135.313 1.87783C136.527 2.50627 137.515 3.50053 138.139 4.72125C138.443 5.33167 138.655 5.98488 138.765 6.65873C138.886 7.39193 138.954 8.13306 138.968 8.8762C138.971 9.20896 138.971 9.56639 138.971 9.92211C138.981 10.3627 138.981 10.7821 138.981 11.205V35.7962C138.981 36.223 138.981 36.6396 138.971 37.0595C138.971 37.4416 138.971 37.7917 138.967 38.1519C138.953 38.8818 138.886 39.6097 138.767 40.3298C138.658 41.0125 138.445 41.6743 138.135 42.292C137.827 42.8941 137.426 43.4436 136.947 43.9202C136.469 44.4064 135.916 44.8121 135.31 45.1217C134.695 45.4342 134.037 45.652 133.358 45.7677C132.635 45.8863 131.904 45.9504 131.171 45.9593C130.829 45.9673 130.47 45.9719 130.122 45.9719L128.853 45.9742L9.88203 45.9719Z"
        fill="white"
      />
      <path
        d="M28.9722 23.8533C28.9847 22.8725 29.2441 21.9109 29.7262 21.0579C30.2082 20.2049 30.8973 19.4883 31.7292 18.9746C31.2007 18.2165 30.5035 17.5927 29.6929 17.1526C28.8823 16.7125 27.9808 16.4683 27.0598 16.4393C25.0952 16.2322 23.1905 17.62 22.1894 17.62C21.1689 17.62 19.6275 16.4599 17.9677 16.4942C16.8941 16.529 15.8477 16.8426 14.9306 17.4043C14.0135 17.966 13.257 18.7567 12.7346 19.6994C10.472 23.6338 12.1597 29.416 14.3271 32.5963C15.4116 34.1536 16.6789 35.8931 18.3373 35.8314C19.9601 35.7638 20.5661 34.7922 22.525 34.7922C24.4656 34.7922 25.0342 35.8314 26.7262 35.7922C28.4675 35.7638 29.5647 34.228 30.611 32.656C31.3902 31.5464 31.9897 30.32 32.3875 29.0223C31.3758 28.5926 30.5125 27.8732 29.9052 26.954C29.2978 26.0348 28.9733 24.9564 28.9722 23.8533Z"
        fill="#121C25"
      />
      <path
        d="M25.7748 14.3478C26.7243 13.2031 27.192 11.7318 27.0788 10.2463C25.6282 10.3993 24.2884 11.0955 23.3261 12.1963C22.8556 12.7341 22.4953 13.3597 22.2657 14.0374C22.0361 14.7151 21.9417 15.4316 21.988 16.146C22.7135 16.1535 23.4313 15.9955 24.0872 15.684C24.7432 15.3726 25.3202 14.9157 25.7748 14.3478Z"
        fill="#121C25"
      />
      <path
        d="M49.4867 31.8891H43.9489L42.619 35.8329H40.2734L45.5188 21.2417H47.9557L53.2011 35.8329H50.8155L49.4867 31.8891ZM44.5225 30.0692H48.9121L46.7482 23.6686H46.6876L44.5225 30.0692Z"
        fill="#121C25"
      />
      <path
        d="M64.5285 30.5144C64.5285 33.8202 62.7667 35.9442 60.108 35.9442C59.4346 35.9796 58.7648 35.8237 58.1754 35.4946C57.5859 35.1654 57.1007 34.6762 56.7753 34.083H56.725V39.3521H54.5508V25.1948H56.6553V26.9641H56.6953C57.0357 26.3737 57.5291 25.8871 58.123 25.5559C58.7169 25.2247 59.3891 25.0615 60.0681 25.0834C62.7564 25.0835 64.5285 27.2177 64.5285 30.5144ZM62.2937 30.5144C62.2937 28.3606 61.1854 26.9446 59.4945 26.9446C57.8333 26.9446 56.7159 28.3904 56.7159 30.5144C56.7159 32.6578 57.8333 34.0933 59.4945 34.0933C61.1854 34.0933 62.2937 32.6877 62.2937 30.5144Z"
        fill="#121C25"
      />
      <path
        d="M76.1867 30.5144C76.1867 33.8202 74.4249 35.9442 71.7662 35.9442C71.0928 35.9795 70.423 35.8237 69.8336 35.4946C69.2442 35.1654 68.759 34.6762 68.4335 34.083H68.3832V39.3521H66.209V25.1947H68.3135V26.9641H68.3535C68.6939 26.3737 69.1873 25.8871 69.7812 25.5559C70.3751 25.2247 71.0473 25.0615 71.7262 25.0834C74.4146 25.0834 76.1867 27.2177 76.1867 30.5144ZM73.9519 30.5144C73.9519 28.3606 72.8436 26.9446 71.1527 26.9446C69.4915 26.9446 68.3741 28.3904 68.3741 30.5144C68.3741 32.6578 69.4915 34.0933 71.1527 34.0933C72.8436 34.0933 73.9519 32.6877 73.9519 30.5144H73.9519Z"
        fill="#121C25"
      />
      <path
        d="M83.8936 31.7674C84.0547 33.2144 85.4543 34.1644 87.3669 34.1644C89.1995 34.1644 90.518 33.2143 90.518 31.9097C90.518 30.7771 89.7228 30.099 87.8399 29.6342L85.957 29.1787C83.2893 28.5315 82.0507 27.2785 82.0507 25.2452C82.0507 22.7277 84.2353 20.9984 87.3372 20.9984C90.4071 20.9984 92.5117 22.7277 92.5825 25.2452H90.3877C90.2563 23.7891 89.0578 22.9101 87.3063 22.9101C85.5548 22.9101 84.3563 23.7994 84.3563 25.0937C84.3563 26.1253 85.1218 26.7323 86.9944 27.197L88.5951 27.5917C91.5759 28.2997 92.8144 29.5023 92.8144 31.6365C92.8144 34.3663 90.6493 36.076 87.2058 36.076C83.9839 36.076 81.8085 34.4065 81.668 31.7673L83.8936 31.7674Z"
        fill="#121C25"
      />
      <path
        d="M97.5059 22.6772V25.1948H99.5201V26.924H97.5059V32.7887C97.5059 33.6998 97.9092 34.1243 98.7946 34.1243C99.0338 34.1201 99.2725 34.1033 99.5098 34.0738V35.7927C99.1117 35.8674 98.7071 35.9012 98.3022 35.8937C96.1577 35.8937 95.3214 35.0847 95.3214 33.0216V26.924H93.7812V25.1948H95.3213V22.6772H97.5059Z"
        fill="#121C25"
      />
      <path
        d="M100.688 30.5144C100.688 27.1673 102.65 25.064 105.711 25.064C108.782 25.064 110.736 27.1672 110.736 30.5144C110.736 33.8707 108.793 35.9648 105.711 35.9648C102.631 35.9648 100.688 33.8707 100.688 30.5144ZM108.521 30.5144C108.521 28.2183 107.473 26.8632 105.711 26.8632C103.949 26.8632 102.903 28.2287 102.903 30.5144C102.903 32.8196 103.949 34.1645 105.711 34.1645C107.473 34.1645 108.521 32.8196 108.521 30.5144H108.521Z"
        fill="#121C25"
      />
      <path
        d="M112.525 25.1948H114.599V27.0055H114.649C114.79 26.44 115.12 25.9402 115.584 25.5902C116.048 25.2402 116.617 25.0612 117.197 25.0835C117.448 25.0826 117.698 25.1099 117.942 25.165V27.2074C117.626 27.1104 117.296 27.0658 116.965 27.0755C116.649 27.0626 116.334 27.1185 116.042 27.2394C115.75 27.3603 115.487 27.5433 115.272 27.7758C115.057 28.0084 114.894 28.2849 114.795 28.5865C114.696 28.8881 114.664 29.2076 114.7 29.523V35.8329H112.525L112.525 25.1948Z"
        fill="#121C25"
      />
      <path
        d="M127.967 32.7084C127.675 34.6395 125.802 35.9648 123.406 35.9648C120.325 35.9648 118.412 33.8914 118.412 30.5649C118.412 27.2281 120.335 25.064 123.315 25.064C126.245 25.064 128.088 27.0858 128.088 30.3113V31.0594H120.607V31.1914C120.572 31.5829 120.621 31.9773 120.75 32.3484C120.879 32.7195 121.085 33.0589 121.355 33.3439C121.624 33.6289 121.951 33.853 122.313 34.0014C122.676 34.1498 123.065 34.2191 123.456 34.2046C123.97 34.253 124.486 34.1335 124.926 33.8639C125.367 33.5943 125.71 33.189 125.902 32.7083L127.967 32.7084ZM120.617 29.5333H125.913C125.932 29.1813 125.879 28.829 125.757 28.4986C125.634 28.1682 125.445 27.8667 125.201 27.6132C124.957 27.3597 124.664 27.1596 124.339 27.0253C124.014 26.8911 123.666 26.8258 123.315 26.8333C122.961 26.8312 122.61 26.8995 122.282 27.0344C121.954 27.1692 121.657 27.3678 121.406 27.6189C121.155 27.8699 120.956 28.1683 120.821 28.4969C120.685 28.8255 120.616 29.1777 120.617 29.5333V29.5333Z"
        fill="#121C25"
      />
      <path
        d="M44.2559 10.259C44.7117 10.2261 45.1691 10.2953 45.5951 10.4615C46.0211 10.6276 46.4051 10.8867 46.7193 11.22C47.0335 11.5532 47.2702 11.9524 47.4122 12.3886C47.5543 12.8249 47.5981 13.2873 47.5407 13.7427C47.5407 15.9825 46.3353 17.27 44.2559 17.27H41.7344V10.259H44.2559ZM42.8186 16.2785H44.1348C44.4605 16.298 44.7865 16.2444 45.089 16.1215C45.3914 15.9986 45.6628 15.8094 45.8833 15.5679C46.1039 15.3264 46.268 15.0385 46.3638 14.7252C46.4595 14.4119 46.4845 14.0811 46.437 13.7569C46.4811 13.434 46.4535 13.1053 46.3563 12.7943C46.2591 12.4833 46.0945 12.1978 45.8745 11.9582C45.6545 11.7186 45.3844 11.5308 45.0836 11.4083C44.7829 11.2858 44.4589 11.2317 44.1348 11.2497H42.8186V16.2785Z"
        fill="#121C25"
      />
      <path
        d="M48.7657 14.6221C48.7325 14.2744 48.7721 13.9236 48.8819 13.5922C48.9917 13.2608 49.1693 12.956 49.4032 12.6975C49.6371 12.4391 49.9222 12.2325 50.2403 12.0912C50.5583 11.9499 50.9023 11.8769 51.2501 11.8769C51.5979 11.8769 51.9418 11.9499 52.2599 12.0912C52.5779 12.2325 52.863 12.4391 53.097 12.6975C53.3309 12.956 53.5084 13.2608 53.6182 13.5922C53.728 13.9236 53.7676 14.2744 53.7345 14.6221C53.7682 14.9702 53.7291 15.3215 53.6196 15.6535C53.5101 15.9854 53.3327 16.2907 53.0987 16.5497C52.8647 16.8087 52.5794 17.0157 52.261 17.1573C51.9426 17.2989 51.5983 17.3721 51.2501 17.3721C50.9019 17.3721 50.5575 17.2989 50.2391 17.1573C49.9208 17.0157 49.6354 16.8087 49.4015 16.5497C49.1675 16.2907 48.99 15.9854 48.8805 15.6535C48.771 15.3215 48.7319 14.9702 48.7657 14.6221ZM52.6651 14.6221C52.6651 13.4753 52.1521 12.8046 51.2518 12.8046C50.3481 12.8046 49.8397 13.4753 49.8397 14.6221C49.8397 15.7782 50.3481 16.4437 51.2518 16.4437C52.1521 16.4437 52.6651 15.7736 52.6651 14.6221H52.6651Z"
        fill="#121C25"
      />
      <path
        d="M60.3387 17.2699H59.2601L58.1713 13.3731H58.089L57.0048 17.2699H55.9365L54.4844 11.979H55.5389L56.4826 16.0163H56.5603L57.6435 11.979H58.6409L59.724 16.0163H59.8062L60.7454 11.979H61.7851L60.3387 17.2699Z"
        fill="#121C25"
      />
      <path
        d="M63.0059 11.979H64.0067V12.8195H64.0844C64.2162 12.5176 64.4385 12.2645 64.7203 12.0955C65.0021 11.9266 65.3294 11.8501 65.6565 11.8768C65.9128 11.8575 66.1702 11.8963 66.4096 11.9904C66.649 12.0845 66.8642 12.2315 67.0393 12.4205C67.2144 12.6095 67.345 12.8356 67.4212 13.0822C67.4975 13.3287 67.5176 13.5893 67.48 13.8447V17.2698H66.4403V14.1069C66.4403 13.2567 66.0724 12.8338 65.3035 12.8338C65.1294 12.8257 64.9557 12.8554 64.7942 12.921C64.6327 12.9866 64.4872 13.0865 64.3677 13.2139C64.2482 13.3412 64.1576 13.493 64.102 13.6588C64.0464 13.8247 64.0271 14.0006 64.0455 14.1746V17.2699H63.0059L63.0059 11.979Z"
        fill="#121C25"
      />
      <path
        d="M69.1367 9.91351H70.1764V17.2699H69.1367V9.91351Z"
        fill="#121C25"
      />
      <path
        d="M71.6231 14.6221C71.59 14.2744 71.6296 13.9236 71.7394 13.5921C71.8492 13.2607 72.0268 12.9559 72.2607 12.6974C72.4947 12.439 72.7798 12.2324 73.0979 12.0911C73.416 11.9498 73.7599 11.8768 74.1078 11.8768C74.4556 11.8768 74.7995 11.9498 75.1176 12.0911C75.4357 12.2324 75.7208 12.439 75.9548 12.6974C76.1887 12.9559 76.3663 13.2607 76.4761 13.5921C76.5859 13.9236 76.6255 14.2744 76.5924 14.6221C76.6262 14.9702 76.587 15.3215 76.4775 15.6535C76.3679 15.9854 76.1905 16.2907 75.9564 16.5497C75.7224 16.8087 75.4371 17.0156 75.1187 17.1572C74.8003 17.2989 74.456 17.372 74.1078 17.372C73.7595 17.372 73.4152 17.2989 73.0968 17.1572C72.7784 17.0156 72.4931 16.8087 72.2591 16.5497C72.025 16.2907 71.8476 15.9854 71.738 15.6535C71.6285 15.3215 71.5893 14.9702 71.6231 14.6221ZM75.5225 14.6221C75.5225 13.4752 75.0095 12.8045 74.1092 12.8045C73.2054 12.8045 72.6971 13.4752 72.6971 14.6221C72.6971 15.7782 73.2055 16.4437 74.1092 16.4437C75.0095 16.4437 75.5225 15.7736 75.5225 14.6221H75.5225Z"
        fill="#121C25"
      />
      <path
        d="M77.6875 15.7736C77.6875 14.8212 78.3936 14.2722 79.6469 14.1942L81.0739 14.1115V13.6548C81.0739 13.096 80.706 12.7805 79.9954 12.7805C79.415 12.7805 79.0128 12.9945 78.8974 13.3686H77.8909C77.9971 12.4598 78.8483 11.8769 80.0434 11.8769C81.3641 11.8769 82.1091 12.5372 82.1091 13.6548V17.2699H81.1082V16.5264H81.0259C80.859 16.7931 80.6245 17.0105 80.3465 17.1565C80.0685 17.3024 79.7569 17.3716 79.4436 17.3571C79.2224 17.3802 78.9989 17.3565 78.7874 17.2875C78.5759 17.2186 78.3812 17.1058 78.2157 16.9567C78.0503 16.8075 77.9178 16.6251 77.8268 16.4214C77.7358 16.2176 77.6884 15.9969 77.6875 15.7736ZM81.0739 15.3215V14.8792L79.7875 14.9618C79.0619 15.0106 78.7329 15.2584 78.7329 15.7249C78.7329 16.201 79.1442 16.4782 79.7098 16.4782C79.8755 16.495 80.0429 16.4782 80.202 16.4287C80.3611 16.3793 80.5087 16.2982 80.636 16.1903C80.7633 16.0824 80.8676 15.9498 80.9429 15.8006C81.0181 15.6514 81.0627 15.4884 81.0739 15.3215Z"
        fill="#121C25"
      />
      <path
        d="M83.4746 14.6221C83.4746 12.9503 84.3304 11.8912 85.6614 11.8912C85.9906 11.8759 86.3174 11.9551 86.6035 12.1195C86.8896 12.2839 87.1231 12.5267 87.2769 12.8194H87.3546V9.91351H88.3943V17.2699H87.398V16.434H87.3158C87.1501 16.7248 86.9084 16.9645 86.6168 17.1272C86.3252 17.2899 85.9948 17.3694 85.6614 17.3571C84.3212 17.3571 83.4746 16.298 83.4746 14.6221ZM84.5486 14.6221C84.5486 15.7444 85.0753 16.4196 85.9562 16.4196C86.8325 16.4196 87.3741 15.7346 87.3741 14.6267C87.3741 13.524 86.8268 12.8292 85.9562 12.8292C85.081 12.8292 84.5486 13.5091 84.5486 14.6221H84.5486Z"
        fill="#121C25"
      />
      <path
        d="M92.6953 14.6221C92.6622 14.2744 92.7018 13.9236 92.8116 13.5922C92.9214 13.2608 93.099 12.956 93.3329 12.6975C93.5668 12.4391 93.8519 12.2325 94.17 12.0912C94.488 11.9499 94.832 11.8769 95.1798 11.8769C95.5275 11.8769 95.8715 11.9499 96.1895 12.0912C96.5076 12.2325 96.7927 12.4391 97.0266 12.6975C97.2606 12.956 97.4381 13.2608 97.5479 13.5922C97.6577 13.9236 97.6973 14.2744 97.6642 14.6221C97.6979 14.9702 97.6588 15.3215 97.5493 15.6535C97.4398 15.9854 97.2623 16.2907 97.0284 16.5497C96.7944 16.8087 96.509 17.0157 96.1907 17.1573C95.8723 17.2989 95.528 17.3721 95.1798 17.3721C94.8316 17.3721 94.4872 17.2989 94.1688 17.1573C93.8505 17.0157 93.5651 16.8087 93.3311 16.5497C93.0972 16.2907 92.9197 15.9854 92.8102 15.6535C92.7007 15.3215 92.6616 14.9702 92.6953 14.6221ZM96.5948 14.6221C96.5948 13.4753 96.0818 12.8046 95.1815 12.8046C94.2777 12.8046 93.7694 13.4753 93.7694 14.6221C93.7694 15.7782 94.2778 16.4437 95.1815 16.4437C96.0818 16.4437 96.5948 15.7736 96.5948 14.6221Z"
        fill="#121C25"
      />
      <path
        d="M99.0586 11.979H100.059V12.8195H100.137C100.269 12.5176 100.491 12.2645 100.773 12.0955C101.055 11.9266 101.382 11.8501 101.709 11.8768C101.966 11.8575 102.223 11.8963 102.462 11.9904C102.702 12.0845 102.917 12.2315 103.092 12.4205C103.267 12.6095 103.398 12.8356 103.474 13.0822C103.55 13.3287 103.57 13.5893 103.533 13.8447V17.2698H102.493V14.1069C102.493 13.2567 102.125 12.8338 101.356 12.8338C101.182 12.8257 101.008 12.8554 100.847 12.921C100.685 12.9866 100.54 13.0865 100.42 13.2139C100.301 13.3412 100.21 13.493 100.155 13.6588C100.099 13.8247 100.08 14.0006 100.098 14.1746V17.2699H99.0586V11.979Z"
        fill="#121C25"
      />
      <path
        d="M109.408 10.6617V12.0031H110.55V12.8826H109.408V15.6032C109.408 16.1575 109.636 16.4001 110.153 16.4001C110.286 16.3997 110.418 16.3917 110.55 16.376V17.2458C110.363 17.2794 110.174 17.2972 109.984 17.2992C108.828 17.2992 108.368 16.8907 108.368 15.8706V12.8825H107.531V12.003H108.368V10.6617H109.408Z"
        fill="#121C25"
      />
      <path
        d="M111.969 9.91351H112.999V12.8292H113.082C113.22 12.5245 113.448 12.27 113.736 12.1004C114.023 11.9308 114.356 11.8543 114.688 11.8814C114.943 11.8675 115.198 11.9102 115.434 12.0066C115.671 12.103 115.884 12.2507 116.057 12.4391C116.23 12.6275 116.36 12.852 116.437 13.0965C116.514 13.3411 116.536 13.5997 116.502 13.8539V17.2699H115.461V14.1115C115.461 13.2664 115.07 12.8384 114.335 12.8384C114.156 12.8237 113.977 12.8483 113.808 12.9107C113.64 12.973 113.487 13.0715 113.361 13.1993C113.235 13.327 113.138 13.481 113.077 13.6504C113.016 13.8197 112.993 14.0004 113.008 14.1798V17.2699H111.969L111.969 9.91351Z"
        fill="#121C25"
      />
      <path
        d="M122.565 15.8413C122.424 16.3249 122.118 16.7431 121.7 17.023C121.283 17.3028 120.781 17.4263 120.282 17.372C119.935 17.3812 119.59 17.3144 119.271 17.1764C118.953 17.0383 118.668 16.8321 118.436 16.5723C118.204 16.3125 118.032 16.0051 117.931 15.6716C117.829 15.3382 117.801 14.9865 117.849 14.6411C117.802 14.2946 117.831 13.9422 117.932 13.6077C118.033 13.2732 118.205 12.9644 118.435 12.7023C118.665 12.4402 118.949 12.2308 119.267 12.0884C119.585 11.946 119.93 11.8738 120.278 11.8768C121.743 11.8768 122.628 12.8826 122.628 14.5441V14.9084H118.908V14.9669C118.891 15.1611 118.916 15.3566 118.979 15.5407C119.042 15.7249 119.143 15.8938 119.275 16.0365C119.408 16.1792 119.568 16.2926 119.746 16.3694C119.924 16.4463 120.117 16.4849 120.311 16.4827C120.559 16.5127 120.811 16.4677 121.035 16.3535C121.258 16.2393 121.442 16.061 121.564 15.8413L122.565 15.8413ZM118.908 14.1362H121.569C121.582 13.9586 121.558 13.7802 121.498 13.6125C121.438 13.4448 121.345 13.2915 121.222 13.1625C121.1 13.0334 120.953 12.9314 120.789 12.863C120.625 12.7947 120.449 12.7615 120.272 12.7655C120.092 12.7633 119.914 12.7972 119.747 12.8653C119.58 12.9333 119.429 13.0342 119.302 13.162C119.175 13.2897 119.075 13.4417 119.007 13.609C118.939 13.7763 118.905 13.9556 118.908 14.1362H118.908Z"
        fill="#121C25"
      />
    </g>
    <defs>
      <clipPath id="clip0_482_372269">
        <rect width={140} height={47} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppleStoreIcon;
