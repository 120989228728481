import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { setAuthModalOpen } from "../redux/slices/auth-slice";

const useAuthModalOpener = ({ redirection }: { redirection: boolean }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const appDispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      if (redirection) {
        navigate("/");
      }
      appDispatch(setAuthModalOpen());
    }
  }, [isAuthenticated]);
};

export default useAuthModalOpener;
