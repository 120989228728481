import React from "react";
import { IconType } from "../../types/utility.types";

const PenIcon: IconType = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.434166 10.3624V13.0664H3.13817L10.8342 5.3704L8.13016 2.6664L0.434166 10.3624ZM13.2262 2.98706C13.5122 2.70106 13.5122 2.2504 13.2262 1.9644L11.5362 0.274398C11.2502 -0.0116016 10.7995 -0.0116016 10.5135 0.274398L9.10083 1.69573L11.8048 4.39973L13.2262 2.98706Z"
      fill="#999999"
    />
  </svg>
);

export default PenIcon;
