import FlagEn from "../components/icons/flag-en";
import FlagRu from "../components/icons/flag-ru";
import FlagUz from "../components/icons/flag-uz";
import { Languages } from "../lib/i18next";

export const languages = [
  {
    label: "O'zbekcha",
    value: Languages.UZ,
    icon: <FlagUz />,
  },
  {
    label: "Русский",
    value: Languages.RU,
    icon: <FlagRu />,
  },
  {
    label: "English (US)",
    value: Languages.EN,
    icon: <FlagEn />,
  },
];
