import { ButtonBase, Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../configs/theme.config";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import AppStoreIcon from "../../components/icons/app-store";
import PlayStoreIcon from "../../components/icons/play-store";
import { NavLink } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mt: 2.5,
        mx: "auto",
        maxWidth: "1344px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography
          component="a"
          variant="caption"
          color={theme.palette.text.primary}
          fontWeight="500"
        >
          © {dayjs().get("year")} ООО «MYTAXI OPS»
        </Typography>
        <NavLink to="/terms-and-conditions">
          <Typography
            component="a"
            variant="caption"
            color={theme.palette.text.primary}
            fontWeight="500"
          >
            {t("term use")}
          </Typography>
        </NavLink>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <ButtonBase
          target={"_blank"}
          href="https://apps.apple.com/ru/app/mytaxi-uz/id865012817"
        >
          <AppStoreIcon />
        </ButtonBase>
        <ButtonBase
          target={"_blank"}
          href="https://play.google.com/store/apps/details?id=com.uznewmax.mytaxi"
        >
          <PlayStoreIcon />
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default Footer;
