import { BoxProps } from "@mui/material";
import { PopoverWrapper } from "./styles";

export interface PopoverTypes extends BoxProps {
  verticalAlign: "top" | "bottom";
  horizontalAlign: "left" | "right";
  x?: number;
  y?: number;
  isOpen?: boolean;
}

const Popover: React.FC<PopoverTypes> = ({
  isOpen,
  horizontalAlign,
  x = 0,
  y = 0,
  verticalAlign,
  children,
  sx,
  ...props
}) =>
  isOpen ? (
    <PopoverWrapper
      x={x}
      y={y}
      sx={{
        [horizontalAlign]: `${x}px`,
        [verticalAlign]: `${verticalAlign == "bottom" ? "" : "-"}${y}px`,
        [verticalAlign]: `-${y}px`,
        transform: `translateY(${
          verticalAlign == "bottom" ? "100%" : "-100%"
        })`,
        ...sx,
      }}
      {...props}
    >
      {children}
    </PopoverWrapper>
  ) : null;

export default Popover;
// ${verticalAlign == 'top' ? '-' : ''}
