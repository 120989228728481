import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorModal from "../components/modals/error-modal/error-modal";
import OfflineToast from "../components/toasts/offline-toast/offline-toast";
import PrivateRoute from "../components/private-route/private-route";
import SuccessToast from "../components/toasts/success-toast/success-toast";
import Tariffs from "./tariffs";
import featureConfig from "../configs/feature-config";
import TechnicalProfilactics from "../layouts/technical-profilactics/technical-profilactics";
import PrivacyPolicyForDriversPage from "./privacy-policy-for-drivers/privacy-policy-for-drivers";

const HomePage = lazy(() => import("./home"));
const ProfilePage = lazy(() => import("./profile"));
const TermsAndConditions = lazy(() => import("./terms-and-conditions"));

const Views = () => (
  <Suspense fallback={<div>Not found</div>}>
    <Routes>
      {featureConfig.technical_work ? (
        <Route path="*" element={<TechnicalProfilactics />} />
      ) : (
        <>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/profile/*"
            element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      )}
      <Route path="/tariffs" element={<Tariffs />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/agreement" element={<TermsAndConditions />} />
      <Route
        path="/legal/privacy-policy-for-drivers"
        element={<PrivacyPolicyForDriversPage />}
      />
    </Routes>
    <ErrorModal />
    <OfflineToast />
    <SuccessToast />
  </Suspense>
);

export default Views;
