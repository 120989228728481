import styled from "styled-components";
import { DropDownMenuProps } from "./header-drop-down.types";
import { COLORS } from "../../../../configs/colors.style";

export const HeaderMenuItem = styled.div`
  padding: 28px 10px 20px;
  color: ${COLORS.textMain};
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  margin-right: 12px;
  transition: color 0.2s ease;
  &:hover {
    color: ${COLORS.additionalRed};
  }
`;

export const DropDownInput = styled.input`
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  line-height: 1px;
  font-size: 1px;
  overflow: hidden;
`;

export const DropDownmenuItem = styled.div`
  height: 100%;
`;
export const DropDownmenuLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
`;
export const DropDownmenuTitle = styled.span`
  white-space: nowrap;
`;

export const DropDownmenuIcon = styled.span<DropDownMenuProps>`
  position: absolute;
  right: -20px;
  top: 0.8px;
  transition: transform 0.2s ease-in-out;
  transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "rotate(0)")};
`;

export const DropDownMenuList = styled.ul<DropDownMenuProps>`
  position: absolute;
  background: ${COLORS.white};
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
  display: ${({ isShow }) => (isShow ? "block" : "none")};
  padding: 10px 20px;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  margin-top: 18px;
  animation: fadeIn ease 0.2s;
  list-style: none;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &::before {
    position: absolute;
    content: "";
    background-color: red;
    display: block;
    width: 500px;
    height: 30px;
    right: 50px;
    width: 100%;
    z-index: -1;
    left: 0;
    top: -6px;
    opacity: 0;
  }
`;

export const Space = styled.span<DropDownMenuProps>`
  position: relative;
  width: 100%;
  height: 8px;
  bottom: -28px;
  display: block;
`;

export const DropDownMenuItem = styled.li`
  position: relative;
`;

export const DropDownMenuLink = styled.a`
  padding: 12px 50px 12px 0;
  position: relative;
  display: flex;
  align-items: center;
  color: ${COLORS.textMain};
  transition: color 0.2s ease;
  &:hover {
    color: ${COLORS.additionalRed};
  }
`;

export const RightDownArrow = styled.span`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  margin-top: 2px;
`;

export const DropDownMenuWrapper = styled.div`
  padding-bottom: 6px;
  border-bottom: 1px solid #efeded;
`;

export const PhoneWrapper = styled.div``;
