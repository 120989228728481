import { FormHelperText } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/buttons/button";
import { Modal } from "../../../../components/modal/modal";
import PageHeader from "../../../../components/page-header/page-header";
import TextArea from "../../../../components/text-area/text-area";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
  closeSupportModal,
  openToast,
} from "../../../../redux/slices/modal-slice";
import { useWriteToSupport } from "../../../../services/mutations/use-write-to-support";

const SupportModal: React.FC<{}> = () => {
  const [error, setError] = useState("");

  const { t } = useTranslation();
  const { mutate: sent, isLoading } = useWriteToSupport();
  const [message, setMessage] = useState<string>("");
  const { support_modal } = useAppSelector((state) => state.modal);
  const appDispatch = useAppDispatch();

  const closeModal = () => {
    setMessage("");
    appDispatch(closeSupportModal());
  };

  const submit = () =>
    sent(message, {
      onSuccess: () => {
        appDispatch(openToast({ isOpen: true }));
        closeModal();
      },
    });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > 250) {
      setError(t("max length of symbols", { count: 250 }));

      return setMessage(event.target.value.slice(0, 250));
    }

    setError("");
    setMessage(event.target.value);
  };

  return (
    <Modal
      style={{ padding: "20px", minWidth: "0", minHeight: 0 }}
      closeIcon
      isOpen={support_modal}
      onClose={closeModal}
    >
      <PageHeader title={t("write to support")} border pb="8px" />
      <TextArea
        aria-label="empty textarea"
        placeholder={t("commentary")}
        minRows={6}
        maxRows={10}
        value={message || ""}
        autoFocus
        onChange={handleChange}
        width="100%"
      />
      <FormHelperText error>{error}</FormHelperText>
      {message.trim().length < 1 ? (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ mt: (theme) => theme.spacing(3) }}
          disabled
        >
          {t("There must be some text")}
        </Button>
      ) : (
        <Button
          variant="contained"
          color={"primary"}
          fullWidth
          sx={{ mt: (theme) => theme.spacing(3) }}
          onClick={submit}
          loading={isLoading}
        >
          {t("send")}
        </Button>
      )}
    </Modal>
  );
};

export default SupportModal;
