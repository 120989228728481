import { ButtonBase } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../configs/colors.style";
import { DropDownMenuProps } from "./components/header-drop-down/header-drop-down.types";

export const HeaderWrapper = styled.div`
  width: 100%;
`;

export const AppHeader = styled.header`
  position: fixed;
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  width: 100%;
  background-color: ${COLORS.white};
  box-shadow: ${(props: { isShadowRemoved: boolean }) =>
    props.isShadowRemoved ? "none" : `0px 8px 40px rgba(0, 0, 0, 0.1)`};
  border-radius: 0px 0px 20px 20px;

  @media screen and (max-width: 1000px) {
    padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  }
`;

export const HeaderMiddle = styled.div`
  width: auto;
  flex: 1;
`;
export const HeaderMiddleWrapper = styled.div``;

export const HeaderNavMenu = styled.nav`
  display: flex;
  justify-content: center;
`;

export const HeaderMenuLink = styled(NavLink)`
  padding: 28px 10px;
  color: ${COLORS.textMain};
  font-size: 15px;
  font-weight: 500;
  transition: color 0.2s ease;

  &:hover {
    color: ${COLORS.additionalRed};
  }
`;

export const HeaderMenuAnchorLink = styled.a`
  padding: 28px 10px;
  color: ${COLORS.textMain};
  font-size: 15px;
  font-weight: 500;
  transition: color 0.2s ease;

  &:hover {
    color: ${COLORS.additionalRed};
  }
`;

export const HeaderMenuLinkButton = styled(ButtonBase)`
  padding: 28px 10px;
  color: ${COLORS.textMain};
  font-size: 15px;
  font-weight: 500;
  transition: color 0.2s ease;

  &:hover {
    color: ${COLORS.additionalRed};
  }
`;

export const DropDownInput = styled.input`
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  line-height: 1px;
  font-size: 1px;
  overflow: hidden;
`;

export const DropDownmenuItem = styled.div`
  height: 100%;
`;
export const DropDownmenuLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
`;
export const DropDownmenuTitle = styled.span`
  white-space: nowrap;
`;

export const DropDownmenuIcon = styled.span<DropDownMenuProps>`
  position: absolute;
  right: -20px;
  top: 0.8px;
  transition: transform 0.2s ease-in-out;
  transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "rotate(0)")};
`;
