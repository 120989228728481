import { Box, BoxProps } from "@mui/material";
import theme from "../../configs/theme.config";
import { IconType } from "../../types/utility.types";

interface LogoSquaredIconType extends BoxProps {
  iconProps?: IconType;
}

const LogoSquaredIcon: React.FC<LogoSquaredIconType> = ({
  sx,
  iconProps,
  ...props
}) => (
  <Box
    sx={{
      backgroundColor: theme.palette.primary.main,
      width: "80px",
      height: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "14px",
      ...sx,
    }}
    {...props}
  >
    <svg
      width={58}
      height={48}
      viewBox="0 0 58 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33526 18.8355H50.5867C54.126 18.8355 57.0216 21.7357 57.0216 25.2805V40.6951C57.0216 44.2399 54.126 47.1402 50.5867 47.1402H7.33526C3.79599 47.1402 0.900391 44.2399 0.900391 40.6951V25.2805C0.900391 21.7357 3.79599 18.8355 7.33526 18.8355"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1701 37.8977C15.4334 38.3837 14.6787 38.6267 13.9058 38.6267C13.223 38.6267 12.6839 38.4152 12.2885 37.9922C11.8931 37.5692 11.6954 36.9528 11.6954 36.1428V29.8252H16.2779V27.5033H11.6954V23.3456H8.89213V27.5033H6.00781V29.8252H8.89213V36.3318C8.89213 37.9516 9.32351 39.1801 10.1861 40.0172C11.0486 40.8541 12.2257 41.2726 13.7171 41.2726C14.7774 41.2726 15.8827 40.9306 17.0327 40.2465L16.1701 37.8977ZM30.523 27.5032H27.7195V29.0422C26.4976 27.7643 24.8803 27.1254 22.8675 27.1254C21.0704 27.1254 19.516 27.7913 18.2042 29.1231C16.8924 30.455 16.2363 32.147 16.2363 34.199C16.2363 36.2688 16.8879 37.9652 18.1906 39.2881C19.4936 40.6109 21.0525 41.2725 22.8675 41.2725C24.8803 41.2725 26.4976 40.6336 27.7195 39.3555V40.8944H30.523V27.5032ZM23.3257 38.7885C22.0679 38.7885 21.0256 38.3702 20.1989 37.5331C19.3723 36.6962 18.959 35.5848 18.959 34.199C18.959 32.813 19.3723 31.7015 20.1989 30.8646C21.0256 30.0277 22.0679 29.6092 23.3257 29.6092C24.4939 29.6092 25.5182 30.0367 26.3987 30.8916C27.2793 31.7465 27.7196 32.849 27.7196 34.199C27.7196 35.5487 27.2793 36.6512 26.3987 37.5061C25.5182 38.3612 24.4939 38.7885 23.3257 38.7885ZM41.0999 34.334L45.898 27.4764H42.5287L39.0245 32.5522L35.5202 27.4764H32.1777L36.9758 34.334L32.1777 40.8946H35.5202L39.0245 36.0619L42.5287 40.8946H45.898L41.0999 34.334ZM49.1438 21.7257C48.6228 21.7257 48.1916 21.8968 47.8501 22.2386C47.5086 22.5807 47.3379 22.9946 47.3379 23.4806C47.3379 23.9846 47.5086 24.3985 47.8501 24.7226C48.1916 25.0465 48.6228 25.2084 49.1438 25.2084C49.6651 25.2084 50.0919 25.0465 50.4244 24.7226C50.7568 24.3985 50.923 23.9846 50.923 23.4806C50.923 22.9946 50.7523 22.5807 50.4108 22.2386C50.0694 21.8968 49.647 21.7257 49.1438 21.7257ZM50.5455 40.8944V27.5032H47.7422V40.8944H50.5455Z"
        fill="#FCE300"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3559 0.476471C23.1995 0.476471 21.5371 1.29545 20.3691 2.93347C19.9377 2.1594 19.3313 1.55648 18.5496 1.12448C17.7679 0.692597 16.8919 0.476471 15.9214 0.476471C14.0884 0.476471 12.6598 1.10654 11.6355 2.36643V0.854537H8.83203V14.2457H11.6355V7.60401C11.6355 6.12817 11.9544 5.00319 12.5923 4.2293C13.2304 3.45535 14.0975 3.06844 15.1935 3.06844C16.0742 3.06844 16.7975 3.36081 17.3635 3.94578C17.9295 4.5307 18.2126 5.30932 18.2126 6.28121V14.2457H21.0161V7.60401C21.0161 6.12817 21.3395 5.00319 21.9864 4.2293C22.6334 3.45535 23.5139 3.06844 24.628 3.06844C25.5087 3.06844 26.232 3.36081 26.7979 3.94578C27.364 4.5307 27.6471 5.30932 27.6471 6.28121V14.2457H30.4236V5.71423C30.4236 4.16621 29.9338 2.9065 28.9544 1.93443C27.975 0.962539 26.7755 0.476471 25.3559 0.476471ZM45.3682 0.827423H42.2414L37.9553 10.4927L33.7772 0.827423H30.6504L36.4188 13.9216L34.2355 18.8353H37.3624L45.3682 0.827423Z"
        fill="black"
      />
    </svg>
  </Box>
);

export default LogoSquaredIcon;
