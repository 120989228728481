import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import queryClient from "../../../configs/react-query.config";
import theme from "../../../configs/theme.config";
import useModal from "../../../hooks/use-modal";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { closeOffline, openOffline } from "../../../redux/slices/modal-slice";
import WarningIcon from "../../icons/warning";
import { ToastWrapper } from "../toast.styles";

const OfflineToast = () => {
  const { t } = useTranslation();
  const { offline_toast } = useAppSelector((state) => state.modal);
  const appDispatch = useAppDispatch();

  const online = () => {
    if (!window.navigator.onLine) return;

    appDispatch(closeOffline());
    queryClient.invalidateQueries();
  };

  const offline = () => appDispatch(openOffline());

  useEffect(() => {
    if (!window.navigator.onLine) offline();

    window.addEventListener("online", online);
    window.addEventListener("offline", offline);

    return () => {
      window.removeEventListener("online", online);
      window.removeEventListener("offline", offline);
    };
  }, []);

  return (
    <ToastWrapper
      isOpen={offline_toast}
      sx={{
        bgcolor: theme.palette.error.main,
        px: 2,
        width: "343px",
        top: "100px",
      }}
    >
      <WarningIcon />
      <Typography
        mx={"auto"}
        color={theme.palette.background.paper}
        lineHeight={"19px"}
      >
        {t("no internet")}
      </Typography>
    </ToastWrapper>
  );
};

export default OfflineToast;
