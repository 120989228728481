import { IconType } from "../../types/utility.types";

export const PhoneSquaredIcon: IconType = (props) => (
  <svg
    width={64}
    height={65}
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      y="0.927734"
      width={64}
      height="63.8078"
      rx="13.4737"
      fill="#3DD598"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.6551 35.2696C32.6702 38.2822 36.1634 41.1653 37.5443 39.7847C39.5195 37.8099 40.7385 36.0884 45.0965 39.5904C49.4525 43.0906 46.106 45.4253 44.1917 47.3372C41.9823 49.5462 33.7464 47.4552 25.6057 39.3181C17.467 31.1791 15.3813 22.9449 17.5927 20.7359C19.5069 18.8201 21.8306 15.4762 25.3314 19.8313C28.8323 24.1865 27.1142 25.4052 25.1353 27.3819C23.7601 28.7625 26.6419 32.255 29.6551 35.2696Z"
      fill="white"
    />
  </svg>
);
