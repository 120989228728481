import React, { useState } from "react";
import {
  HeaderMenuItem,
  DropDownInput,
  DropDownmenuLabel,
  DropDownmenuItem,
  DropDownmenuTitle,
  DropDownmenuIcon,
  Space,
} from "./header-drop-down.style";
import { HeaderDropDownMenu } from "./header-drop-down-menu";
import DownArrow from "../../../../components/icons/down-arrow";
import { useTranslation } from "react-i18next";

export const HeaderDropDown = () => {
  const [isDropDowrnShow, setDropDowrnShow] = useState(false);
  const { t } = useTranslation();
  return (
    <HeaderMenuItem
      onMouseEnter={() => setDropDowrnShow(true)}
      onMouseLeave={() => setDropDowrnShow(false)}
    >
      <DropDownInput
        type="checkbox"
        aria-expanded={isDropDowrnShow}
        id="header-links.rider"
      />
      <DropDownmenuItem>
        <DropDownmenuLabel htmlFor="header-links.rider">
          <DropDownmenuTitle>{t("information")}</DropDownmenuTitle>
          <DropDownmenuIcon isShow={isDropDowrnShow}>
            <DownArrow />
          </DropDownmenuIcon>
        </DropDownmenuLabel>
      </DropDownmenuItem>
      <Space isShow={isDropDowrnShow} />
      <HeaderDropDownMenu isShow={isDropDowrnShow} />
    </HeaderMenuItem>
  );
};
