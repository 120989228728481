import { IconType } from "../../types/utility.types";

const ModalBack: IconType = (props) => (
  <svg
    width={64}
    height={63}
    viewBox="0 0 64 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={32}
      y="0.707107"
      width={43}
      height={43}
      rx="13.5"
      transform="rotate(45 32 0.707107)"
      fill="white"
    />
    <path
      d="M34.75 23.4127L27.05 31.1127L34.75 38.8127"
      stroke="#767676"
      strokeWidth="2.1875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={32}
      y="0.707107"
      width={43}
      height={43}
      rx="13.5"
      transform="rotate(45 32 0.707107)"
      stroke="#EFEDED"
    />
  </svg>
);

export default ModalBack;
