import { Box } from "@mui/material";
import styled from "styled-components";
import theme from "../../configs/theme.config";

export const DriverNumberWrapper = styled(Box)`
  display: flex;
  border: 1px solid #efeded;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  padding: 5px 6px;
`;

export const DriverNumberHr = styled.div`
  width: 1px;
  border: none;
  height: 16px;
  margin: auto 4px;
  background-color: ${theme.palette.divider};
`;
