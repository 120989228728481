import React from "react";
import { motion } from "framer-motion";
import { useMultiLevelMenu } from "../../context/multi-level-menu/multi-level-menu";

const AnimatedWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { direction } = useMultiLevelMenu();
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          x: direction === "forward" ? 200 : -200,
          y: 0,
          height: "100%",
        },
        enter: { opacity: 1, x: 0, height: "100%" },
        exit: { opacity: 0, x: 200 },
      }}
      initial="hidden"
      animate="enter"
      exit="exit"
      transition={{ type: "just" }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedWrapper;
