import { useEffect, useState } from "react";

const useTimer = (time: number = 60) => {
  const [timer, setTimer] = useState(time);

  useEffect(() => {
    const t = setInterval(() => {
      setTimer((p) => {
        if (p > 0) {
          return p - 1;
        }

        clearInterval(t);
        return 0;
      });
    }, 1000);

    return () => clearInterval(t);
  }, [timer]);

  const reset = () => setTimer(time);

  return {
    seconds: timer % 60 <= 9 ? "0" + (timer % 60) : timer % 60,
    minets:
      Math.trunc(timer / 60) <= 9
        ? "0" + Math.trunc(timer / 60)
        : Math.trunc(timer / 60),
    total: timer,
    reset,
  };
};

export default useTimer;
