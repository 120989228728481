import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import theme from "../../../configs/theme.config";
import LogoSquaredIcon from "../../icons/logo-squared";
import { Modal } from "../../modal/modal";
import DownloadModalBg from "../../../assets/images/download-modal-bg.png";
import GooglePlayIcon from "../../icons/google-play";
import AppleStoreIcon from "../../icons/apple-store";
import { useTranslation } from "react-i18next";
import QR from "../../../assets/images/qr-download-app.svg";

const DownloadAppModal: React.FC<{ isOpen: boolean; close: () => void }> = ({
  isOpen,
  close,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      style={{
        width: "524px",
        backgroundImage: `url(${DownloadModalBg})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        padding: 0,
      }}
      isOpen={isOpen}
      onClose={close}
      closeIcon={true}
    >
      <Box p={4.5} pt={5} sx={{ width: "100%", height: "100%" }}>
        <LogoSquaredIcon mx="auto" />
        <Typography
          textAlign="center"
          lineHeight="32px"
          fontSize="29px"
          mt={2.5}
        >
          {t("download app")}
        </Typography>
        <Typography
          lineHeight="19.5px"
          textAlign="center"
          fontWeight={400}
          maxWidth="303px"
          mx={"auto"}
          mt={0.5}
          mb={3}
        >
          {t("download app subtitle")}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            p={1}
            sx={{
              bgcolor: "rgba(23, 23, 37, 0.5)",
              borderRadius: theme.borderRadius.small,
            }}
            component="a"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.uznewmax.mytaxi"
          >
            <GooglePlayIcon />
            <Box
              sx={{
                width: "47px",
                height: "47px",
                borderRadius: "6px",
                p: "3.5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "white",
              }}
            >
              <img src={QR} width="40px" height="40px" />
            </Box>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            p={1}
            sx={{
              bgcolor: "rgba(23, 23, 37, 0.5)",
              borderRadius: theme.borderRadius.small,
            }}
            component="a"
            target="_blank"
            href="https://apps.apple.com/ru/app/mytaxi-uz/id865012817"
          >
            <AppleStoreIcon />
            <Box
              sx={{
                width: "47px",
                height: "47px",
                borderRadius: "6px",
                p: "3.5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "white",
              }}
            >
              <img src={QR} width="40px" height="40px" />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DownloadAppModal;
