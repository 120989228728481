import { IconType } from "../../types/utility.types";

const DownArrow: IconType = ({ color, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33325 6.33333L7.99992 11L12.6666 6.33333"
      stroke={color || "#434343"}
      fill="none"
      strokeWidth="1.45833"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownArrow;
