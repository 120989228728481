import { useQuery } from "react-query";
import { Languages } from "../../lib/i18next";
import { useAppSelector } from "../../redux/hook";
import request from "../request";

export const useGetClientLanguage = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery(
    "language",
    () =>
      request.private
        .get<{
          lang: Languages;
        }>("v1/client-lang")
        .then((res) => res.data),
    {
      enabled: isAuthenticated,
    }
  );
};
