import styled from "styled-components";

export const NameAuthModalWrapper = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
