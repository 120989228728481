/* eslint-disable indent */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal } from "../../../../components/modal/modal";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { setAuthModalClose } from "../../../../redux/slices/auth-slice";
import { AuthProvider } from "./context/auth";
import { NameAuth } from "./name/name";
import { SendCodeAuth } from "./send-code/send-code";
import { AuthWrapper } from "./styles";
import { AuthPropsModal } from "./types";
import { WelcomeAuth } from "./welcome/welcome-modal";

export const modes = {
  WELCOME: "WELCOME",
  SEND_CODE: "SEND_CODE",
  NAME: "NAME",
};

const renderModal = (
  step: string,
  setCurrentMode: Dispatch<SetStateAction<string>>
) => {
  switch (step) {
    case modes.WELCOME:
      return <WelcomeAuth setCurrentMode={setCurrentMode} />;
    case modes.SEND_CODE:
      return <SendCodeAuth setCurrentMode={setCurrentMode} />;
    case modes.NAME:
      return <NameAuth setCurrentMode={setCurrentMode} />;
    default:
      return null;
  }
};

export const Auth: React.FC<AuthPropsModal> = () => {
  const [mode, setMode] = useState(modes.WELCOME);

  const auth_modal = useAppSelector((state) => state.auth.auth_modal);
  const dispatch = useAppDispatch();
  const closeModal = () => {
    setMode(modes.WELCOME);
    dispatch(setAuthModalClose());
  };

  useEffect(() => {
    if (![modes.NAME, modes.SEND_CODE, modes.WELCOME].includes(mode)) {
      closeModal();
    }
  }, [mode]);

  return (
    <AuthProvider>
      <Modal
        isOpen={auth_modal}
        onClose={closeModal}
        closeIcon={mode === modes.WELCOME}
        style={{
          padding: "28px 24px 28px",
          width: "100%",
          maxWidth: "423px",
          borderRadius: "32px",
        }}
      >
        <AuthWrapper>{renderModal(mode, setMode)}</AuthWrapper>
      </Modal>
    </AuthProvider>
  );
};
