import { IconType } from "../../types/utility.types";

const UserCircledIcon: IconType = (props) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.9941 1.24819e-05C29.4908 -0.0118848 38 8.48279 38 18.9881C38.0119 29.4934 29.5265 37.9762 19.006 38C8.50925 38.0119 1.25033e-05 29.5172 1.25033e-05 19.0119C-0.0118885 8.49468 8.47354 0.0119098 18.9941 1.24819e-05ZM18.9702 34.2048C23.1118 34.1453 26.6821 32.7057 29.6574 29.8265C30.5618 28.958 30.5618 28.0419 29.6693 27.1615C23.7069 21.2962 14.2217 21.3319 8.25932 27.2329C7.47386 28.0063 7.46196 28.958 8.25932 29.7433C11.2346 32.6581 14.8168 34.1334 18.9702 34.2048ZM19.0179 7.59049C15.876 7.57859 13.3054 10.1246 13.2935 13.2536C13.2816 16.3945 15.8165 18.9762 18.9464 18.9881C22.1121 19 24.6946 16.454 24.6946 13.3012C24.7065 10.1603 22.1597 7.59049 19.0179 7.59049Z"
      fill="#D9D9D9"
    />
    <path
      d="M18.9703 34.2047C14.8168 34.1334 11.2346 32.67 8.25933 29.7433C7.47386 28.9699 7.47386 28.0182 8.25933 27.2329C14.2217 21.3319 23.695 21.3081 29.6693 27.1615C30.5619 28.0419 30.5619 28.958 29.6574 29.8265C26.6821 32.7057 23.1118 34.1453 18.9703 34.2047Z"
      fill="white"
    />
    <path
      d="M19.0178 7.59048C22.1478 7.60238 24.7065 10.1603 24.6946 13.3012C24.6946 16.454 22.1121 19.0119 18.9464 18.9881C15.8165 18.9762 13.2815 16.3945 13.2934 13.2536C13.3053 10.1246 15.876 7.57858 19.0178 7.59048Z"
      fill="white"
    />
  </svg>
);

export default UserCircledIcon;
