import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadState, saveState } from "../../utils/storage";
import { ReactNode } from "react";

interface AuthStateType {
  cancel_order_id: number | null;
  error_list:
    | {
        error_message?: string;
        error_type: string;
        action?: () => void;
      }[]
    | null;
  trip_rates: Array<{
    rate: number;
    order_id: number | null;
    comment: string | null;
  }>;
  support_modal: boolean;
  offline_toast: boolean;
  success_toast: {
    message?: ReactNode;
    isOpen: boolean;
    time?: number;
    icon?: ReactNode;
  } | null;
}

const initialState: AuthStateType = {
  cancel_order_id: null,
  error_list: [],
  trip_rates: loadState("trip_rate") || [], // trip rate
  support_modal: false,
  offline_toast: false,
  success_toast: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setCancelOrderId: (state, action: PayloadAction<number | null>) => {
      state.cancel_order_id = action.payload;
    },
    resetModalSlice: (state) => {
      state = initialState;
    },
    addError: (
      state,
      action: PayloadAction<{
        error_message?: string;
        error_type: string;
        action?: () => void;
      }>
    ) => {
      if (
        state.error_list?.find(
          (error) =>
            error.error_type === action.payload.error_type ||
            (action.payload.error_message === error.error_message &&
              error.error_message)
        )
      )
        return;

      state.error_list?.push(action.payload);
    },
    removeError: (state) => {
      state.error_list?.shift();
    },
    openSupportModal: (state) => {
      state.support_modal = true;
    },
    closeSupportModal: (state) => {
      state.support_modal = false;
    },
    openToast: (
      state,
      action: PayloadAction<{
        message?: ReactNode;
        isOpen: boolean;
        time?: number;
        icon?: ReactNode;
      }>
    ) => {
      state.success_toast = action.payload;
    },
    closeToast: (state) => {
      state.success_toast = null;
    },
    openOffline: (state) => {
      state.offline_toast = true;
    },
    closeOffline: (state) => {
      state.offline_toast = false;
    },
    setTripRate: (
      state,
      action: PayloadAction<{
        rate: number;
        order_id: number;
        comment?: string | null;
      }>
    ) => {
      const index = state.trip_rates.findIndex(
        (rate) => rate.order_id === action.payload.order_id
      );

      state.trip_rates[index] = {
        order_id: action.payload.order_id,
        rate: action.payload.rate || 0,
        comment: action.payload.comment || null,
      };

      saveState("trip_rate", state.trip_rates);
    },
    addTripRate: (
      state,
      action: PayloadAction<{
        rate: number;
        order_id: number;
        comment: string | null;
      }>
    ) => {
      state.trip_rates?.push({
        order_id: action.payload.order_id,
        rate: action.payload.rate || 0,
        comment: action.payload.comment || null,
      });

      saveState("trip_rate", state.trip_rates);
    },

    removeTripRate: (state, action: PayloadAction<{ order_id: number }>) => {
      state.trip_rates = state.trip_rates.filter(
        (rate) => rate.order_id !== action.payload.order_id
      );

      saveState("trip_rate", state.trip_rates);
    },
  },
});

export const {
  setCancelOrderId,
  resetModalSlice,
  addError,
  removeError,
  openSupportModal,
  closeSupportModal,
  openToast,
  closeToast,
  openOffline,
  closeOffline,
  setTripRate,
  addTripRate,
  removeTripRate,
} = modalSlice.actions;
export default modalSlice.reducer;
