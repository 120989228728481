import { useInfiniteQuery } from "react-query";
import { useAppSelector } from "../../redux/hook";
import request from "../request";
import { OrderType } from "./use-get-order-history";

export interface OrderHistoryType {
  page: number;
  total_pages: number;
  orders_count: number;
  orders: OrderType[];
}

export const useGetOrderHistoryInfinite = (
  status: "finished" | "cancelled"
) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const fetchProjects = ({ pageParam = 1 }) =>
    request.private
      .get<OrderHistoryType>(
        `v1/orders?page=${pageParam ?? 1}&status=${status}`
      )
      .then((res) => res.data);

  return useInfiniteQuery(["order-history-infinite", status], fetchProjects, {
    getNextPageParam: (lastPage, pages) =>
      lastPage.total_pages >= lastPage.page ? pages.length + 1 : null,
    enabled: isAuthenticated,
  });
};
