import { IconType } from "../../types/utility.types";

const SearchEllipseIcon: IconType = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6485 13.6485C20.6485 17.3204 17.6719 20.297 14 20.297C10.3281 20.297 7.35148 17.3204 7.35148 13.6485C7.35148 9.97664 10.3281 7 14 7C17.6719 7 20.6485 9.97664 20.6485 13.6485Z"
      stroke={props.fill ? `${props.fill}` : "#4B89FA"}
      strokeWidth="2.33333"
    />
    <ellipse
      cx="14.0002"
      cy="13.6485"
      rx="2.87927"
      ry="2.87927"
      fill={props.fill ? `${props.fill}` : "#4B89FA"}
    />
  </svg>
);

export default SearchEllipseIcon;
