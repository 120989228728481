import { useState } from "react";

const useModal = (defaultValue = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const toggle = () => setIsOpen((p) => !p);

  return { isOpen, close, open, toggle };
};

export default useModal;
