import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hook";
import { useGetUserCards } from "../../../../services/queries/use-get-paycom-cards";
import HumoIcon from "../../../icons/card-icons/humo";
import MastercardIcon from "../../../icons/card-icons/mastercard";
import UnionPayIcon from "../../../icons/card-icons/union-pay";
import VisaIcon from "../../../icons/card-icons/visa";
import UzcardIcon from "../../../icons/uzcardIcon";
import Radio from "../../../radio/radio";

export const cardNumberFormatter = (number?: string | null) =>
  number &&
  number
    .split("")
    .map((e, index) => (index % 4 == 3 ? e + " " : e))
    .join("");

export const isCardPayment = (alias?: string | null) => {
  switch (alias) {
    case "upay":
    case "payme":
      return true;
    default:
      return false;
  }
};

const getCardIcon = (card_number: string | null) => {
  if (!card_number) return;

  if (card_number?.startsWith("4")) return <VisaIcon />;

  if (
    (parseInt(card_number?.slice(0, 2)) <= 55 &&
      parseInt(card_number?.slice(0, 2)) >= 51) ||
    (parseInt(card_number?.slice(0, 4)) <= 2204 &&
      parseInt(card_number?.slice(0, 4)) >= 2200)
  )
    return <MastercardIcon />;

  if (card_number.startsWith("8600") || card_number.startsWith("5614"))
    return <UzcardIcon />;

  if (card_number.startsWith("9860")) return <HumoIcon />;

  if (card_number.startsWith("62")) return <UnionPayIcon />;

  return <UzcardIcon />;
};

const Cards: React.FC<{
  handlePaymentTypeChange: (alias: string, id?: number) => void;
  payment_type_alias: string | null;
  disabled?: boolean;
}> = ({ handlePaymentTypeChange, payment_type_alias, disabled = false }) => {
  const { t } = useTranslation();
  const { data } = useGetUserCards();

  const { card_id } = useAppSelector((state) => state.order);

  return (
    <Box>
      {data?.cards?.map((card) => {
        return (
          payment_type_alias && (
            <Radio
              disabled={disabled}
              wrapperProps={{
                sx: {
                  padding: "6px 6px",
                  borderRadius: "12px",
                  position: "relative",
                },
                title: disabled ? t("not supported by tarif") : "",
              }}
              key={card.id}
              label={
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  {getCardIcon(card.number)}
                  <Stack>
                    <Typography variant="body1">
                      {cardNumberFormatter(card.number)}
                    </Typography>
                  </Stack>
                </Stack>
              }
              name="paymentType"
              value={card.id + ""}
              checked={card.id === card_id}
              onChange={() =>
                handlePaymentTypeChange(payment_type_alias, card.id)
              }
              // disabled={card.card_status === 'success'}
            />
          )
        );
      })}
    </Box>
  );
};

export default Cards;
