import { IconType } from "../../types/utility.types";

export const HelpIcon: IconType = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C6.47917 2 2 6.47917 2 12C2 17.5208 6.47917 22 12 22C17.5208 22 22 17.5208 22 12C22 6.47917 17.5208 2 12 2ZM11.6875 17.8333C11.4815 17.8333 11.2801 17.7722 11.1088 17.6578C10.9375 17.5433 10.804 17.3806 10.7251 17.1903C10.6463 17 10.6257 16.7905 10.6658 16.5884C10.706 16.3864 10.8053 16.2008 10.9509 16.0551C11.0966 15.9094 11.2822 15.8102 11.4843 15.77C11.6863 15.7298 11.8958 15.7505 12.0861 15.8293C12.2765 15.9081 12.4392 16.0416 12.5536 16.2129C12.6681 16.3842 12.7292 16.5856 12.7292 16.7917C12.7292 17.0679 12.6194 17.3329 12.4241 17.5282C12.2287 17.7236 11.9638 17.8333 11.6875 17.8333ZM13.4292 12.5208C12.5849 13.0875 12.4688 13.6068 12.4688 14.0833C12.4688 14.2767 12.3919 14.4622 12.2552 14.5989C12.1184 14.7357 11.933 14.8125 11.7396 14.8125C11.5462 14.8125 11.3607 14.7357 11.224 14.5989C11.0872 14.4622 11.0104 14.2767 11.0104 14.0833C11.0104 12.9422 11.5354 12.0349 12.6156 11.3094C13.6198 10.6354 14.1875 10.2083 14.1875 9.26927C14.1875 8.63073 13.8229 8.14583 13.0682 7.78698C12.8906 7.7026 12.4953 7.62031 12.0089 7.62604C11.3984 7.63385 10.9245 7.77969 10.5594 8.07344C9.87083 8.6276 9.8125 9.23073 9.8125 9.23958C9.80788 9.33534 9.78445 9.42925 9.74354 9.51595C9.70263 9.60265 9.64505 9.68044 9.57407 9.74488C9.5031 9.80933 9.42013 9.85916 9.32989 9.89154C9.23966 9.92392 9.14393 9.93821 9.04818 9.93359C8.95242 9.92898 8.85851 9.90555 8.77181 9.86464C8.68511 9.82373 8.60732 9.76614 8.54288 9.69517C8.47843 9.62419 8.4286 9.54122 8.39622 9.45099C8.36384 9.36075 8.34955 9.26503 8.35417 9.16927C8.3599 9.04271 8.44792 7.9026 9.64427 6.9401C10.2646 6.44115 11.0536 6.18177 11.988 6.17031C12.6495 6.1625 13.2708 6.27448 13.6922 6.47344C14.9531 7.06979 15.6458 8.06406 15.6458 9.26927C15.6458 11.0312 14.4682 11.8224 13.4292 12.5208Z"
      fill="#FFD112"
    />
  </svg>
);
