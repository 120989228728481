import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "../../configs/theme.config";
import { useGetTariffs } from "../../services/queries/use-get-tariffs";
import DataMap from "../../components/data-map/data-map";
import { useTranslation } from "react-i18next";
import {
  ScrollButtonWrapper,
  TarrifsPageServicePrice,
  TarrifsPageServiceTitle,
  TarrifsPaper,
} from "./styles";
import { priceFromatter } from "../../utils/price-formatter";
import { useGetTariffsInfo } from "../../services/queries/use-get-tariffs-info";
import Image from "../../components/image/image";
import DefaultImage from "../../assets/images/default-image.png";
import ScrollButtonLeftIcon from "../../components/icons/scroll-button-left";
import ScrollButtonRightIcon from "../../components/icons/scroll-button-right.";
import Footer from "../../layouts/footer/footer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ScrollButtons: React.ElementType<any> | undefined = (props) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(850));
  return (
    <ScrollButtonWrapper
      {...props}
      style={{
        [props.direction]: isSmallScreen ? 4 : "-22px",
        display: props.disabled ? "none" : "flex",
        transform: "0.3s",
      }}
    >
      {console.log(props)}

      {props.direction === "left" ? (
        <ScrollButtonLeftIcon />
      ) : (
        <ScrollButtonRightIcon />
      )}
    </ScrollButtonWrapper>
  );
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      pt={5}
      sx={{
        px: { xs: 2, md: 0 },
        pt: 4,
        height: "100%",
        bgcolor: { xs: "#f8f8f8", md: "transparent" },
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Tariffs = () => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(850));
  const [region, setRegion] = React.useState("tashkent");
  const [value, setValue] = React.useState(0);

  const { data: tariffs, isLoading: isTariffsLoading } = useGetTariffs(
    JSON.stringify({ lat: 41.31071925530611, lon: 69.24161656206479 })
  );

  const {
    data: tariffsInfo,
    isLoading: isTariffsInfoLoading,
  } = useGetTariffsInfo({
    tariff_ids: tariffs?.groups
      ?.map((group) => [
        ...(group?.tariffs?.map((tariff) => tariff.id) || []),
        ...(group?.categories
          ?.map((category) => category.tariffs?.map((tariff) => tariff.id))
          .reduce((t: Array<number>, c) => (c ? [...t, ...c] : []), []) || []),
      ])
      .reduce((t: Array<number>, c) => (c ? [...t, ...c] : []), []),
  });

  const handleRegionChange = (event: SelectChangeEvent) => {
    setRegion(event.target.value as string);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    tariffsInfo?.tariffs?.[0]?.id &&
      value === 0 &&
      setValue(tariffsInfo?.tariffs?.[0]?.id);
  }, [tariffsInfo, isTariffsInfoLoading]);

  return (
    <Box
      sx={{
        pt: isSmallScreen ? 0 : "108px",
        pb: 4,
        px: isSmallScreen ? 0 : "48px",
        bgcolor: theme.palette.background.default,
        overflowY: "auto",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          mx: "auto",
          maxWidth: "1344px",
          padding: isSmallScreen ? 0 : 4,
          boxShadow: "none",
          height: isSmallScreen ? "100%" : "fit-content",
          borderRadius: isSmallScreen ? 0 : "24px",
        }}
      >
        <Box
          sx={{
            mb: { xs: 3, md: 4 },
            pt: { xs: 2, md: 0 },
            px: { xs: 1, md: 0 },
          }}
        >
          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={region}
            onChange={handleRegionChange}
            sx={{
              fontSize: {
                xs: "22px",
                md: "36px",
              },
              borderRadius: 24,
              overflow: "hidden",
              backgroundColor: "transparent",
              ":hover": { backgroundColor: "transparent" },
              ".MuiSelect-icon": {
                top: "50%",
                transform: "translateY(-50%)",
                height: isSmallScreen ? "20px" : "24px",
                width: isSmallScreen ? "20px" : "24px",
              },
            }}
            SelectDisplayProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
            variant="filled"
            IconComponent={ArrowDownIcon}
          >
            <MenuItem value={"tashkent"}>{t("tariffs in Tashkent")}</MenuItem>
          </Select> */}
          <Typography
            sx={{
              fontSize: {
                xs: "22px",
                md: "36px",
              },
              color: "#2c2738", // need to verified by designer
            }}
          >
            {t("tariffs in Tashkent")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          {isTariffsInfoLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress sx={{ mx: "auto" }} size={"70px"} />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  pl: { xs: 2, md: 0 },
                  position: "relative",
                }}
              >
                <Tabs
                  variant="scrollable"
                  aria-label="scrollable auto tabs example"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  textColor="inherit"
                  ScrollButtonComponent={ScrollButtons}
                  sx={{
                    minHeight: "initial",
                    ".MuiButtonBase-root": {
                      fontWeight: 600,
                      textTransform: "capitalize",
                      fontSize: "18px",
                      px: 1,
                      pt: 0.5,
                      pb: 1.5,
                      lineHeight: "19.8px",
                      minWidth: "30px",
                      minHeight: "initial",
                    },
                  }}
                  indicatorColor="primary"
                  value={value}
                  onChange={handleChange}
                >
                  {tariffsInfo?.tariffs.map((tariff) => (
                    <Tab
                      label={tariff.name}
                      value={tariff.id}
                      {...a11yProps(tariff.id)}
                    />
                  ))}
                </Tabs>
              </Box>
              {tariffsInfo?.tariffs.map((tariff) => (
                <TabPanel value={value} index={tariff.id}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap-reverse",
                    }}
                  >
                    <Box sx={{ width: isSmallScreen ? "100%" : "548px" }}>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "24px",
                            md: "28px",
                          },
                        }}
                      >
                        {tariff.name}{" "}
                        {t("from", {
                          text: `${priceFromatter(tariff.minimal_cost)} ${t(
                            "sum"
                          )}`,
                        })}
                      </Typography>
                      <TarrifsPaper
                        sx={{
                          py: { xs: 0.5, md: 2 },
                          px: { xs: 2, md: 3 },
                          mt: 1.5,
                        }}
                      >
                        <DataMap
                          wrapper={false}
                          listItemProps={{ py: 1.5 }}
                          datalist={[
                            {
                              left: (
                                <div>
                                  <TarrifsPageServiceTitle mb={0.75}>
                                    {t("minimum cost")}
                                  </TarrifsPageServiceTitle>
                                  <Typography
                                    fontWeight={500}
                                    sx={{
                                      fontSize: { xs: "12px", md: "14px" },
                                    }}
                                    color={theme.palette.text.secondary}
                                  >
                                    {t("included min and km", {
                                      min:
                                        (tariff?.free_waiting_time || 0) / 60,
                                      km: tariff?.minimal_km,
                                    })}
                                  </Typography>
                                </div>
                              ),
                              right: (
                                <TarrifsPageServicePrice>
                                  {t("from", {
                                    text: `${tariff.minimal_cost} ${t("sum")}`,
                                  })}
                                </TarrifsPageServicePrice>
                              ),
                              hide: !tariff.minimal_cost,
                            },
                            {
                              left: (
                                <TarrifsPageServiceTitle>
                                  {t("cost of", { unit: t("km"), amount: 1 })}
                                </TarrifsPageServiceTitle>
                              ),
                              right: (
                                <TarrifsPageServicePrice>
                                  {priceFromatter(tariff.price_per_km_in)}{" "}
                                  {t("sum")}
                                </TarrifsPageServicePrice>
                              ),
                              hide: !tariff.price_per_km_in,
                            },
                            {
                              left: (
                                <TarrifsPageServiceTitle>
                                  {t("cost of", {
                                    unit: t("minute"),
                                    amount: 1,
                                  })}
                                </TarrifsPageServiceTitle>
                              ),
                              right: (
                                <TarrifsPageServicePrice>
                                  {priceFromatter(
                                    tariff.driving_cost_per_minute
                                  )}{" "}
                                  {t("sum")}
                                </TarrifsPageServicePrice>
                              ),
                              hide: !tariff.driving_cost_per_minute,
                            },
                            {
                              left: (
                                <TarrifsPageServiceTitle>
                                  {t("paid waiting")}
                                </TarrifsPageServiceTitle>
                              ),
                              right: (
                                <TarrifsPageServicePrice>
                                  {priceFromatter(tariff.price_per_minute)}{" "}
                                  {t("sum")}
                                </TarrifsPageServicePrice>
                              ),
                              hide: !tariff.price_per_minute,
                            },
                            {
                              left: (
                                <TarrifsPageServiceTitle>
                                  {t("free waiting")}
                                </TarrifsPageServiceTitle>
                              ),
                              right: (
                                <TarrifsPageServicePrice>
                                  {priceFromatter(
                                    (tariff.free_waiting_time || 0) / 60
                                  )}{" "}
                                  {t("minute")}
                                </TarrifsPageServicePrice>
                              ),
                              hide: !tariff.free_waiting_time,
                            },
                            {
                              left: (
                                <TarrifsPageServiceTitle>
                                  {t("price outside the city")}
                                </TarrifsPageServiceTitle>
                              ),
                              right: (
                                <TarrifsPageServicePrice>
                                  {priceFromatter(tariff.price_per_km_out)}{" "}
                                  {t("sum")}/{t("km")}
                                </TarrifsPageServicePrice>
                              ),
                              hide: !tariff.price_per_km_out,
                            },
                          ]}
                          mt={1}
                        />
                      </TarrifsPaper>
                      <TarrifsPaper
                        sx={{
                          py: { xs: 1.5, md: 2 },
                          px: { xs: 2, md: 3 },
                          mt: 1.5,
                        }}
                      >
                        <TarrifsPageServiceTitle mb={0.5}>
                          {t("description")}
                        </TarrifsPageServiceTitle>
                        <Typography fontWeight={500} fontSize="18px">
                          {tariff.desc}
                        </Typography>
                      </TarrifsPaper>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                        padding: 3,
                      }}
                    >
                      {tariff?.tariff_image && (
                        <Image
                          src={tariff?.tariff_image}
                          style={{
                            width: "498px",
                            maxWidth: isSmallScreen ? "63%" : "100%",
                            minWidth: "200px",
                          }}
                          defaultImage={DefaultImage}
                        />
                      )}
                    </Box>
                  </Box>
                </TabPanel>
              ))}
            </>
          )}
        </Box>
      </Paper>
      {!isSmallScreen && <Footer />}
    </Box>
  );
};

export default Tariffs;
