import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isNumber } from "../../utils/is-number";

type Free = "free";
type Input = "input";
type Pin = "pin";

export type OrderPointInputModeType = Free | Input | Pin;

type RouteState = {
  mode: OrderPointInputModeType;
  inputs: string[];
  searchQueries: string[];
  currentlyOnFocus: number;
  location: number[] | undefined;
  geolocation_permission: boolean;
  isNotReady: boolean;
  autoZoom: boolean;
};

const initialState: RouteState = {
  mode: "pin",
  inputs: [],
  searchQueries: ["", ""],
  currentlyOnFocus: 0,
  location: undefined,
  geolocation_permission: false,
  isNotReady: false,
  autoZoom: true,
};

const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setInputs: (state, action: PayloadAction<string[]>) => {
      state.inputs = action.payload;
    },
    updateCoordinate: (
      state,
      action: PayloadAction<{ index: number; value?: string }>
    ) => {
      if (action.payload.value) {
        state.inputs[action.payload.index] = action.payload.value;
      }
    },
    changeInputMode: (
      state,
      action: PayloadAction<{
        mode: OrderPointInputModeType;
        currentlyOnFocus?: number;
      }>
    ) => {
      if (typeof action.payload.currentlyOnFocus === "number") {
        state.currentlyOnFocus = action.payload.currentlyOnFocus;
      }

      if (action.payload.mode === "free" && state.inputs.length <= 1) {
        state.mode = "pin";
        state.currentlyOnFocus = 0;
        return;
      }

      state.mode = action.payload.mode;
    },
    searchAddress: (
      state,
      action: PayloadAction<{ index: number; value: string | undefined }>
    ) => {
      if (typeof action.payload.value === "string") {
        state.searchQueries[action.payload.index] = action.payload.value;
      }
    },
    createInput: (state) => {
      if (state.searchQueries.length < 5) {
        state.searchQueries.splice(state.searchQueries.length, 0, "");
        state.inputs.splice(state.inputs.length, 0, "{}");
      }
    },
    deleteInput: (state, action: PayloadAction<number>) => {
      if (state.inputs.length > 1 && action.payload > 0)
        state.inputs.splice(action.payload, 1);

      if (state.searchQueries.length > 2) {
        state.searchQueries.splice(action.payload, 1);
        return;
      }

      if (state.searchQueries.length > 1)
        state.searchQueries[action.payload] = "";
    },
    resetRouteSlice: (state) => {
      state = initialState;
    },
    setLocation: (state, action: PayloadAction<number[]>) => {
      if (isNumber(action.payload[0]) && isNumber(action.payload[1])) {
        state.location = action.payload;
        state.geolocation_permission =
          isNumber(action.payload[0]) && isNumber(action.payload[1]);
      }
    },
    setGeolocationPermission: (state, action: PayloadAction<boolean>) => {
      state.geolocation_permission = action.payload;
    },
    setIsNotReady: (state, action: PayloadAction<boolean>) => {
      state.isNotReady = action.payload;
    },
    clearRoute: (state) => {
      state.inputs.splice(1);
      state.searchQueries = ["", ""];
      state.currentlyOnFocus = 0;

      if (state.mode === "free") state.mode = "pin";
    },
    setAutoZoom: (state, action: PayloadAction<boolean>) => {
      state.autoZoom = action.payload;
    },
  },
});

export const {
  updateCoordinate,
  changeInputMode,
  searchAddress,
  createInput,
  deleteInput,
  setInputs,
  resetRouteSlice,
  setLocation,
  setGeolocationPermission,
  setIsNotReady,
  clearRoute,
  setAutoZoom,
} = routeSlice.actions;

export default routeSlice.reducer;
