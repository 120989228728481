import { useQuery } from "react-query";
import { useAppSelector } from "../../redux/hook";
import request from "../request";

interface CardsType {
  cards?: {
    card_status: string;
    expire: string;
    id: number;
    name: string;
    number: string;
    type: "upay" | "payme";
  }[];
  status: "success";
}

export const useGetUserCards = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery(
    "user-cards",
    () =>
      request.private.get<CardsType>("v1/paycom/cards").then((res) => res.data),
    { enabled: !!isAuthenticated }
  );
};
