import styled from "styled-components";
import { Textarea } from "../main-panel/components/address-bar/address-bar.styles";

const TextArea = styled(Textarea)<{
  fullWidth?: boolean;
  width?: string;
  height?: string;
}>`
  width: ${({ fullWidth, width }) => (fullWidth ? "100%" : width || "auto")};
  height: ${({ height }) => height || "auto"};
  resize: none;
  border: none;
  border-radius: ${({ theme }) => theme.shape.borderRadius + "px"};
  padding: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: #f9f9f9;

  :hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
`;

export default TextArea;
