import { IconType } from "../../types/utility.types";

const FlagRu: IconType = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.00024 16.9354V20.374C1.00024 21.7998 2.13248 22.8062 3.51632 22.8062H24.4836C25.8674 22.8062 26.9997 21.7998 26.9997 20.374V16.9354H1.00024Z"
      fill="#EC2227"
    />
    <path
      d="M3.51632 5.44373H24.4836C25.7141 5.44373 26.7497 6.45676 26.7497 7.95064V11.5983H1.25024V7.95064C1.25024 6.45676 2.28586 5.44373 3.51632 5.44373Z"
      fill="white"
      stroke="#D9D9D9"
      strokeWidth="0.5"
    />
    <path
      d="M26.9997 11.0646H1.00024V16.9354H26.9997V11.0646Z"
      fill="#005EA5"
    />
  </svg>
);

export default FlagRu;
