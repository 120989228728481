import { useQuery } from "react-query";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import request from "../request";
import { setLanguage } from "../../redux/slices/auth-slice";
import { useTranslation } from "react-i18next";
import queryClient from "../../configs/react-query.config";
import { Languages } from "../../lib/i18next";

export interface ClientProfileType {
  app_lang: "ru" | "uz" | "en";
  birth_date: string;
  comp_id: number;
  corp_id_manager: number;
  email: string;
  fcm_token_exists: number;
  first_name: string;
  gender: "male" | "female";
  is_bonus_enabled: number;
  is_manager: boolean;
  orders_count: number;
  phone: string;
  user_pic: string;
  multi_order_limit: number | null;
}

export const useGetClientProfile = () => {
  const { isAuthenticated, language } = useAppSelector((state) => state.auth);
  const appDispatch = useAppDispatch();
  const { i18n } = useTranslation();

  return useQuery(
    "client-profile",
    () =>
      request.private
        .get<ClientProfileType>("v1/client-profile")
        .then((res) => {
          if (
            i18n.language !== language ||
            language !== res.data.app_lang ||
            res.data.app_lang !== i18n.language
          ) {
            appDispatch(setLanguage(res?.data?.app_lang as Languages));
            i18n.changeLanguage(res?.data?.app_lang).then(() => {
              queryClient.invalidateQueries();
            });
          }

          return res.data;
        }),
    { enabled: isAuthenticated }
  );
};
