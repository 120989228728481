import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconWrapper } from "../../../layouts/header/components/auth/welcome/styles";
import { formatUZB } from "../../../utils/number-formatter";
import Button from "../../buttons/button";
import { PhoneSquaredIcon } from "../../icons/phone-squared";
import { Modal } from "../../modal/modal";

const DriverContactModal: React.FC<{
  isOpen: boolean;
  close: () => void;
  driverNumber: string;
  text?: string;
}> = ({ isOpen, close, driverNumber, text }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Modal
      style={{ width: "450px" }}
      isOpen={isOpen}
      onClose={close}
      closeIcon={false}
    >
      <Stack direction="column" alignItems="center">
        <IconWrapper>
          <Stack spacing={1} direction="row">
            <PhoneSquaredIcon />
          </Stack>
        </IconWrapper>
        <Typography variant="h2">{formatUZB(driverNumber)}</Typography>
        {text && (
          <Typography
            textAlign="center"
            variant="subtitle2"
            fontWeight="500"
            mt={1}
            color={theme.palette.text.secondary}
          >
            {text}
          </Typography>
        )}
        <Button
          color="secondary"
          sx={{ mt: 3 }}
          fullWidth
          onClick={close}
          onSubmit={close}
        >
          {t("cancellation")}
        </Button>
      </Stack>
    </Modal>
  );
};

export default DriverContactModal;
