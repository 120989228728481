import { useMutation } from "react-query";
import request from "../request";

interface profileData {
  first_name?: string | null;
  last_name?: string | null;
  gender?: "male" | "female" | null;
  birth_date?: string | null;
  email?: string | null;
}

export const useEditProfile = () =>
  useMutation((data: profileData) =>
    request.private.post("v1/client-profile", data).then((res) => res.data)
  );
