import React from "react";
import {
  DropDownMenuItem,
  DropDownMenuLink,
  DropDownMenuList,
  DropDownMenuWrapper,
  PhoneWrapper,
  RightDownArrow,
} from "./header-drop-down.style";
import { DropDownMenuProps } from "./header-drop-down.types";
import DownArrow from "../../../../components/icons/down-arrow";
import { Phone } from "../../../../components/icons/phone";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { openSupportModal } from "../../../../redux/slices/modal-slice";
import { setAuthModalOpen } from "../../../../redux/slices/auth-slice";
import { NavLink } from "react-router-dom";
import useModal from "../../../../hooks/use-modal";
import DownloadAppModal from "../../../../components/modals/download-app-modal/download-app-modal";

export const HeaderDropDownMenu: React.FC<DropDownMenuProps> = ({ isShow }) => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const DownloadApp = useModal();

  return (
    <DropDownMenuList isShow={isShow}>
      <DownloadAppModal isOpen={DownloadApp.isOpen} close={DownloadApp.close} />
      <DropDownMenuWrapper>
        <DropDownMenuItem>
          <NavLink to={"/tariffs"}>
            <DropDownMenuLink>
              {t("tariffs")}
              <RightDownArrow>
                <DownArrow
                  style={{
                    transform: "rotate(-90deg)",
                    height: 20,
                    width: "auto",
                    fill: "#999999",
                  }}
                />
              </RightDownArrow>
            </DropDownMenuLink>
          </NavLink>
        </DropDownMenuItem>
        <DropDownMenuItem>
          <DropDownMenuLink
            onClick={() => {
              if (!isAuthenticated) {
                appDispatch(setAuthModalOpen());
              } else {
                appDispatch(openSupportModal());
              }
            }}
          >
            {t("write to support")}
            <RightDownArrow>
              <DownArrow
                style={{
                  transform: "rotate(-90deg)",
                  height: 20,
                  width: "auto",
                  fill: "#999999",
                }}
              />
            </RightDownArrow>
          </DropDownMenuLink>
        </DropDownMenuItem>
        <DropDownMenuItem>
          <DropDownMenuLink href="https://t.me/call_center" target="_blank">
            {t("telegram")}
            <RightDownArrow>
              <DownArrow
                style={{
                  transform: "rotate(-90deg)",
                  height: 20,
                  width: "auto",
                  fill: "#999999",
                }}
              />
            </RightDownArrow>
          </DropDownMenuLink>
        </DropDownMenuItem>
        <DropDownMenuItem>
          <DropDownMenuLink onClick={DownloadApp.open}>
            {t("downloadApp")}
          </DropDownMenuLink>
        </DropDownMenuItem>
      </DropDownMenuWrapper>
      <PhoneWrapper>
        <DropDownMenuLink href="tel:+998712021515">
          <Phone style={{ marginRight: 9 }} /> +998 71 202-15-15
        </DropDownMenuLink>
      </PhoneWrapper>
    </DropDownMenuList>
  );
};
