import { Box, ButtonBase, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import theme from "../../../configs/theme.config";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { closeToast } from "../../../redux/slices/modal-slice";
import CheckedCircledIcon from "../../icons/checked-circled";
import { ToastWrapper } from "../toast.styles";

const SuccessToast: React.FC = () => {
  const { success_toast, offline_toast } = useAppSelector(
    (state) => state.modal
  );
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = success_toast
      ? setTimeout(
          () => appDispatch(closeToast()),
          success_toast?.time ? success_toast?.time : 5000
        )
      : 0;

    return () => clearTimeout(timeout);
  }, [success_toast]);

  return (
    <ToastWrapper
      isOpen={!!success_toast?.isOpen}
      sx={{
        bgcolor: theme.palette.background.paper,
        px: 1.5,
        width: "303px",
        display: "flex",
        justifyContent: "space-between",
        top: offline_toast ? "160px" : "100px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {success_toast?.icon ? (
          success_toast?.icon
        ) : (
          <Box sx={{ width: "28px", height: "28px" }}>
            <CheckedCircledIcon />
          </Box>
        )}
        <Box ml={1.5} maxWidth="100%">
          <Typography maxWidth="100%" lineHeight={"19px"}>
            {success_toast?.message
              ? success_toast?.message
              : t("successfully send")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Divider
          orientation="vertical"
          sx={{ height: "28px", width: "0.5px", mr: 1.25 }}
        />
        <ButtonBase
          onClick={() => appDispatch(closeToast())}
          sx={{ color: theme.palette.action.focus }}
        >
          {t("close")}
        </ButtonBase>
      </Box>
    </ToastWrapper>
  );
};

export default SuccessToast;
