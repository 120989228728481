import { Box } from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../../configs/colors.style";
import { AttributeContainerType } from "./radio.types";

const Container = styled.div`
  display: flex;
  margin-left: 11.75px;
`;

const AttributeContainer = styled(Box)<AttributeContainerType>`
  display: flex;
  align-items: center;
  position: relative;
  color: white !important;
  border-radius: ${({ theme }) => theme.shape.borderRadius + "px"};
  ${({ theme, disabled }) =>
    disabled &&
    `&:before {
		border-radius: ${theme.shape.borderRadius + "px"};
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.1);
	}`}
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  font-size: 11px;
  color: #818181;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    color: ${COLORS.buttonYellow};
  }
`;

const Checkmark = styled.span`
  position: relative;
  top: 0;
  left: 0;
  min-height: 32px;
  min-width: 32px;
  background-color: ${COLORS.buttonGrey};
  border-radius: 50%;
  & > svg {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const Input = styled.input`
  display: none;
  &:checked ~ ${Checkmark} {
    transition: all 0.25s ease-in-out;
    background-color: transparent;
  }
  &:checked ~ ${Checkmark} > svg {
    display: block;
  }
`;

export { Label, Input, Checkmark, Container, AttributeContainer };
