import { ButtonBase, ButtonBaseProps } from "@mui/material";
import ModalBack from "../../icons/modal-back";

const ModalBackButton: React.FC<ButtonBaseProps> = ({ sx, ...props }) => (
  <ButtonBase
    sx={{
      position: "absolute",
      left: 0,
      top: (theme) => theme.spacing(2),
      transform: "translateX(-50%)",
    }}
    {...props}
  >
    <ModalBack />
  </ButtonBase>
);

export default ModalBackButton;
