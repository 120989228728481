import { PointType, RoutePointsType } from "../types/global.types";

const routeParser = (
  order?: {
    from_adres: string | null;
    to_adres: string | null;
    coordinates_a_x: number | null;
    coordinates_a_y: number | null;
    coordinates_b_x: number | null;
    coordinates_b_y: number | null;
    route_points?: RoutePointsType | null;
  },
  include?: { Bpoint?: boolean }
): PointType[] => {
  if (!order) return [];

  const Apoint = {
    lat: order?.coordinates_a_x,
    lon: order?.coordinates_a_y,
    address: order?.from_adres,
  };

  const Bpoint = include?.Bpoint
    ? {
        lat: order?.coordinates_b_x,
        lon: order?.coordinates_b_y,
        address: order?.to_adres,
      }
    : undefined;

  const inputs = [
    Apoint,
    order?.route_points?.b,
    order?.route_points?.c,
    order?.route_points?.d,
    order?.route_points?.e,
    Bpoint,
  ].filter((e) => !!(e?.address && e.lat && e.lon));

  return inputs as PointType[];
};

export default routeParser;
