import React from "react";
import { IconType } from "../../types/utility.types";

const ICMytaxiIcon: IconType = (props) => (
  <svg
    width={81}
    height={80}
    viewBox="0 0 81 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width={80} height={80} rx={14} fill="#FCE300" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8349 34.8354H62.0863C65.6256 34.8354 68.5212 37.7356 68.5212 41.2805V56.695C68.5212 60.2399 65.6256 63.1402 62.0863 63.1402H18.8349C15.2956 63.1402 12.4 60.2399 12.4 56.695V41.2805C12.4 37.7356 15.2956 34.8354 18.8349 34.8354Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6699 53.8976C26.9332 54.3837 26.1784 54.6266 25.4056 54.6266C24.7227 54.6266 24.1836 54.4152 23.7882 53.9922C23.3928 53.5692 23.1951 52.9527 23.1951 52.1428V45.8252H27.7776V43.5033H23.1951V39.3456H20.3919V43.5033H17.5075V45.8252H20.3919V52.3318C20.3919 53.9516 20.8232 55.18 21.6858 56.0171C22.5483 56.854 23.7255 57.2725 25.2169 57.2725C26.2772 57.2725 27.3824 56.9305 28.5324 56.2465L27.6699 53.8976ZM42.0218 43.5032H39.2184V45.0421C37.9964 43.7642 36.3791 43.1253 34.3663 43.1253C32.5692 43.1253 31.0148 43.7913 29.703 45.1231C28.3912 46.455 27.7351 48.147 27.7351 50.1989C27.7351 52.2688 28.3867 53.9652 29.6895 55.2881C30.9924 56.6109 32.5513 57.2725 34.3663 57.2725C36.3791 57.2725 37.9964 56.6335 39.2184 55.3555V56.8944H42.0218V43.5032ZM34.8246 54.7885C33.5669 54.7885 32.5246 54.3702 31.6978 53.5331C30.8712 52.6961 30.4579 51.5848 30.4579 50.1989C30.4579 48.8129 30.8712 47.7015 31.6978 46.8646C32.5246 46.0276 33.5669 45.6092 34.8246 45.6092C35.9929 45.6092 37.0171 46.0367 37.8976 46.8916C38.7782 47.7464 39.2185 48.8489 39.2185 50.1989C39.2185 51.5487 38.7782 52.6512 37.8976 53.5061C37.0171 54.3612 35.9929 54.7885 34.8246 54.7885ZM52.5999 50.334L57.398 43.4764H54.0286L50.5245 48.5521L47.0202 43.4764H43.6777L48.4758 50.334L43.6777 56.8945H47.0202L50.5245 52.0619L54.0286 56.8945H57.398L52.5999 50.334ZM60.644 37.7257C60.123 37.7257 59.6918 37.8967 59.3503 38.2386C59.0088 38.5806 58.8381 38.9946 58.8381 39.4806C58.8381 39.9845 59.0088 40.3985 59.3503 40.7226C59.6918 41.0465 60.123 41.2084 60.644 41.2084C61.1653 41.2084 61.5921 41.0465 61.9245 40.7226C62.257 40.3985 62.4232 39.9845 62.4232 39.4806C62.4232 38.9946 62.2525 38.5806 61.911 38.2386C61.5695 37.8967 61.1472 37.7257 60.644 37.7257ZM62.0459 56.8944V43.5032H59.2426V56.8944H62.0459Z"
      fill="#FCE300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.8559 16.4764C34.6995 16.4764 33.0372 17.2954 31.8691 18.9334C31.4377 18.1594 30.8313 17.5565 30.0496 17.1244C29.2679 16.6926 28.3919 16.4764 27.4214 16.4764C25.5885 16.4764 24.1598 17.1065 23.1355 18.3664V16.8545H20.3321V30.2457H23.1355V23.604C23.1355 22.1281 23.4544 21.0032 24.0923 20.2293C24.7304 19.4553 25.5975 19.0684 26.6936 19.0684C27.5742 19.0684 28.2975 19.3608 28.8635 19.9458C29.4296 20.5307 29.7126 21.3093 29.7126 22.2812V30.2457H32.5161V23.604C32.5161 22.1281 32.8395 21.0032 33.4865 20.2293C34.1335 19.4553 35.0139 19.0684 36.1281 19.0684C37.0087 19.0684 37.732 19.3608 38.2979 19.9458C38.8641 20.5307 39.1471 21.3093 39.1471 22.2812V30.2457H41.9236V21.7142C41.9236 20.1662 41.4339 18.9065 40.4544 17.9344C39.4751 16.9625 38.2755 16.4764 36.8559 16.4764ZM56.8687 16.8274H53.7418L49.4558 26.4927L45.2777 16.8274H42.1508L47.9193 29.9216L45.736 34.8353H48.8629L56.8687 16.8274Z"
      fill="black"
    />
  </svg>
);

export default ICMytaxiIcon;
