import { useAppDispatch, useAppSelector } from "../redux/hook";
import { useEditClientLanguage } from "../services/mutations/use-edit-client-language";
import { useTranslation } from "react-i18next";
import { Languages } from "../lib/i18next";
import { setLanguage } from "../redux/slices/auth-slice";
import queryClient from "../configs/react-query.config";

const useChangeLanguage = () => {
  const appDispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { i18n } = useTranslation();

  const { mutate } = useEditClientLanguage();

  const handleLanguageChange = (lang: string) => {
    if (isAuthenticated && lang.length == 2) {
      appDispatch(setLanguage(lang as Languages));
      i18n.changeLanguage(lang);
      // @ts-expect-error
      mutate(lang, {
        onSuccess: () => {
          queryClient.invalidateQueries();
        },
      });
    }

    if (!isAuthenticated) {
      appDispatch(setLanguage(lang as Languages));
      i18n.changeLanguage(lang).then((res) => queryClient.invalidateQueries());
    }
  };

  return handleLanguageChange;
};

export default useChangeLanguage;
