import React, { useEffect } from "react";
import styled from "styled-components";
import { AppLayoutProps } from "./types";
import { Header } from "../header/header";
import { useMediaQuery, useTheme } from "@mui/material";
import Adaptive from "../adaptive/adaptive";
import { useLocation, useNavigate } from "react-router-dom";
import useChangeLanguage from "../../hooks/use-change-language";
import featureConfig from "../../configs/feature-config";

const isAdaptive = ({
  strings,
  pathname,
}: {
  pathname: string;
  strings: string[];
}): boolean => {
  for (const str of strings) {
    if (pathname.startsWith(str)) return true;
  }

  return false;
};

export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(850));

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const changeLanguage = useChangeLanguage();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const lang = searchParams.get("lang");

    if (!lang) return;

    searchParams.delete("lang");
    changeLanguage(lang);

    navigate(pathname + new URLSearchParams(searchParams).toString());
  }, [search]);

  return isSmallScreen &&
    !isAdaptive({
      pathname,
      strings: ["/tariffs", "/legal", "/agreement", "/terms-and-conditions"],
    }) ? (
    <Adaptive />
  ) : (
    <AppWrapper>
      {!isSmallScreen && !featureConfig.technical_work && <Header />}
      {children}
    </AppWrapper>
  );
};

export const AppWrapper = styled.div`
  position: relative;
`;
