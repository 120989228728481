import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import store from "../../redux/store";

i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`../../locales/${language}/${namespace}.json`)
        .then(({ default: resources }) => {
          // with dynamic import, you have to use the "default" key of the module ( https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#importing_defaults )
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .use(initReactI18next)
  .init({
    ns: ["translation"],
    initImmediate: false,
    fallbackLng: store.getState().auth.language,
    lng: store.getState().auth.language,
    // editor: {
    //   onEditorSaved: async (lng, ns) => {
    //     await i18n.reloadResources(lng, ns);
    //     i18n.emit('editorSaved');
    //   },
    // },
    react: {
      bindI18n: "languageChanged editorSaved",
      useSuspense: false,
    },
  });

export { i18n };
