import { IconType } from "../../types/utility.types";

const ScrollButtonLeftIcon: IconType = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2899 12.0002L14.8299 8.46019C15.0162 8.27283 15.1207 8.01938 15.1207 7.75519C15.1207 7.49101 15.0162 7.23756 14.8299 7.05019C14.737 6.95646 14.6264 6.88207 14.5045 6.8313C14.3827 6.78053 14.252 6.75439 14.1199 6.75439C13.9879 6.75439 13.8572 6.78053 13.7354 6.8313C13.6135 6.88207 13.5029 6.95646 13.4099 7.05019L9.16994 11.2902C9.07622 11.3832 9.00182 11.4938 8.95105 11.6156C8.90028 11.7375 8.87415 11.8682 8.87415 12.0002C8.87415 12.1322 8.90028 12.2629 8.95105 12.3848C9.00182 12.5066 9.07622 12.6172 9.16994 12.7102L13.4099 17.0002C13.5034 17.0929 13.6142 17.1662 13.736 17.216C13.8579 17.2657 13.9883 17.291 14.1199 17.2902C14.2516 17.291 14.382 17.2657 14.5038 17.216C14.6257 17.1662 14.7365 17.0929 14.8299 17.0002C15.0162 16.8128 15.1207 16.5594 15.1207 16.2952C15.1207 16.031 15.0162 15.7776 14.8299 15.5902L11.2899 12.0002Z"
      fill="#8C8B88"
    />
  </svg>
);

export default ScrollButtonLeftIcon;
