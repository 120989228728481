import {
  closeAuthModalType,
  openAuthModalType,
  SetBackButtonType,
  setPhoneNumberType,
} from "./auth-types";

export const setPhoneNumber = (phone_number: string): setPhoneNumberType => ({
  type: "SET_PHONE_NUMBER",
  payload: { phone_number },
});

export const setBackButton = (back_button: boolean): SetBackButtonType => ({
  type: "SET_BACK_BUTTON",
  payload: { back_button },
});

export const openAuthModal = (): openAuthModalType => ({
  type: "OPEN_MODAL",
});

export const closeAuthModal = (): closeAuthModalType => ({
  type: "CLOSE_MODAL",
});
