import AppLogo from "../../components/icons/app-logo";
import {
  AppHeader,
  HeaderMenuAnchorLink,
  HeaderMenuLink,
  HeaderMenuLinkButton,
  HeaderMiddle,
  HeaderMiddleWrapper,
  HeaderNavMenu,
  HeaderWrapper,
} from "./header.style";
import { HeaderDropDown } from "./components/header-drop-down/header-drop-down";
import { useTranslation } from "react-i18next";
import HeaderProfile from "./components/header-profile/header-profile";
import MultiLevelMenuProvider from "../../context/multi-level-menu/multi-level-menu";
import { Auth } from "./components/auth";
import { NavLink, useLocation } from "react-router-dom";
import OrderHistoryModal from "./components/order-history-modal/order-history-modal";
import SupportModal from "./components/support-modal/support-modal";
import useModal from "../../hooks/use-modal";
import DownloadAppModal from "../../components/modals/download-app-modal/download-app-modal";
import Private from "../../components/private/private";

export const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <HeaderWrapper>
      <Private>
        <OrderHistoryModal />
      </Private>
      <SupportModal />
      <AppHeader isShadowRemoved={pathname.startsWith("/tariffs")}>
        <div>
          <NavLink to="/">
            <AppLogo />
          </NavLink>
        </div>
        <HeaderMiddle>
          <HeaderMiddleWrapper>
            <HeaderNavMenu>
              <HeaderMenuAnchorLink
                href="https://company.mytaxi.uz"
                target="_blank"
              >
                {t("header.about-company")}
              </HeaderMenuAnchorLink>
              <HeaderMenuAnchorLink
                href="https://business.mytaxi.uz/"
                target="_blank"
              >
                {t("header.business")}
              </HeaderMenuAnchorLink>
              <HeaderMenuAnchorLink
                href="https://drivers.mytaxi.uz/"
                target="_blank"
              >
                {t("header.drivers")}
              </HeaderMenuAnchorLink>
              <Private>
                <HeaderMenuLink to="/?history=finished">
                  {t("myTrips")}
                </HeaderMenuLink>
              </Private>
              <HeaderDropDown />
            </HeaderNavMenu>
          </HeaderMiddleWrapper>
        </HeaderMiddle>
        <MultiLevelMenuProvider>
          <HeaderProfile />
        </MultiLevelMenuProvider>
      </AppHeader>
      <Auth />
    </HeaderWrapper>
  );
};
