import { Box, Button, ButtonBase, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoSquaredIcon from "../../components/icons/logo-squared";
import { AdaptiveWrapper } from "./adaptive.styles";
import LanguageControl from "../header/components/language-control";
import { COLORS } from "../../configs/colors.style";
import DownArrow from "../../components/icons/down-arrow";

const Adaptive = () => {
  const { t } = useTranslation();

  return (
    <AdaptiveWrapper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <LogoSquaredIcon />
        <LanguageControl
          sx={{
            px: "15px !important",
            py: "10px !important",
            color: "white",
            border: "none",
            bgcolor: COLORS.indigo,
            borderRadius: "50px",
            ":hover": { bgcolor: COLORS.indigo },
          }}
          variant="contained"
          color="primary"
          startIcon={undefined}
          endIcon={<DownArrow color="white" />}
        />
      </Box>
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          textAlign="center"
          lineHeight="32px"
          fontSize="29px"
          mt={2.5}
        >
          {t("adaptive.title")}
        </Typography>
        <Typography
          lineHeight="19.5px"
          textAlign="center"
          fontWeight={400}
          maxWidth="247px"
          mx={"auto"}
          mt={0.5}
        >
          {t("adaptive.subtitle")}
        </Typography>
        <Box
          mb={1.5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            mt: "28px",
          }}
        >
          <ButtonBase
            target={"_blank"}
            href="https://play.google.com/store/apps/details?id=com.uznewmax.mytaxi"
          >
            <img src="/icons/google-play.svg" width="auto" height="47.43px" />
          </ButtonBase>
          <ButtonBase
            target={"_blank"}
            href="https://apps.apple.com/ru/app/mytaxi-uz/id865012817"
          >
            <img src="/icons/apple-store.svg" width="auto" height="47.43px" />
          </ButtonBase>
        </Box>
      </Box>
      <Box
        sx={{
          px: "20px",
          pt: "28px",
          pb: "20px",
          bgcolor: COLORS.indigo,
          borderRadius: "14px",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "280px",
          maxWidth: "310px",
          width: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src="/driver-image.png"
          style={{ width: "170px", position: "absolute", bottom: -2, left: 0 }}
        />
        <Box sx={{ position: "relative", zIndex: 1 }}>
          <Typography variant="h2" color="white" align="center">
            {t("adaptive.driver-card.title")}
          </Typography>
          <Typography
            color="#A7A2EA"
            fontWeight="400"
            align="center"
            mt={1}
            maxWidth="247px"
          >
            {t("adaptive.driver-card.subtitle")}
          </Typography>
        </Box>
        <Button
          fullWidth
          component="a"
          href="https://drivers.mytaxi.uz/"
          target="_blank"
        >
          {t("adaptive.driver-card.action-button")}
        </Button>
      </Box>
    </AdaptiveWrapper>
  );
};

export default Adaptive;
