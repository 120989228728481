import { IconType } from "../../types/utility.types";

const UzcardIcon: IconType = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.50144 2.84127C3.49507 2.56658 3.74765 2.32711 4.02009 2.33761C5.94518 2.33423 7.8699 2.33798 9.79462 2.33573C9.98162 2.32561 10.1907 2.35634 10.3174 2.50924C10.4767 2.6805 10.4429 2.92784 10.4448 3.1422C10.4444 7.17676 10.4414 11.2113 10.4441 15.2455C10.4542 16.1434 10.5482 17.0589 10.8964 17.895C11.1433 18.4845 11.5353 19.035 12.0888 19.3711C12.6843 19.7418 13.4177 19.8257 14.1035 19.7444C14.7211 19.6777 15.3226 19.4071 15.7494 18.9503C16.307 18.3653 16.5739 17.5675 16.7001 16.7839C16.8418 15.9542 16.7972 15.1106 16.804 14.2734C16.8047 13.8604 16.804 13.4478 16.8036 13.0352C19.1188 13.0341 21.434 13.0337 23.7492 13.0345C23.7436 14.4229 23.7893 15.817 23.6135 17.198C23.4865 18.2589 23.2579 19.3123 22.8749 20.3121C22.5133 21.249 22.0066 22.1379 21.3298 22.8837C20.7205 23.5661 19.9698 24.1147 19.1548 24.5266C18.2876 24.9639 17.3485 25.2457 16.3955 25.4219C15.0366 25.6647 13.6467 25.7149 12.2706 25.6246C10.8986 25.5249 9.5248 25.2337 8.28213 24.6293C7.02184 24.024 5.92907 23.071 5.1747 21.8925C4.38322 20.6666 3.94177 19.2456 3.71317 17.8129C3.55203 16.7914 3.49282 15.7555 3.50069 14.722C3.50144 10.762 3.49919 6.80163 3.50144 2.84127Z"
      fill="#1C4585"
    />
    <path
      d="M16.8038 2.87949C16.7959 2.58269 17.0676 2.31774 17.3651 2.3391C17.6758 2.3361 17.9869 2.34022 18.2975 2.33798C18.2919 3.77702 18.8889 5.20519 19.9176 6.21102C20.9208 7.21348 22.3287 7.79172 23.7471 7.78572C23.7494 9.08536 23.7498 10.385 23.7475 11.6846C21.4327 11.6854 19.1178 11.6858 16.803 11.6843C16.8038 8.74921 16.8015 5.81416 16.8038 2.87949Z"
      fill="#1C4585"
    />
    <path
      d="M19.6709 2.33924C20.8525 2.33324 22.0341 2.33811 23.2153 2.33661C23.4064 2.31038 23.5447 2.45503 23.6736 2.57121C23.7163 2.69487 23.7564 2.82266 23.7534 2.95533C23.7478 4.11181 23.7545 5.26829 23.7493 6.42477C22.4845 6.43976 21.2411 5.80568 20.4875 4.79385C19.9568 4.09532 19.6567 3.21765 19.6709 2.33924Z"
      fill="#FD8700"
    />
  </svg>
);

export default UzcardIcon;
