import { IconType } from "../../types/utility.types";

const WarningIcon: IconType = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4362 3.97762L22.4415 15.9532C23.1706 17.1856 23.1863 18.7132 22.4828 19.9603C21.7784 21.2089 20.4619 21.9842 19.0123 21.9983L4.9744 21.9983C3.53966 21.9866 2.22063 21.2089 1.51618 19.9592C0.812312 18.7105 0.828751 17.1795 1.55543 15.9576L8.56336 3.97767C9.2754 2.75196 10.5867 1.99834 12.0045 2C13.4221 2.00166 14.7329 2.7584 15.4362 3.97762ZM10.2912 4.98491L3.27808 16.9737C2.91427 17.5854 2.90604 18.3519 3.25844 18.9771C3.61123 19.6029 4.27193 19.9925 4.98253 19.9983L19.0025 19.9984C19.7287 19.9913 20.3881 19.603 20.7408 18.9777C21.0931 18.3533 21.0852 17.5885 20.7177 16.9673L13.7068 4.98222C13.3576 4.37673 12.7064 4.00083 12.0021 4C11.2978 3.99918 10.6464 4.37354 10.2912 4.98491ZM12.0003 17.9983C11.4479 17.9983 11 17.5506 11 16.9983C11 16.4461 11.4479 15.9983 12.0003 15.9983C12.5528 15.9983 13.0007 16.4461 13.0007 16.9983C13.0007 17.5506 12.5528 17.9983 12.0003 17.9983ZM11.003 7.99835H13.0036V14.9983H11.003V7.99835Z"
      fill="white"
    />
  </svg>
);

export default WarningIcon;
