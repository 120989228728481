import React from "react";
import { IconType } from "../../types/utility.types";

const FlagEn: IconType = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_253_167151)">
      <path d="M27 4.87988H1V23.1201H27V4.87988Z" fill="#F0F0F0" />
      <path d="M27 7.15969H1V9.43949H27V7.15969Z" fill="#D80027" />
      <path d="M27 11.7199H1V13.9997H27V11.7199Z" fill="#D80027" />
      <path d="M27 16.2795H1V18.5593H27V16.2795Z" fill="#D80027" />
      <path d="M27 20.8397H1V23.1195H27V20.8397Z" fill="#D80027" />
      <path d="M14 4.87988H1V14.7015H14V4.87988Z" fill="#2E52B2" />
      <path
        d="M6.06905 8.90334L5.85968 9.58114H5.18246L5.73045 9.99983L5.52107 10.6776L6.06905 10.2589L6.61668 10.6776L6.40736 9.99983L6.95534 9.58114H6.27807L6.06905 8.90334Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.27807 12.0271L6.06905 11.3493L5.85968 12.0271H5.18246L5.73045 12.4458L5.52107 13.1236L6.06905 12.7048L6.61668 13.1236L6.40736 12.4458L6.95534 12.0271H6.27807Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.416 12.0271L3.20693 11.3493L2.99756 12.0271H2.32034L2.86832 12.4458L2.65895 13.1236L3.20693 12.7048L3.75461 13.1236L3.54529 12.4458L4.09317 12.0271H3.416Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.20693 8.90334L2.99756 9.58114H2.32034L2.86832 9.99983L2.65895 10.6776L3.20693 10.2589L3.75461 10.6776L3.54529 9.99983L4.09317 9.58114H3.416L3.20693 8.90334Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.06905 6.45729L5.85968 7.13515H5.18246L5.73045 7.55388L5.52107 8.23163L6.06905 7.8129L6.61668 8.23163L6.40736 7.55388L6.95534 7.13515H6.27807L6.06905 6.45729Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.20693 6.45729L2.99756 7.13515H2.32034L2.86832 7.55388L2.65895 8.23163L3.20693 7.8129L3.75461 8.23163L3.54529 7.55388L4.09317 7.13515H3.416L3.20693 6.45729Z"
        fill="#F0F0F0"
      />
      <path
        d="M8.93116 8.90334L8.72184 9.58114H8.04462L8.59255 9.99983L8.38328 10.6776L8.93116 10.2589L9.47888 10.6776L9.26951 9.99983L9.81749 9.58114H9.14027L8.93116 8.90334Z"
        fill="#F0F0F0"
      />
      <path
        d="M9.14027 12.0271L8.93116 11.3493L8.72184 12.0271H8.04462L8.59255 12.4458L8.38328 13.1236L8.93116 12.7048L9.47888 13.1236L9.26951 12.4458L9.81749 12.0271H9.14027Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0024 12.0271L11.7933 11.3493L11.584 12.0271H10.9068L11.4547 12.4458L11.2454 13.1236L11.7933 12.7048L12.341 13.1236L12.1316 12.4458L12.6796 12.0271H12.0024Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.7933 8.90334L11.584 9.58114H10.9068L11.4547 9.99983L11.2454 10.6776L11.7933 10.2589L12.341 10.6776L12.1316 9.99983L12.6796 9.58114H12.0024L11.7933 8.90334Z"
        fill="#F0F0F0"
      />
      <path
        d="M8.93116 6.45729L8.72184 7.13515H8.04462L8.59255 7.55388L8.38328 8.23163L8.93116 7.8129L9.47888 8.23163L9.26951 7.55388L9.81749 7.13515H9.14027L8.93116 6.45729Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.7933 6.45729L11.584 7.13515H10.9068L11.4547 7.55388L11.2454 8.23163L11.7933 7.8129L12.341 8.23163L12.1316 7.55388L12.6796 7.13515H12.0024L11.7933 6.45729Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_253_167151">
        <rect x="1" y="5" width="26" height="18" rx="2.6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FlagEn;
