import { IconType } from "../../../types/utility.types";

const MastercardIcon: IconType = (props) => (
  <svg
    width={36}
    height={22}
    viewBox="0 0 36 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: "-4px", marginRight: "-4px" }}
    {...props}
  >
    <path
      d="M22.2679 2.99841L14.1514 3.02632L14.3973 17.8857L22.5139 17.8577L22.2679 2.99841Z"
      fill="#FF5F00"
    />
    <path
      d="M14.6871 10.4861C14.6369 7.4638 15.9908 4.77839 18.1114 3.0365C16.5107 1.78045 14.504 1.02513 12.3287 1.03258C7.17524 1.05023 3.07617 5.29569 3.16308 10.5255C3.24999 15.7554 7.48968 19.9723 12.6431 19.9546C14.8185 19.9472 16.7998 19.1782 18.3586 17.9113C16.1808 16.2105 14.7374 13.5083 14.6871 10.4861Z"
      fill="#EB001B"
    />
    <path
      d="M33.5057 10.3586C33.5927 15.5884 29.4936 19.8339 24.3401 19.8516C22.1648 19.859 20.1581 19.1037 18.5574 17.8476C20.7039 16.1057 22.0319 13.4203 21.9817 10.3981C21.9315 7.3758 20.4885 4.69997 18.3102 2.97281C19.869 1.70593 21.8504 0.936964 24.0257 0.929513C29.1791 0.911863 33.4193 5.15504 33.5057 10.3586Z"
      fill="#F79E1B"
    />
  </svg>
);

export default MastercardIcon;
