import {
  Avatar,
  Box,
  BoxProps,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import RationMiniStar from "../icons/rating-ministar-icon";
import { PanelItem } from "../main-panel/pannels/home-panel/home-panel.styles";

interface DriverInfoType extends BoxProps {
  img?: string | null;
  name?: string | null;
  rating?: number | null;
  tripsCount?: number | null;
}

const DriverInfo: React.FC<DriverInfoType> = ({
  img,
  name,
  rating,
  tripsCount,
  sx,
  ...props
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <PanelItem
      sx={{
        py: theme.spacing(1.5),
        px: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
      {...props}
    >
      <Avatar
        src={img || "/no_image.svg"}
        sx={{ width: 48, height: 48, mr: 1.5 }}
      >
        <img src="/no_image.svg" />
      </Avatar>
      <Box>
        <Typography variant="subtitle1">{name}</Typography>
        <Box sx={{ display: "flex", mt: 0.5 }}>
          {rating && (
            <Typography variant="caption" fontWeight={600} mr={1.5}>
              {t("rating")}:
              <Typography component="span" ml={0.5}>
                {rating}
                <RationMiniStar style={{ marginLeft: "3px" }} />
              </Typography>
            </Typography>
          )}
          {tripsCount && (
            <Typography variant="caption" fontWeight={600}>
              {t("trips")}:{" "}
              <Typography component="span">{tripsCount}</Typography>
            </Typography>
          )}
        </Box>
      </Box>
    </PanelItem>
  );
};

export default DriverInfo;
