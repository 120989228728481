const SignOutIcon = ({ color = "#FC5A5A", ...props }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0009 7.00002C15.0009 7.55231 15.4484 8.00002 16.0005 8.00002C16.5525 8.00002 17 7.55231 17 7.00002V6.00002C17 3.79088 15.2099 2.00002 13.0018 2.00002H7.00522C4.79704 2.00002 3.00696 3.79088 3.00696 6.00002L3.00696 18C3.00696 20.2092 4.79704 22 7.00522 22H13.0087C15.2169 22 17.007 20.2092 17.007 18V17C17.007 16.4477 16.5594 16 16.0074 16C15.4553 16 15.0078 16.4477 15.0078 17V18C15.0078 19.1046 14.1128 20 13.0087 20H7.00522C5.90113 20 5.00609 19.1046 5.00609 18L5.00609 6.00002C5.00609 4.89545 5.90113 4.00002 7.00522 4.00002H13.0018C14.1058 4.00002 15.0009 4.89545 15.0009 6.00002V7.00002Z"
      fill={color}
    />
    <rect
      x={20}
      y={11}
      width={2}
      height={12}
      rx={1}
      transform="rotate(90 20 11)"
      fill={color}
    />
    <path
      d="M18.2929 9.70713C17.9024 9.31661 17.9024 8.68344 18.2929 8.29292C18.6834 7.90239 19.3166 7.90239 19.7071 8.29292L22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L19.7071 15.7071C19.3166 16.0977 18.6834 16.0977 18.2929 15.7071C17.9024 15.3166 17.9024 14.6834 18.2929 14.2929L20.5858 12L18.2929 9.70713Z"
      fill={color}
    />
  </svg>
);

export default SignOutIcon;
