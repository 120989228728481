import { IconType } from "../../types/utility.types";

export const Phone: IconType = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6453 13.3529C12.3872 15.0933 14.4052 16.7589 15.203 15.9613C16.344 14.8205 17.0483 13.8259 19.5659 15.8491C22.0823 17.8711 20.149 19.2199 19.0432 20.3244C17.7668 21.6005 13.0089 20.3926 8.306 15.6918C3.60421 10.9898 2.39933 6.23287 3.67683 4.95673C4.78268 3.85 6.12511 1.91818 8.14755 4.43417C10.17 6.95016 9.17747 7.65424 8.03421 8.79617C7.23976 9.59376 8.90459 11.6114 10.6453 13.3529Z"
      fill="#3DD598"
    />
  </svg>
);
