import { IconType } from "../../types/utility.types";

const FlagUz: IconType = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1.30952"
      y="5.30952"
      width="25.381"
      height="17.9524"
      rx="2.16667"
      fill="white"
      stroke="#F5F5F5"
      strokeWidth="0.619048"
    />
    <mask
      id="mask0_253_167116"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="5"
      width="26"
      height="19"
    >
      <path
        d="M1.30952 7.47619C1.30952 6.27958 2.27957 5.30952 3.47619 5.30952H24.5238C25.7204 5.30952 26.6905 6.27957 26.6905 7.47619V21.0952C26.6905 22.2919 25.7204 23.2619 24.5238 23.2619H3.47619C2.27957 23.2619 1.30952 22.2919 1.30952 21.0952V7.47619Z"
        fill="white"
        stroke="white"
        strokeWidth="0.619048"
      />
    </mask>
    <g mask="url(#mask0_253_167116)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11.1905H27V5H1V11.1905Z"
        fill="#04AAC8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 23.5715H27V17.381H1V23.5715Z"
        fill="#23C840"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.36541 9.63945C6.07025 9.83712 5.71526 9.9524 5.33334 9.9524C4.30767 9.9524 3.4762 9.12093 3.4762 8.09526C3.4762 7.06958 4.30767 6.23811 5.33334 6.23811C5.71523 6.23811 6.0702 6.35338 6.36535 6.55102C6.33116 6.54876 6.29666 6.54762 6.26189 6.54762C5.40717 6.54762 4.71428 7.24051 4.71428 8.09524C4.71428 8.94996 5.40717 9.64286 6.26189 9.64286C6.29668 9.64286 6.3312 9.64171 6.36541 9.63945Z"
        fill="white"
      />
      <path
        d="M9.47476 6.45017L9.55815 6.70682H9.82801L9.60969 6.86544L9.69308 7.12209L9.47476 6.96347L9.25644 7.12209L9.33983 6.86544L9.12151 6.70682H9.39137L9.47476 6.45017Z"
        fill="white"
      />
      <path
        d="M10.4573 6.46657L10.5407 6.72322L10.8106 6.72322L10.5923 6.88184L10.6757 7.13849L10.4573 6.97987L10.239 7.13849L10.3224 6.88184L10.1041 6.72322L10.3739 6.72322L10.4573 6.46657Z"
        fill="white"
      />
      <path
        d="M11.4398 6.44514L11.5232 6.70179H11.793L11.5747 6.86041L11.6581 7.11706L11.4398 6.95845L11.2215 7.11706L11.3049 6.86041L11.0865 6.70179H11.3564L11.4398 6.44514Z"
        fill="white"
      />
      <path
        d="M11.4399 7.65526L11.5233 7.91191L11.7931 7.91191L11.5748 8.07053L11.6582 8.32718L11.4399 8.16856L11.2216 8.32718L11.3049 8.07053L11.0866 7.91191L11.3565 7.91191L11.4399 7.65526Z"
        fill="white"
      />
      <path
        d="M10.4571 7.65526L10.5405 7.91191L10.8103 7.91191L10.592 8.07053L10.6754 8.32718L10.4571 8.16856L10.2388 8.32718L10.3222 8.07053L10.1038 7.91191L10.3737 7.91191L10.4571 7.65526Z"
        fill="white"
      />
      <path
        d="M9.47452 7.65526L9.55791 7.91191L9.82777 7.91191L9.60945 8.07053L9.69284 8.32718L9.47452 8.16856L9.2562 8.32718L9.33959 8.07053L9.12127 7.91191L9.39113 7.91191L9.47452 7.65526Z"
        fill="white"
      />
      <path
        d="M8.49212 7.65527L8.57551 7.91192L8.84537 7.91192L8.62705 8.07053L8.71044 8.32718L8.49212 8.16857L8.2738 8.32718L8.3572 8.07053L8.13888 7.91192L8.40873 7.91192L8.49212 7.65527Z"
        fill="white"
      />
      <path
        d="M8.49704 8.96199L8.58043 9.21864L8.85029 9.21864L8.63197 9.37726L8.71536 9.63391L8.49704 9.47529L8.27872 9.63391L8.36211 9.37726L8.14379 9.21864L8.41365 9.21864L8.49704 8.96199Z"
        fill="white"
      />
      <path
        d="M9.47107 8.96199L9.55446 9.21864L9.82432 9.21864L9.606 9.37726L9.68939 9.63391L9.47107 9.47529L9.25275 9.63391L9.33614 9.37726L9.11782 9.21864L9.38768 9.21864L9.47107 8.96199Z"
        fill="white"
      />
      <path
        d="M10.4655 8.96199L10.5488 9.21864L10.8187 9.21864L10.6004 9.37726L10.6838 9.63391L10.4655 9.47529L10.2471 9.63391L10.3305 9.37726L10.1122 9.21864L10.3821 9.21864L10.4655 8.96199Z"
        fill="white"
      />
      <path
        d="M11.4398 8.96199L11.5232 9.21864L11.7931 9.21864L11.5747 9.37726L11.6581 9.63391L11.4398 9.47529L11.2215 9.63391L11.3049 9.37726L11.0866 9.21864L11.3564 9.21864L11.4398 8.96199Z"
        fill="white"
      />
      <path
        d="M7.52301 8.96198L7.6064 9.21863L7.87626 9.21863L7.65794 9.37725L7.74133 9.6339L7.52301 9.47528L7.30469 9.6339L7.38808 9.37725L7.16976 9.21863L7.43962 9.21863L7.52301 8.96198Z"
        fill="white"
      />
    </g>
  </svg>
);

export default FlagUz;
