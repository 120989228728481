import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../../../configs/theme.config";
import { OrderType } from "../../../../services/queries/use-get-order-history";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import AddressMap from "../../../../components/address-map/address-map";
import { PointType } from "../../../../types/global.types";

const Order: React.FC<{ order: OrderType; type: "finished" | "cancelled" }> = ({
  order,
  type,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const points: Array<PointType | null | undefined> = [
    order?.from,
    order?.route_points?.b,
    order?.route_points?.c,
    order?.route_points?.d,
    order?.route_points?.e,
    // order?.to,
  ].filter((point) => !!point?.address);

  const lastIndex = points.length - 1;

  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: "1px solid #F0F0F0",
        overflow: "hidden",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() =>
        order?.id && setSearchParams({ history: type, id: order.id.toString() })
      }
    >
      <Box
        sx={{
          position: "absolute",
          right: "2px",
          bottom: "13px",
          height: "39px",
        }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={order?.tariff.icon || ""}
          alt="car"
        />
      </Box>
      {/* <Box pt={1} px={1.5}>
				{points.map((point, i) => {
					return (
						<Box key={i} sx={{ display: 'flex', alignItems: 'flex-start', py: 0.5 }}>
							<SearchEllipseIcon
								width={'28px'}
								height={'28px'}
								style={{ minWidth: '28px', minHeight: '28px' }}
								fill={
									i !== 0
										? i === lastIndex
											? theme.palette.error.main
											: theme.palette.grey[700]
										: undefined
								}
							/>
							<Typography ml={0.5} maxWidth={`calc(100% - 28px - ${theme.spacing(0.5)})`}>
								{point?.address}
							</Typography>
						</Box>
					);
				})}
			</Box> */}
      <AddressMap
        py={0}
        mt={0.5}
        listItemProps={{ py: 0 }}
        datalist={points}
        divider={false}
      />
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          py: 1.5,
          px: 2.5,
          mb: -0.02,
          mt: 1.5,
          display: "flex",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {type === "finished" ? (
            <>
              <Typography lineHeight="15px">
                {dayjs(order?.time).format("hh:mm")}
              </Typography>
              <Box sx={{ mb: "5px", mx: 0.5 }}>
                <svg
                  width="4"
                  height="4"
                  viewBox="0 0 4 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="1.75" cy="2" r="1.75" fill="#434343" />
                </svg>
              </Box>
              <Typography lineHeight="15px">
                {`${
                  order?.total_cost &&
                  order?.total_cost
                    .toString()
                    .split("")
                    .reverse()
                    .map((e, i) => (i % 3 == 2 ? " " + e : e))
                    .reverse()
                    .join("")
                } 
						${t("sum")}`}
              </Typography>
            </>
          ) : (
            <Typography color="#858585" pr={11}>
              {order?.cancel_reason}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Order;
