import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { DriverNumberHr, DriverNumberWrapper } from "./driver-number.styles";

const DriverNumber: React.FC<{ carNumber?: string | null }> = ({ carNumber }) =>
  carNumber ? (
    <DriverNumberWrapper mb={1}>
      {carNumber && (
        <Typography lineHeight={"23px"}>{carNumber.slice(0, 2)}</Typography>
      )}
      <DriverNumberHr />
      <Typography lineHeight={"23px"}>{carNumber?.slice(2)}</Typography>
    </DriverNumberWrapper>
  ) : (
    <Skeleton
      variant="rectangular"
      sx={{ width: "120px", height: "32px", borderRadius: "6px", mb: 1 }}
    />
  );

export default DriverNumber;
