import { IconType } from "../../types/utility.types";

const ScrollButtonRightIcon: IconType = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7101 11.9998L9.17006 15.5398C8.98381 15.7272 8.87926 15.9806 8.87926 16.2448C8.87926 16.509 8.98381 16.7624 9.17006 16.9498C9.26302 17.0435 9.37362 17.1179 9.49548 17.1687C9.61734 17.2195 9.74804 17.2456 9.88006 17.2456C10.0121 17.2456 10.1428 17.2195 10.2646 17.1687C10.3865 17.1179 10.4971 17.0435 10.5901 16.9498L14.8301 12.7098C14.9238 12.6168 14.9982 12.5062 15.0489 12.3844C15.0997 12.2625 15.1259 12.1318 15.1259 11.9998C15.1259 11.8678 15.0997 11.7371 15.0489 11.6152C14.9982 11.4934 14.9238 11.3828 14.8301 11.2898L10.5901 6.99981C10.4966 6.90713 10.3858 6.8338 10.264 6.78404C10.1421 6.73427 10.0117 6.70905 9.88006 6.70981C9.74845 6.70905 9.61799 6.73427 9.49615 6.78404C9.37431 6.8338 9.2635 6.90713 9.17006 6.99981C8.98381 7.18717 8.87926 7.44062 8.87926 7.70481C8.87926 7.96899 8.98381 8.22244 9.17006 8.40981L12.7101 11.9998Z"
      fill="#8C8B88"
    />
  </svg>
);

export default ScrollButtonRightIcon;
