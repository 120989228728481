import {
  MultiLevelMenuState,
  MultiLevelMenuActions,
} from "./multi-level-menu.types";

export const goBack = (): MultiLevelMenuActions => ({
  type: "GO_BACK",
});

export const goForward = (
  data: Partial<MultiLevelMenuState>
): MultiLevelMenuActions => ({
  type: "GO_FORWARD",
  payload: {
    currentMenuNumber: data.currentMenuNumber,
    currentMenuTitle: data.currentMenuTitle,
  },
});
