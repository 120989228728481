import { Fragment } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { OrderType } from "../../../../services/queries/use-get-order-history";
import dayjs from "dayjs";
import Order from "./order";
import { useGetOrderHistoryInfinite } from "../../../../services/queries/use-get-order-history-infinite";
import InfiniteLoader from "../../../../components/infinite-loader/infinite-loader";
import { infinitePageCollector } from "../../../../utils/infinite-page-collector";
import theme from "../../../../configs/theme.config";
import { useTranslation } from "react-i18next";

const CanceledOrders = () => {
  const {
    data,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  } = useGetOrderHistoryInfinite("cancelled");
  const { t, i18n } = useTranslation();

  const collection = infinitePageCollector<OrderType>(data)?.filter((e) => e);

  return (
    <Stack spacing={1.5} pb={2.5}>
      <InfiniteLoader
        hasMore={hasNextPage}
        loadMore={fetchNextPage}
        loading={isFetchingNextPage || isFetching}
      >
        {isSuccess ? (
          collection?.length != 0 ? (
            collection?.map((order, i) => (
              <Fragment key={order?.id}>
                {(i === 0 ||
                  dayjs(collection?.[i - 1]?.time).format("DD MMMM, dddd") !==
                    dayjs(order?.time).format("DD MMMM, dddd")) && (
                  <Typography variant="h3">
                    {dayjs(order?.time)
                      .locale(i18n.language)
                      .format("DD MMMM, dddd")}
                  </Typography>
                )}
                <Order order={order} type="cancelled" />
              </Fragment>
            ))
          ) : (
            <Box
              width={"100%"}
              height="300px"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h3" color={theme.palette.text.secondary}>
                {t("no trips yet")}
              </Typography>
            </Box>
          )
        ) : (
          [1, 2, 3, 4].map((order) => (
            <Skeleton
              variant="rectangular"
              key={order}
              sx={{
                height: "131px",
                width: "100%",
                borderRadius: "12px",
              }}
            />
          ))
        )}
      </InfiniteLoader>
    </Stack>
  );
};

export default CanceledOrders;
