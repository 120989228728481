import { IconType } from "../../types/utility.types";

const TelegramInnerIcon: IconType = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.60547 19.2325C10.0499 19.2325 10.2463 19.0292 10.4944 18.788L12.8647 16.4832L9.90799 14.7002"
      fill="#30A7DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9075 14.7007L17.0719 19.9939C17.8895 20.445 18.4796 20.2114 18.6832 19.2349L21.5995 5.49218C21.8981 4.29512 21.1432 3.75218 20.3611 4.10727L3.23663 10.7104C2.06772 11.1792 2.07454 11.8314 3.02356 12.1219L7.41808 13.4935L17.5919 7.07501C18.0721 6.78377 18.5129 6.94035 18.1511 7.26144"
      fill="#30A7DC"
    />
  </svg>
);

export default TelegramInnerIcon;
