export const numberCleaner = (number: string) => number.replace(/\D/g, "");

export const formatUZB = (text: string = "") => {
  const number = text.split("");

  let order = 0;

  let symbols = [
    { order: 0, symbol: "+" },
    { order: 3, symbol: " " },
    { order: 3, symbol: "(" },
    { order: 5, symbol: ")" },
    { order: 5, symbol: " " },
    { order: 8, symbol: "-" },
    { order: 10, symbol: "-" },
  ];

  symbols.map((item, i) => {
    if (item.order + order < number.length) {
      number.splice(item.order + order, 0, item.symbol);
      order += 1;
    }
  });

  return number.join("");

  // return (
  // 	'+' + numberCleaner(text).replace(/(\d\d\d)(\d\d)(\d\d\d)(\d\d)(\d\d)/, '$1 ($2) $3-$4-$5')
  // );
};

export const numberCheck = (number: string): string | undefined => {
  if (number === "+") {
    number = "998";
  }

  if (numberCleaner(number).length > 13) {
    number = "+998" + numberCleaner(number).slice(-9);
  }

  if (
    !(
      number.length < 20 &&
      (number.startsWith("998") || number.startsWith("+998"))
    )
  )
    return undefined;

  number = numberCleaner(number);

  return formatUZB(numberCleaner(number)) || "";
};
