import { createGlobalStyle } from "styled-components";
import "./fonst.css";

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif !important; 
  }
  html {
  font-size: 62.5%;
}
  body {
    height: 100%;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    margin: 0;
    overflow: hidden;
  }
  a { 
      text-decoration: none;
      border: none;
      outline: none;
  }
  .map-container {
  height: 100vh;
}

.mapboxgl-user-location-accuracy-circle {
  width: 0 !important;
  height: 0 !important;
  display: none;

}

.btn-s {
  width: 119px;
  height: 44px;
  left: 1273px;
  border: none;
  outline: none;
  top: 16px;
  color: #434343;
  background: #FEDD33;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9.8px; 
}
`;
