import React from "react";
import { IconType } from "../../types/utility.types";

export const CloseIcon: IconType = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.69252 7.00001L13.3516 2.3518L13.3516 2.35172C13.5775 2.12583 13.7044 1.81946 13.7044 1.50001C13.7044 1.18056 13.5775 0.874186 13.3516 0.648298C13.1258 0.42241 12.8194 0.295508 12.4999 0.295508C12.1805 0.295508 11.8741 0.42241 11.6482 0.648298L11.6481 0.64838L6.99993 5.30742L2.35173 0.64838L2.35164 0.648298C2.12576 0.42241 1.81939 0.295508 1.49993 0.295508C1.18048 0.295508 0.87411 0.42241 0.648222 0.648298C0.422334 0.874186 0.295432 1.18056 0.295432 1.50001C0.295432 1.81946 0.422334 2.12583 0.648222 2.35172L0.648304 2.3518L5.30735 7.00001L0.648511 11.648C0.648455 11.6481 0.648399 11.6481 0.648344 11.6482C0.535948 11.7597 0.446734 11.8924 0.385841 12.0385C0.324919 12.1847 0.293553 12.3416 0.293553 12.5C0.293553 12.6584 0.324919 12.8153 0.385841 12.9615C0.446711 13.1076 0.535883 13.2402 0.648219 13.3517C0.759715 13.4641 0.892333 13.5532 1.03844 13.6141C1.18467 13.675 1.34152 13.7064 1.49993 13.7064C1.65834 13.7064 1.81519 13.675 1.96142 13.6141C2.10762 13.5532 2.24031 13.4639 2.35185 13.3515C2.35188 13.3515 2.3519 13.3515 2.35193 13.3514L6.99993 8.6926L11.6479 13.3514C11.648 13.3515 11.648 13.3515 11.648 13.3515C11.7596 13.4639 11.8922 13.5532 12.0384 13.6141C12.1847 13.675 12.3415 13.7064 12.4999 13.7064C12.6583 13.7064 12.8152 13.675 12.9614 13.6141C13.1075 13.5532 13.2401 13.4641 13.3516 13.3517C13.464 13.2402 13.5532 13.1076 13.614 12.9615C13.6749 12.8153 13.7063 12.6584 13.7063 12.5C13.7063 12.3416 13.6749 12.1847 13.614 12.0385C13.5531 11.8923 13.4639 11.7596 13.3514 11.6481C13.3514 11.6481 13.3514 11.648 13.3514 11.648L8.69252 7.00001Z"
      fill="#767676"
      stroke="#767676"
      strokeWidth="0.2"
    />
  </svg>
);
