const privacyPolicyForDrivers = `<style>  ul, p, h1, h2, h3, h4, h5, h6 { margin-bottom: 8px }; h1 { font-size: 3rem; } h2 { font-size: 2.25rem; } h3 { font-size: 1.755rem; } h4 { font-size: 1.5rem; } h5 { font-size: 1.245rem; } h6 { font-size: 1.005rem; } p,div { font-size: 1.5rem; } li { margin-left: 32px };</style>
<div><p>"MyTaxi Ops" LLC (Company Registration Number 009457-11) located at Yakkasaray district, B.Babayeva st. 7, Tashkent, Uzbekistan, is the controller of personal data of passengers and has appointed a Data Protection Officer (<a href="mailto:info@mytaxi.uz">info@mytaxi.uz</a>).</p>
<p>The term "us" or "we" refers to the owner of the MyTaxi app, "MyTaxi Ops" LLC, a private limited company, founded in the Republic of Uzbekistan.</p>
<p><strong>1. Personal data we process</strong></p>
<ul>
<li>Name, phone number, place of residence.</li>
<li>Geolocation of drivers and driving routes.</li>
<li>Information about vehicles (including registration number).</li>
<li>Driver's efficiency and ratings.</li>
<li>Driver's license, photo, profession and identity documents.</li>
<li>Data about criminal convictions and offences. The financial data of providing transportation services is not considered as personal data, because drivers provide services in the course of economic and professional activities.</li>
</ul>
<p><strong>2. Purposes of the processing</strong></p>
<ul>
<li>We collect and process personal data for the purpose of connecting passengers with drivers to help them move around cities more efficiently.</li>
<li>Geolocation and driving routes are processed to analyse the geographical area and give suggestions to the drivers. If you do not want to disclose your geolocation for passengers, you must close the MyTaxi app or indicate in the MyTaxi app that you are offline and currently are not providing transportation services.</li>
<li>Driver's license, profession, identity documents and criminal convictions and offences are processed to determine the compliance with the legal requirements and the suitability of pursuing a profession as a driver.</li>
<li>MyTaxi app displays driver's photo, name and vehicle details for the passengers to identify driver and vehicle.</li>
<li>You will receive summaries from the MyTaxi Driver Portal, which will include your efficiency and ratings as a driver. Summary and ratings about driver are necessary to provide a reliable services for passengers.</li>
</ul>
<p><strong>3. Legal basis</strong></p>
<ul>
<li>Personal data is processed for the performance of the contract concluded with the driver. The prerequisite for the use of the MyTaxi services is the processing of driver's identification and geolocation data.</li>
<li>Personal data may be processed on the ground of legitimate interest in investigating and detecting fraudulent payments.</li>
<li>Data regarding the criminal convictions and offences is processed for compliance with a legal obligation.</li>
</ul>
<p><strong>4. Recipients</strong></p>
<ul>
<li>Your personal data is only disclosed to passengers, whose order has been accepted by you. Passengers will see driver's name, vehicle, phone number, photo and geolocation data. Passengers also see driver's personal data in the receipt.</li>
<li>Depending on the location of the driver, the personal data may be disclosed to the MyTaxi local subsidiaries, representatives, affiliates, agents etc). Processing of personal data by will occur under the same conditions as established in this privacy notice.</li>
</ul>
<p><strong>5. Security and access</strong></p>
<ul>
<li>Any personal data collected in the course of providing services is transferred to and stored in the data centres of "Unitel" LLC (Beeline Cloud) which are located in the Uzbekistan. Only authorised employees of MyTaxi group companies and partners have access to the personal data and they may access the data only for the purpose of resolving issues associated with the use of the services (including disputes regarding transportation services).</li>
<li>MyTaxi group companies and partners can access personal data to the extent necessary to provide customer support.</li>
</ul>
<p><strong>6. Processing passengers personal data</strong></p>
<ul>
<li>You may not process the personal data of passengers without the permission of us. You may not contact any passenger or collect, record, store, grant access, use or cross-use the personal data provided by the passenger or accessible to you via the MyTaxi app for any reason other than for the purposes of fulfilling the transportation services.</li>
<li>You must comply with the rules and conditions for processing of personal data of passengers as set forth in the Privacy Notice for Passengers. If you violate the requirements for the processing of personal data of passengers, we may terminate your drivers account and claim damages from you.</li>
</ul>
<p><strong>7. Access, correction, retention, deletion and data portability</strong></p>
<ul>
<li>Personal data can be viewed and corrected in MyTaxi Driver Portal.</li>
<li>Your personal data will be stored as long as you have an active driver's account. If your account will be closed the personal data will be stored for additional 3 year period.</li>
<li>Data necessary for accounting purposes shall be stored for 7 years.</li>
<li>In the event of suspicions of a administrational or criminal offence, fraud or false information, the data shall be stored for 10 years.</li>
<li>In the event of disputes, the data shall be retained until the claim is satisfied or the expiry date of such claims.</li>
<li>We respond to the request for deleting and transferring personal data submitted by an e-mail within a month and specify the period of data deletion and transfer.</li>
</ul>
<p><strong>8. Dispute resolution</strong></p>
<ul>
<li>Disputes relating to the processing of personal data are resolved through customer support (<a href="mailto:support@mytaxi.uz">support@mytaxi.uz</a>) or by contacting MyTaxi's Data Protection Officer (<a href="mailto:privacy@bolt.eu">i</a><a href="mailto:nfo@mytaxi.uz">nfo@mytaxi.uz</a>).</li>
</ul>


</div>
</div></div>`;

export default privacyPolicyForDrivers;
