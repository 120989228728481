import {
  Box,
  Card,
  CircularProgress,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "../../configs/theme.config";
import Footer from "../../layouts/footer/footer";
import { useTranslation } from "react-i18next";

interface DocumentationPageProps {
  renderHTML: string;
  isLoading?: boolean;
  title: string;
}

const DocumentationPage: React.FC<DocumentationPageProps> = ({
  renderHTML,
  isLoading = false,
  title,
}) => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(850));

  return (
    <>
      <Box
        sx={{
          pt: isSmallScreen ? 0 : "108px",
          pb: isSmallScreen ? 0 : 4,
          px: isSmallScreen ? 0 : "48px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          bgcolor: theme.palette.background.default,
          overflowY: "auto",
          minHeight: "100vh",
        }}
      >
        <Card
          sx={{
            p: 3,
            mx: "auto",
            maxWidth: "1344px",
            width: "100%",
            borderRadius: isSmallScreen ? "0" : undefined,
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "22px",
                md: "36px",
              },
              color: "#2c2738", // need to verified by designer
              mb: 2,
            }}
          >
            {t(title)}
          </Typography>
          {isLoading ? (
            <>
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <CircularProgress size="80px" />
              </Box>
              {Array(30)
                .fill(4)
                .map((txt) => (
                  <Skeleton
                    variant="text"
                    width={Math.random() * 80 + 20 + "%"}
                    height="18px"
                  />
                ))}
            </>
          ) : (
            <Box dangerouslySetInnerHTML={{ __html: renderHTML }} />
          )}
        </Card>
        {!isSmallScreen && <Footer />}
      </Box>
    </>
  );
};

export default DocumentationPage;
