import { IconType } from "../../types/utility.types";

const SparkIcon: IconType = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2483 19.8426C9.14502 20.5774 10.0575 20.9999 10.5511 20.4458L18.0863 11.9855C18.4847 11.5381 18.2238 10.8273 17.6306 10.7439L12.7531 10.0584L13.5824 4.15767C13.6856 3.42283 12.7731 3.00033 12.2796 3.55447L4.74435 12.0148C4.34594 12.4621 4.60684 13.1729 5.20004 13.2563L10.0776 13.9418L9.2483 19.8426Z"
      fill="#725BFF"
    />
  </svg>
);

export default SparkIcon;
