import { useReducer } from "react";
import {
  MultiLevelMenuActions,
  MultiLevelMenuState,
} from "./multi-level-menu.types";

const initialState: MultiLevelMenuState = {
  currentMenuNumber: 0,
  currentMenuTitle: "home",
  menus: ["home"],
  direction: "forward",
};

const multilevelMenuReducer = (
  state: MultiLevelMenuState,
  action: MultiLevelMenuActions
): MultiLevelMenuState => {
  switch (action.type) {
    case "GO_FORWARD":
      return {
        ...state,
        /* eslint-disable */
        // @ts-expect-error
        currentMenuNumber: action.payload.currentMenuNumber,
        // @ts-expect-error
        currentMenuTitle: action.payload.currentMenuTitle,
        // @ts-expect-error
        menus: [...state.menus, action.payload.currentMenuTitle],
        direction: "forward",
        /* eslint-enable */
      };
    case "GO_BACK":
      return {
        ...state,
        currentMenuNumber:
          state.currentMenuNumber > 0 ? state.currentMenuNumber - 1 : 0,
        menus: state.menus.slice(0, -1),
        // @ts-expect-error
        currentMenuTitle: state.menus.at(-2),
        direction: "backward",
      };
    default:
      return state;
  }
};

const useMultilevelMenuReducer = () =>
  useReducer(multilevelMenuReducer, initialState);

export default useMultilevelMenuReducer;
