import { IconType } from "../../types/utility.types";

const ArrowRightBig: IconType = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.33332 16.0001L26.6667 16.0001"
      stroke="#434343"
      strokeWidth="2.96296"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 6.66669L5.33332 16L14.6667 25.3334"
      stroke="#434343"
      strokeWidth="2.96296"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRightBig;
