import { Box, Typography } from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { setAuthModalOpen } from "../../redux/slices/auth-slice";

const PrivateRoute: React.FC<{ children?: ReactNode | undefined }> = ({
  children,
}) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAuthenticated) dispatch(setAuthModalOpen());
  }, []);

  return isAuthenticated ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h1">
        This is private zone! Please, log in!
      </Typography>
    </Box>
  );
};

export default PrivateRoute;
