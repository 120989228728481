import { IconType } from "../../types/utility.types";

const LogoutIcon: IconType = (props) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.9085 32C18.9085 31.4213 19.1384 30.8664 19.5476 30.4572C19.9567 30.0481 20.5117 29.8182 21.0903 29.8182H40.7267V15.6364C40.7267 11.2727 36.119 8 31.9994 8H11.2722C9.24754 8.00217 7.30646 8.8074 5.87483 10.239C4.44321 11.6707 3.63797 13.6117 3.6358 15.6364V48.3636C3.63797 50.3883 4.44321 52.3293 5.87483 53.761C7.30646 55.1926 9.24754 55.9978 11.2722 56H33.0903C35.115 55.9978 37.0561 55.1926 38.4877 53.761C39.9193 52.3293 40.7245 50.3883 40.7267 48.3636V34.1818H21.0903C20.5117 34.1818 19.9567 33.952 19.5476 33.5428C19.1384 33.1336 18.9085 32.5787 18.9085 32Z"
      fill="#FC5A5A"
    />
    <path
      d="M59.7237 30.4576L48.8146 19.5485C48.4021 19.1566 47.8528 18.9414 47.2839 18.9487C46.715 18.9559 46.1714 19.1852 45.769 19.5875C45.3667 19.9899 45.1375 20.5334 45.1302 21.1024C45.1229 21.6713 45.3382 22.2206 45.7301 22.6331L52.9137 29.8181H40.7269V34.1817H52.9137L45.7301 41.3667C45.5189 41.5673 45.3501 41.8081 45.2335 42.075C45.1169 42.3419 45.0549 42.6294 45.0512 42.9206C45.0475 43.2118 45.1021 43.5007 45.2118 43.7705C45.3215 44.0403 45.4841 44.2853 45.69 44.4913C45.896 44.6972 46.141 44.8598 46.4108 44.9695C46.6806 45.0793 46.9696 45.1339 47.2608 45.1301C47.552 45.1264 47.8395 45.0644 48.1063 44.9478C48.3732 44.8312 48.614 44.6624 48.8146 44.4513L59.7237 33.5422C60.1325 33.133 60.3622 32.5783 60.3622 31.9999C60.3622 31.4215 60.1325 30.8668 59.7237 30.4576Z"
      fill="#FC5A5A"
    />
  </svg>
);

export default LogoutIcon;
