import styled from "styled-components";
import TextAreaAutoSize from "@mui/material/TextareaAutosize";
import { SearchPanelBarProps } from "./types";
import { COLORS } from "../../../../configs/colors.style";

export const AddressBarWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) => `0 ${theme.spacing(1.5)}`};
`;

export const AddressSearchBar = styled.div<SearchPanelBarProps>`
  position: relative;
  display: flex;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    display: ${({ buttonLine }) => (buttonLine ? "block" : "none")};
    height: 1px;
    width: 100%;
    bottom: 0;
    background-color: ${COLORS.divider};
  }
`;
export const SearchBarWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) =>
    `${theme.spacing(1.875)} ${theme.spacing(1.875)} ${theme.spacing(
      1.875
    )} ${theme.spacing(0.5)}`};
  width: 100%;
`;
export const AddressSearchBarIcon = styled.span`
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextareaWrapper = styled.span`
  max-height: 54px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  min-height: inherit;
`;

export const Textarea = styled(TextAreaAutoSize)`
  width: 100%;
  height: inherit;
  border-width: 0;
  border-radius: 0;
  overflow: hidden;
  outline: 0;
  margin: 0;
  vertical-align: top;
  background: none;
  resize: none;
  font-size: 15px;
  font-weight: 500;
  caret-color: ${({ theme }) => theme.palette.primary.main};
  color: ${COLORS.textGray};
  &::placeholder {
    color: ${COLORS.textGray};
  }
`;
