import React, { useContext, useState } from "react";
import { ModeTypes } from "../types";
import Button from "../../../../../components/buttons/button";
import { WelcomeAuthSubTitle, WelcomeAuthTitle } from "../styles";
import { NameAuthModalWrapper } from "./styles";
import ModalBackButton from "../../../../../components/buttons/ModalBackButton";
import { useEditProfile } from "../../../../../services/mutations/use-edit-profile";
import { modes } from "..";
import Input from "../../../../../components/input/input";
import theme from "../../../../../configs/theme.config";
import { AuthCtx } from "../context/auth";
import { setPhoneNumber } from "../context/auth-actions";
import { useTranslation } from "react-i18next";
import { useGetErrorMessages } from "../../../../../services/queries/use-get-error-messages";
import queryClient from "../../../../../configs/react-query.config";

export const NameAuth: React.FC<ModeTypes> = ({ setCurrentMode }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [first_name, setFirst_name] = useState("");
  const { mutate, isLoading } = useEditProfile();

  const { dispatch } = useContext(AuthCtx);
  const { data } = useGetErrorMessages();

  const nextStep = (e: any) => {
    e.preventDefault();

    if (first_name.length < 2) {
      setError(t("minimum charnum", { count: 2 }));

      return;
    }

    let firstname = first_name.split(" ")[0];
    let lastname = first_name.split(" ")[1];

    return mutate(
      {
        first_name: firstname || null,
        last_name: lastname || null,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("client-profile");
          dispatch(setPhoneNumber(""));
          setCurrentMode("");
        },
        onError: (err: any) =>
          setError(
            data?.[err?.response?.data?.error?.type]?.[i18n.language] ||
              err?.response?.data?.error?.message ||
              t("something went wrong")
          ),
      }
    );
  };

  const prevStep = () => {
    setCurrentMode(modes.SEND_CODE);
  };

  return (
    <div>
      <ModalBackButton onClick={prevStep} />
      <NameAuthModalWrapper onSubmit={nextStep}>
        <WelcomeAuthTitle>{t("your name")}</WelcomeAuthTitle>
        <WelcomeAuthSubTitle>{t("enter you name")}</WelcomeAuthSubTitle>
      </NameAuthModalWrapper>
      <form style={{ width: "100%" }} onSubmit={nextStep}>
        <Input
          fullWidth
          sx={{ mb: theme.spacing(2.5) }}
          inputProps={{
            autoFocus: true,
            placeholder: t("enter name"),
            value: first_name,
            onChange: (e) => {
              setError("");
              setFirst_name(e.target.value);
            },
          }}
          error={!!error}
          errorMessage={error}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={nextStep}
          loading={isLoading}
        >
          {t("ready")}
        </Button>
      </form>
    </div>
  );
};
