import { useQuery } from "react-query";
import useDebounce from "../../hooks/use-debounce";
import { Coordinate, TariffGroup } from "../../types/global.types";
import request from "../request";

export const useGetTariffs = (coordinates?: string | Coordinate | null) => {
  const debouncedValue = useDebounce(coordinates, 500);
  const coordinatesObject: Coordinate =
    typeof debouncedValue === "string"
      ? JSON.parse(debouncedValue || "{}")
      : coordinates;

  return useQuery(
    ["gettariffs", coordinatesObject],
    () =>
      request.public
        .get<{ groups?: TariffGroup[] }>(
          `v1/tariffs/lat/${coordinatesObject?.lat}/lon/${coordinatesObject?.lon}?density=xxhdpi`
        )
        .then((res) => res.data),
    {
      enabled:
        !!coordinates && !!coordinatesObject.lat && !!coordinatesObject.lon,
      keepPreviousData: true,
    }
  );
};
