import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import ArrowRightBig from "../icons/arrow-right-big";

export interface PageHeaderProps {
  right?: ReactElement;
  title: string;
  onBack?: boolean | (() => void);
  border?: boolean;
  fontSize?: string;
  iconSize?: string;
  fontWeight?: string | number;
  size?: "small" | "medium" | "large";
  pb?: string;
  mb?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  right,
  border,
  onBack,
  iconSize,
  fontSize,
  fontWeight = 700,
  size = "medium",
  pb,
  mb = "16px",
  title,
}) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const handleBackButtonClick = () =>
    typeof onBack == "function" ? onBack() : navigate(-1);

  if (size) {
    switch (size) {
      case "small":
        (iconSize = "20px"), (fontSize = "17px");
        break;
      case "medium":
        (iconSize = "24px"), (fontSize = "20px");
        break;
      case "large":
        (iconSize = "32px"), (fontSize = "23px");
        fontWeight = 700;
        break;
      default:
        (iconSize = "24px"), (fontSize = "20px");
        fontWeight = 700;
        break;
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        mb: mb,
        borderBottom: border ? `1px solid ${theme.palette.divider}` : "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: pb || theme.spacing(1.875),
        }}
      >
        {!!onBack && (
          <ButtonBase
            sx={{ display: "flex", alignItems: "center" }}
            onClick={handleBackButtonClick}
          >
            <ArrowRightBig width={iconSize} height={iconSize} />
          </ButtonBase>
        )}
        <Typography
          sx={{ lineHeight: "25px", ml: !!onBack ? theme.spacing(1.125) : 0 }}
          variant="h2"
          fontWeight={700}
          fontSize={fontSize}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ ml: "auto" }}>{right}</Box>
    </Box>
  );
};

export default PageHeader;
