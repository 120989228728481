import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/buttons/button";
import SignInIcon from "../../../../components/icons/sign-in";
import { useAppDispatch } from "../../../../redux/hook";
import { setAuthModalOpen } from "../../../../redux/slices/auth-slice";
import LanguageControl from "../language-control";

const UnauthorizedHeaderProfile = () => {
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <LanguageControl />
      <Button
        size="small"
        variant="contained"
        startIcon={<SignInIcon />}
        onClick={() => appDispatch(setAuthModalOpen())}
        sx={{ p: "7px 28px !important", whiteSpace: "nowrap" }}
      >
        {t("sign in")}
      </Button>
    </React.Fragment>
  );
};

export default UnauthorizedHeaderProfile;
