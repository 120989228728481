import DocumentationPage from "../../components/documentation-page";
import privacyPolicyForDrivers from "./constants";

const PrivacyPolicyForDriversPage = () => (
  <DocumentationPage
    renderHTML={privacyPolicyForDrivers}
    title={"Privacy Policy For Drivers"}
  />
);

export default PrivacyPolicyForDriversPage;
