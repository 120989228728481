import {
  FormControl,
  FormControlProps,
  FormHelperText,
  Typography,
  FilledInput,
  FilledInputProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface InputProp extends React.PropsWithChildren, FormControlProps {
  errorMessage?: string;
  label?: string;
  type?: "text" | "tel" | "password";
  title?: string;
  register?: any;
  inputProps?: FilledInputProps;
}

const Input: React.FC<InputProp> = ({
  errorMessage,
  type = "text",
  title,
  register,
  inputProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <FormControl {...props}>
      <Typography
        component="label"
        variant="caption"
        mb={(theme) => theme.spacing(1)}
        fontWeight="500"
      >
        {t(title || "")}
      </Typography>
      <FilledInput {...register} size="medium" type={type} {...inputProps} />
      {errorMessage && <FormHelperText error>{t(errorMessage)}</FormHelperText>}
    </FormControl>
  );
};

export default Input;
