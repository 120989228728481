import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../../configs/colors.style";

export const TarrifsPaper = styled(Box)`
  border-radius: 24px;
  background-color: #f8f8f8;

  @media screen and (max-width: 850px) {
    background-color: #fff;
    max-width: 100%;
  }
`;

export const TarrifsPageServiceTitle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  color: #5e5e5e;
`;

export const TarrifsPageServicePrice = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  text-align: end;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const ScrollButtonWrapper = styled.button`
  position: absolute;
  transform: translateY(-14%);
  min-width: 44px;
  min-height: 44px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.divider};
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 2px 3px 10px 0px #0000000d;
`;
