import { BoxProps } from "@mui/material";
import React from "react";
import CheckedIcon from "../icons/checked-icon";
import { AttributeContainer, Checkmark, Input, Label } from "./radio.styles";
import { AttributeContainerType } from "./radio.types";

interface RadioProps {
  name?: string;
  id?: string;
  value?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string | React.ReactElement;
  defaultChecked?: boolean;
  disabled?: boolean;
  wrapperProps?: AttributeContainerType;
  checked?: boolean;
}

const Radio: React.FC<RadioProps> = ({
  name,
  id,
  value,
  onChange,
  label,
  disabled,
  wrapperProps,
  checked,
}) => (
  <AttributeContainer disabled={disabled} p={0.75} {...wrapperProps}>
    <Label htmlFor={id}>
      {label}
      <Input
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />
      <Checkmark>
        <CheckedIcon />
      </Checkmark>
    </Label>
  </AttributeContainer>
);

export default Radio;
