import { IconType } from "../../types/utility.types";

const CheckedCircledIcon: IconType = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="13.9987" cy="13.9997" r="11.6667" fill="#3DD598" />
    <path
      d="M19.5638 9.12063C20.0034 8.64959 20.7417 8.62413 21.2127 9.06377C21.6838 9.50341 21.7092 10.2417 21.2696 10.7127L13.1029 19.4627C12.6764 19.9197 11.9654 19.9593 11.4908 19.5525L7.40743 16.0525C6.91822 15.6331 6.86156 14.8966 7.28089 14.4074C7.70021 13.9182 8.43673 13.8615 8.92594 14.2809L12.1602 17.0531L19.5638 9.12063Z"
      fill="white"
    />
  </svg>
);

export default CheckedCircledIcon;
