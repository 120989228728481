import React, { ImgHTMLAttributes, memo, useState } from "react";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  defaultImage?: string;
}

const Image: React.FC<ImageProps> = memo(({ defaultImage, ...props }) => {
  const [error, setError] = useState(false);

  return (
    <img
      {...props}
      src={error ? defaultImage : props?.src}
      onError={() => setError(true)}
    />
  );
});

export default Image;
