import { Box } from "@mui/material";
import styled from "styled-components";
import theme from "../../configs/theme.config";

export const ToastWrapper = styled(Box)<{ isOpen: boolean }>`
  position: fixed;
  right: ${({ theme }) => `${theme.spacing(6)}`};
  transition-duration: 0.3s;
  border-radius: ${theme.borderRadius.small};
  display: flex;
  align-items: center;
  max-width: 80%;
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0))" : "translateX(calc(100% + 96px))"};
  padding-top: ${theme.spacing(1.5)};
  padding-bottom: ${theme.spacing(1.5)};
  z-index: 1000;

  @media screen and (max-width: 1000px) {
    right: ${({ theme }) => `${theme.spacing(3)}`};
  }
`;
/* transform: ${noInternet.isOpen ? 'translateX(0))' : 'translateX(calc(100% + 96px))'}, */
