import { Box, Stack, Typography } from "@mui/material";
import { Modal } from "../../modal/modal";
import { Phone } from "../../icons/phone";
import theme from "../../../configs/theme.config";
import Button from "../../buttons/button";
import { useTranslation } from "react-i18next";
import { HelpIcon } from "../../icons/help";
import TelegramInnerIcon from "../../icons/telegram";

const ContactSupportModal: React.FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  const { t } = useTranslation();

  return (
    <Modal closeIcon={false} isOpen={isOpen} onClose={close}>
      <Stack direction="row" spacing={1} mx="auto">
        <HelpIcon width={64} height={64} />
      </Stack>
      <Typography variant="h2" mb={1} mt={1} mx="auto">
        {t("support")}
      </Typography>
      <Typography
        variant="subtitle2"
        fontWeight={400}
        color={theme.palette.text.secondary}
        textAlign="center"
        pb={2.5}
        lineHeight="23px"
      >
        {t("support subtitle")}
      </Typography>
      <Stack spacing={1}>
        <Button
          size="small"
          variant="stroke"
          color="success"
          sx={{ py: "12px !important", px: 2.25, position: "relative" }}
          href="tel:+998712021515"
        >
          <Box sx={{ position: "absolute", left: 18 }}>
            <Phone />
          </Box>
          <Box>
            <Typography color="inherit" variant="h5" textAlign="center">
              +998 (71) 202-15-15
            </Typography>
            <Typography variant="caption">Связаться с поддержкой</Typography>
          </Box>
        </Button>
        <Button
          size="small"
          variant="stroke"
          color="info"
          sx={{ py: "12px !important", px: 2.25, position: "relative" }}
          href="https://t.me/call_center"
        >
          <Box sx={{ position: "absolute", left: 18 }}>
            <TelegramInnerIcon />
          </Box>
          <Box>
            <Typography color="inherit" variant="body1" textAlign="center">
              {t("Telegram")}
            </Typography>
            <Typography variant="caption">Написать поддержке</Typography>
          </Box>
        </Button>
      </Stack>

      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={close}
        sx={{ mt: 2.5 }}
      >
        {t("back")}
      </Button>
    </Modal>
  );
};

export default ContactSupportModal;
