export const COLORS = {
  white: "#FFFFFF",
  lightBg: "#F8F8F8",
  divider: "#EFEDED",
  pointBg: "#FEDD33",
  textMain: "#434343",
  textSec: "#C8C8C8",
  inputFill: "#F9F9F9",
  inputText: "#2C2738",
  additionalRed: "#FC5A5A",
  additionalBlue: "#4B89FA",
  additionalGreen: "#3DD598",
  buttonSec: "#F9F9F9",
  buttonGrey: "#DEDEDE",
  buttonYellow: "#FEDD33",
  textGray: "#999999",
  greenStroke: "#ECFBF5",
  yellowStroke: "#FFFBEB",
  blueStroke: "#EDF5FF",
  errorStroke: "rgba(252, 90, 90, 0.1)",
  indigo: "#4D469E",
};
