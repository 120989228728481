import { IconType } from "../../../types/utility.types";

const VisaIcon: IconType = (props) => (
  <svg
    width={36}
    height={22}
    viewBox="0 0 36 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: "-4px", marginRight: "-4px" }}
    {...props}
  >
    <path
      d="M32.7134 6.149H30.5761C29.9299 6.149 29.4328 6.34783 29.1346 6.99402L25.0586 16.2892H27.9416C27.9416 16.2892 28.4387 15.0465 28.5381 14.7483C28.8363 14.7483 31.6696 14.7483 32.0673 14.7483C32.1667 15.0962 32.4152 16.2395 32.4152 16.2395H35L32.7134 6.149ZM29.3334 12.6606C29.5819 12.0641 30.4269 9.82731 30.4269 9.82731C30.4269 9.87702 30.6755 9.23083 30.7749 8.88288L30.9737 9.7776C30.9737 9.7776 31.5205 12.2132 31.6199 12.7103H29.3334V12.6606Z"
      fill="#3362AB"
    />
    <path
      d="M25.2557 12.959C25.2557 15.0466 23.3668 16.4384 20.4341 16.4384C19.1914 16.4384 17.9985 16.1899 17.3523 15.8917L17.7499 13.6051L18.0979 13.7543C18.9926 14.1519 19.5891 14.301 20.6827 14.301C21.478 14.301 22.323 14.0028 22.323 13.3069C22.323 12.8595 21.975 12.5613 20.8815 12.0642C19.8376 11.5672 18.4458 10.7719 18.4458 9.33036C18.4458 7.34209 20.3844 6 23.1183 6C24.1621 6 25.0569 6.19883 25.6036 6.44736L25.206 8.63446L25.0072 8.43564C24.5101 8.23681 23.8639 8.03798 22.9195 8.03798C21.8756 8.08769 21.3786 8.53505 21.3786 8.9327C21.3786 9.38007 21.975 9.72801 22.9195 10.1754C24.5101 10.921 25.2557 11.766 25.2557 12.959Z"
      fill="#3362AB"
    />
    <path
      d="M1 6.24863L1.04971 6.0498H5.3245C5.92098 6.0498 6.36834 6.24863 6.51746 6.89482L7.46189 11.3684C6.51746 8.98251 4.33036 7.04394 1 6.24863Z"
      fill="#F9B50B"
    />
    <path
      d="M13.4752 6.1491L9.15071 16.2396H6.21801L3.73267 7.78943C5.52211 8.93269 7.01332 10.7221 7.56009 11.9648L7.85834 13.0086L10.5425 6.0994H13.4752V6.1491Z"
      fill="#3362AB"
    />
    <path
      d="M14.6196 6.0994H17.3535L15.6138 16.2396H12.8799L14.6196 6.0994Z"
      fill="#3362AB"
    />
  </svg>
);

export default VisaIcon;
