import React from "react";
import { IconType } from "../../types/utility.types";

const CheckedIcon: IconType = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9999 0.333252C6.89992 0.333252 0.333252 6.89992 0.333252 14.9999C0.333252 23.0999 6.89992 29.6666 14.9999 29.6666C23.0999 29.6666 29.6666 23.0999 29.6666 14.9999C29.6666 6.89992 23.0999 0.333252 14.9999 0.333252ZM21.3572 12.5199C21.4743 12.3861 21.5634 12.2302 21.6194 12.0615C21.6753 11.8927 21.6969 11.7145 21.6829 11.5372C21.669 11.36 21.6197 11.1873 21.538 11.0294C21.4563 10.8715 21.3439 10.7315 21.2073 10.6177C21.0707 10.5039 20.9128 10.4185 20.7427 10.3667C20.5727 10.3148 20.3939 10.2975 20.2171 10.3157C20.0402 10.3339 19.8688 10.3873 19.7129 10.4728C19.557 10.5582 19.4198 10.674 19.3092 10.8132L13.5759 17.6919L10.6093 14.7239C10.3578 14.481 10.021 14.3466 9.67138 14.3497C9.32179 14.3527 8.98737 14.4929 8.74016 14.7402C8.49295 14.9874 8.35272 15.3218 8.34968 15.6714C8.34665 16.021 8.48104 16.3578 8.72392 16.6092L12.7239 20.6092C12.8549 20.7402 13.0118 20.8424 13.1845 20.9093C13.3572 20.9763 13.5419 21.0065 13.7269 20.9981C13.912 20.9897 14.0932 20.9428 14.2591 20.8605C14.425 20.7782 14.572 20.6622 14.6906 20.5199L21.3572 12.5199Z"
      fill="#F9D310"
    />
  </svg>
);

export default CheckedIcon;
