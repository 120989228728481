import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadState, saveState } from "../../utils/storage";

const doesExists = (value: any) =>
  typeof value === "boolean" ||
  typeof value === "number" ||
  typeof value === "string";

export interface SenderRecipientInfoType {
  phone_number: string | null;
  address: string | number | null;
  entrance: string | number | null;
  apartment: string | number | null;
  floor: string | number | null;
  door_intercom: string | null;
  comment: string | null;
  name: string | null;
}

interface DeliveryDetails {
  door_to_door: boolean;
  contactless_delivery: boolean;
  sender_info: SenderRecipientInfoType;
  recipient_info: SenderRecipientInfoType;
}

export type PaymentType =
  | "cash"
  | "payme"
  | "corporate_account"
  | string
  | null;

export type FixedPriceUnitsType = Partial<{
  air_conditioner_amount: number | null;
  cashback_amount: number | null;
  door_to_door_price: number | null;
}>;

type InitialStateType = {
  block_redirection: boolean;
  order_mode: "multi" | "single";
  active_orders: number[] | [];
  active_order_id: number | null;
  active_tab: string;
  payment_type_alias: PaymentType;
  price: number | null;
  tariff_id: number | null;
  use_bonus: boolean | null;
  comment: string | null;
  fixed_price_units: FixedPriceUnitsType;
  use_air_conditioner: boolean;
  air_conditioner_mutated: boolean;
  price_service_cost: number | null;
  fixed_client_cost: number | null;
  is_fixed_cost: boolean | null;
  promo_code: string | null;
  predicted_cost: number | null;
  predicted_distance: number | null;
  card_id: number | null;
  delivery_details: DeliveryDetails;
};

const sender_recipient_info = {
  phone_number: "998",
  address: "",
  name: "",
  entrance: "",
  apartment: "",
  floor: "",
  door_intercom: "",
  comment: "",
};

const initialState: InitialStateType = {
  block_redirection: false,
  order_mode: "single",
  active_orders: [],
  active_order_id: null,
  active_tab: "taxi",
  payment_type_alias: "cash", // payment method
  tariff_id: null, // tariff id
  use_bonus: false, // use cashback
  fixed_price_units: {
    // used only when route is specified
    air_conditioner_amount: null,
    cashback_amount: null,
    door_to_door_price: null,
  },
  promo_code: null, // promo code
  predicted_distance: null,
  card_id: null,
  use_air_conditioner: false,
  air_conditioner_mutated: false,
  price: null, // tarif price
  price_service_cost: null, // route price
  predicted_cost: null, // probably wouldn't be used
  fixed_client_cost: null, // end price
  comment: null,
  is_fixed_cost: null,
  delivery_details: {
    door_to_door: false,
    contactless_delivery: false,
    sender_info: sender_recipient_info,
    recipient_info: sender_recipient_info,
  },
};

const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setCardId: (state, action: PayloadAction<number | null>) => {
      state.card_id = action.payload;
    },
    setPaymentType: (state, action: PayloadAction<PaymentType>) => {
      state.payment_type_alias = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
    },
    setBonus: (
      state,
      action: PayloadAction<{
        use_bonus: boolean;
        cashback_amount?: number | null;
      }>
    ) => {
      state.use_bonus = action.payload.use_bonus;
      state.fixed_price_units.cashback_amount = action.payload.cashback_amount;
    },
    setAirConditioner: (
      state,
      action: PayloadAction<{
        use_air_conditioner: boolean;
        air_conditioner_mutated?: boolean;
      }>
    ) => {
      state.use_air_conditioner = action.payload.use_air_conditioner;

      if (action.payload.air_conditioner_mutated === true) {
        state.air_conditioner_mutated = action.payload.air_conditioner_mutated;
      }

      if (action.payload.use_air_conditioner === false) {
        state.fixed_price_units.air_conditioner_amount = null;
      }
    },
    setTarrifId: (state, action: PayloadAction<number>) => {
      state.tariff_id = action.payload;
    },
    setPredictedDistance: (state, action: PayloadAction<number | null>) => {
      state.predicted_distance = action.payload;
    },
    setPrice: (state, action: PayloadAction<number | null>) => {
      state.price = action.payload;
    },
    setPriceServiceCost: (state, action: PayloadAction<number | null>) => {
      state.price_service_cost = action.payload;
    },
    setFixedClientCost: (
      state,
      action: PayloadAction<{
        fixed_client_cost?: number | null;
        fixed_price_units?: FixedPriceUnitsType | null;
      }>
    ) => {
      const { fixed_client_cost, fixed_price_units } = action.payload;

      if (typeof fixed_client_cost === "number" || fixed_client_cost === null) {
        state.fixed_client_cost = fixed_client_cost;
        state.predicted_cost = fixed_client_cost;
      }

      if (
        typeof fixed_price_units?.air_conditioner_amount === "number" ||
        fixed_price_units?.air_conditioner_amount === null
      ) {
        state.fixed_price_units.air_conditioner_amount =
          fixed_price_units.air_conditioner_amount;
      }
    },
    setActiveOrderId: (state, action: PayloadAction<number | null>) => {
      state.active_order_id = action.payload;
    },

    resetOrderSlice: (state) => {
      state = initialState;

      return initialState;
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.active_tab = action.payload;
    },
    setDeliveryOptions: (
      state,
      action: PayloadAction<Partial<DeliveryDetails>>
    ) => {
      if (typeof action.payload.contactless_delivery === "boolean")
        state.delivery_details.contactless_delivery =
          action.payload.contactless_delivery;

      if (typeof action.payload.door_to_door === "boolean")
        state.delivery_details.door_to_door = action.payload.door_to_door;
    },
    setDeliveryParticipantsInfo: (
      state,
      action: PayloadAction<{
        details: Partial<SenderRecipientInfoType>;
        participant: "recipient" | "sender";
      }>
    ) => {
      if (action.payload.participant === "recipient") {
        for (const key in action.payload.details) {
          if (
            doesExists(
              action.payload.details[key as keyof SenderRecipientInfoType]
            )
          ) {
            // @ts-expect-error
            state.delivery_details.recipient_info[
              key as keyof SenderRecipientInfoType
            ] = action.payload.details[key as keyof SenderRecipientInfoType];
          }
        }
        action.payload.details;
      }

      if (action.payload.participant === "sender") {
        for (const key in action.payload.details) {
          if (
            doesExists(
              action.payload.details[key as keyof SenderRecipientInfoType]
            )
          ) {
            // @ts-expect-error
            state.delivery_details.sender_info[
              key as keyof SenderRecipientInfoType
            ] = action.payload.details[key as keyof SenderRecipientInfoType];
          }
        }
        action.payload.details;
      }
    },
    setDoorToDoorPrice: (
      state,
      action: PayloadAction<{ price: number | null }>
    ) => {
      state.fixed_price_units.door_to_door_price = action.payload.price;
    },
    setIsFixedClientCost: (state, action: PayloadAction<boolean>) => {
      state.is_fixed_cost = action.payload;
    },
    clearOrder: (state) => {
      return {
        ...initialState,
        payment_type_alias: state.payment_type_alias,
        tariff_id: state.tariff_id,
        active_order_id: state.active_order_id,
        card_id: state.card_id,
        active_tab: state.active_tab,
      };
    },
    setOrders: (state, action: PayloadAction<number[] | null>) => {
      state.active_orders = action.payload || [];
      state.order_mode =
        action?.payload && action.payload?.length > 1 ? "multi" : "single";
    },
    setBlockRedirection: (state, action: PayloadAction<boolean>) => {
      state.block_redirection = action.payload;
    },
  },
});

export const {
  setCardId,
  setPaymentType,
  setComment,
  setBonus,
  setAirConditioner,
  setTarrifId,
  setPredictedDistance,
  setPrice,
  setPriceServiceCost,
  setFixedClientCost,
  setActiveOrderId,
  resetOrderSlice,
  setActiveTab,
  setDeliveryOptions,
  setDeliveryParticipantsInfo,
  setDoorToDoorPrice,
  setIsFixedClientCost,
  clearOrder,
  setOrders,
  setBlockRedirection,
} = routeSlice.actions;
export default routeSlice.reducer;
