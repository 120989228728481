import { IconType } from "../../types/utility.types";

const BackArrow: IconType = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33332 10L16.6667 10"
      stroke="#434343"
      strokeWidth="1.85185"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16666 4.16663L3.33332 9.99996L9.16666 15.8333"
      stroke="#434343"
      strokeWidth="1.85185"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BackArrow;
