import {
  Box,
  BoxProps,
  Divider,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { PanelItem } from "../main-panel/pannels/home-panel/home-panel.styles";

interface DataMapType extends BoxProps {
  datalist?: {
    left?: ReactNode;
    right?: ReactNode;
    component?: ReactNode;
    hide?: boolean;
    key?: any;
  }[];
  divider?: boolean;
  listItemProps?: BoxProps;
  wrapper?: boolean;
}

const DataMap: React.FC<DataMapType> = ({
  datalist,
  divider = true,
  listItemProps,
  wrapper = true,
  ...props
}) => {
  const theme = useTheme();

  const filtered = datalist?.filter((item) => !item?.hide);
  const datalistLength = filtered?.length || 0;

  return wrapper ? (
    <PanelItem px={theme.spacing(1.5)} py={theme.spacing(0.5)} {...props}>
      {filtered?.map((data, i) => (
        <Box
          key={data.key ? data.key?.toString() : i}
          sx={{
            py: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom:
              i !== datalistLength - 1 && divider
                ? `1px solid ${theme.palette.divider}`
                : "none",
            ...listItemProps,
          }}
        >
          {data?.component ? (
            data.component
          ) : (
            <>
              {data.left && (
                <Typography component="span">{data.left}</Typography>
              )}
              {data.right && (
                <Typography component="span" fontWeight="500">
                  {data.right}
                </Typography>
              )}
            </>
          )}
        </Box>
      ))}
      {!datalist &&
        [0, 1, 2].map((item) => (
          <>
            <Skeleton
              key={item}
              sx={{ width: "100%", height: "30px", my: theme.spacing(0.5) }}
            />
            {item !== 2 && <Divider />}
          </>
        ))}
    </PanelItem>
  ) : (
    <>
      {filtered?.map((data, i) => (
        <Box
          key={data.key ? data.key?.toString() : i}
          sx={{
            py: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom:
              i !== datalistLength - 1 && divider
                ? `1px solid ${theme.palette.divider}`
                : "none",
            ...listItemProps,
          }}
        >
          {data.left && <Typography component="span">{data.left}</Typography>}
          {data.right && (
            <Typography component="span" fontWeight="500">
              {data.right}
            </Typography>
          )}
        </Box>
      ))}
      {!datalist &&
        [0, 1, 2].map((item) => (
          <>
            <Skeleton
              key={item}
              sx={{ width: "100%", height: "30px", my: theme.spacing(0.5) }}
            />
            {item !== 2 && <Divider />}
          </>
        ))}
    </>
  );
};

export default DataMap;
