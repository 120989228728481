import {
  Box,
  ButtonBase,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import AddressMap from "../../../../components/address-map/address-map";
import Button from "../../../../components/buttons/button";
import DataMap from "../../../../components/data-map/data-map";
import DriverNumber from "../../../../components/driver-number/driver-number";
import BackArrow from "../../../../components/icons/back-arrow";
import { HelpIcon } from "../../../../components/icons/help";
import { Phone } from "../../../../components/icons/phone";
import SparkIcon from "../../../../components/icons/spark";
import DriverInfo from "../../../../components/driver-info/driver-info";
import { PanelItem } from "../../../../components/main-panel/pannels/home-panel/home-panel.styles";
import ContactSupportModal from "../../../../components/modals/contact-support-modal/contact-support-modal";
import DriverContactModal from "../../../../components/modals/driver-contact-modal/driver-contact-modal";
import theme from "../../../../configs/theme.config";
import useModal from "../../../../hooks/use-modal";
import { useAppDispatch } from "../../../../redux/hook";
import { useGetOrderDetail } from "../../../../services/queries/use-get-order-detail";
import { useGetUserCards } from "../../../../services/queries/use-get-paycom-cards";
import DeleteIcon from "../../../../components/icons/delete";
import dayjs from "dayjs";
import store from "../../../../redux/store";
import {
  cardNumberFormatter,
  isCardPayment,
} from "../../../../components/main-panel/pannels/payment-types-pannel/cards";
import Money from "../../../../components/icons/money";
import { useDeleteOrderHistory } from "../../../../services/mutations/use-deleter-order-history";
import routeParser from "../../../../utils/route-parser";
import { getMinetsFromSeconds } from "../../../../utils/get-seconds-from-minets";

const OrderHistoryDetail: React.FC<{ orderID: number }> = ({ orderID }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetOrderDetail(orderID);
  const cacelErrorModal = useModal();
  const driverContactModal = useModal();
  const { data: cards, isLoading: cardsLoading } = useGetUserCards();
  const {
    mutate: deleteOrder,
    isLoading: isDeleting,
  } = useDeleteOrderHistory();
  const { t } = useTranslation();

  const orderStatus =
    searchParams.get("history") === "finished"
      ? "finished"
      : searchParams.get("history") === "canceled"
      ? "canceled"
      : null;

  const appDispatch = useAppDispatch();

  const repeat = () => {};

  return (
    <Box sx={{ bgcolor: theme.palette.background.default }}>
      <ContactSupportModal
        isOpen={cacelErrorModal.isOpen}
        close={cacelErrorModal.close}
      />
      <DriverContactModal
        isOpen={driverContactModal.isOpen}
        close={driverContactModal.close}
        driverNumber={data?.order.driver.phone || ""}
      />
      <Box
        sx={{
          px: 2.5,
          pt: 2.5,
          pb: 1.5,
          bgcolor: theme.palette.background.paper,
          borderRadius: "18px 18px 0 0",
          display: "flex",
          alignItems: "center",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <IconButton
          sx={{ ml: -1, mr: 1 }}
          onClick={() =>
            setSearchParams({ history: searchParams.get("history") || "" })
          }
        >
          <BackArrow />
        </IconButton>
        <Typography variant="h4" fontSize="20px" fontWeight="700">
          {t("myTrips")}
        </Typography>
      </Box>
      <Box p={2} pb={15}>
        <PanelItem
          sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            mb: 1,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <DriverNumber carNumber={data?.order.car.government_num_car} />
              {data?.order.car ? (
                <Typography
                  variant="caption"
                  fontWeight={500}
                  lineHeight={"18px"}
                >
                  {`${data?.order.car.color_car} ${data?.order.car.model_car}`}
                </Typography>
              ) : (
                <Skeleton
                  sx={{ width: "150px", height: "18px", maxWidth: "100%" }}
                />
              )}
            </Box>
            <Box sx={{ mr: "3.5px", height: "39px" }}>
              {data?.order?.tariff_icon && (
                <img
                  src={data?.order?.tariff_icon}
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", width: "100%", mt: 2 }}
          >
            <Button
              fullWidth
              variant="stroke"
              sx={{ padding: "8px !important", mr: 1, flexDirection: "column" }}
              onClick={cacelErrorModal.open}
            >
              <HelpIcon />
              <Typography
                sx={{
                  mt: 0.25,
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: "inherit",
                }}
              >
                {t("help")}
              </Typography>
            </Button>
            {/* <Button
							fullWidth
							variant="stroke"
							color="info"
							sx={{ padding: '8px !important', mr: 1, flexDirection: 'column' }}
						>
							<RepeatIcon />
							<Typography sx={{ mt: 0.25, fontSize: '12px', lineHeight: '14px', color: 'inherit' }}>
								Repeat
							</Typography>
						</Button> */}
            {searchParams.get("history") === "finished" && (
              <Button
                fullWidth
                variant="stroke"
                color="success"
                sx={{
                  padding: "8px !important",
                  mr: 1,
                  flexDirection: "column",
                }}
                onClick={driverContactModal.open}
                href="tel:+998712021515"
              >
                <Phone />
                <Typography
                  sx={{
                    mt: 0.25,
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "inherit",
                  }}
                >
                  {t("call")}
                </Typography>
              </Button>
            )}
          </Box>
        </PanelItem>

        <DataMap
          mb={1}
          datalist={[
            { left: t("tariff"), right: data?.order.tariff_name },
            {
              left: t("payment type"),
              right: (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  {data?.order.details.payment.alias === "cash" && <Money />}
                  <Box ml={0.5} mt={0.5}>
                    {(isCardPayment(data?.order.details.payment.alias)
                      ? cardNumberFormatter(
                          cards?.cards?.find(
                            (card) => card.id === data?.order.card_id
                          )?.number
                        )
                      : data?.order.details.payment.title) || ""}
                  </Box>
                </Box>
              ),
            },
            { left: t("orders"), right: data?.order.id },
            {
              left: t("trip date"),
              right: dayjs(data?.order.time_order_start || "")
                .locale(
                  (store.getState().auth.language === "uz"
                    ? "uz-latn"
                    : store.getState().auth.language) || "en"
                )
                .format(`D MMMM, HH:mm`),
            },
            {
              left: t("trip duration"),
              right: getMinetsFromSeconds(data?.order.details.duration || 0),
              hide: searchParams.get("history") !== "finished",
            },
          ]}
          mt={1}
        />

        <PanelItem
          mb={1}
          sx={{ bgcolor: theme.palette.background.paper, overflow: "hidden" }}
        >
          {data?.order && (
            <AddressMap
              py={0}
              mt={0.5}
              px={2}
              listItemProps={{ py: 0.75 }}
              datalist={routeParser(data?.order)}
            />
          )}
          {searchParams.get("history") === "finished" && (
            <ButtonBase
              sx={{
                bgcolor: theme.palette.grey[300],
                p: 1.75,
                width: "100%",
                lineHeight: "15px",
              }}
              onClick={() =>
                data?.order?.id &&
                orderStatus &&
                setSearchParams({ order_route_id: data?.order.id.toString() })
              }
            >
              <Typography variant="body1" lineHeight="15px">
                {t("Show route")}
              </Typography>
            </ButtonBase>
          )}
        </PanelItem>
        <DriverInfo
          mb={1}
          img={data?.order.driver.photo}
          name={data?.order.driver.name}
          rating={data?.order.driver.rating}
          tripsCount={data?.order.driver.count_rate}
        />
        {searchParams.get("history") === "finished" && (
          <DataMap
            datalist={[
              {
                left: t("minimum amount"),
                right: (data?.order?.min_sum_by_tarif || 0) + ` ${t("sum")}`,
              },
              {
                left: t("path"),
                right:
                  (data?.order?.details.distance_cost || 0) + ` ${t("sum")}`,
              },
              {
                left: t("increased demand"),
                right: (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SparkIcon />
                    {(data?.order?.details.surge_price || 0) + ` ${t("sum")}`}
                  </Box>
                ),
                hide: !data?.order?.details.surge_price,
              },
              {
                left: t("price pending"),
                right:
                  (data?.order?.details.waiting_cost || 0) + ` ${t("sum")}`,
                hide: !data?.order?.details.waiting_cost,
              },
              {
                left: t("conditioner"),
                right:
                  (data?.order?.details.condition_cost || 0) + ` ${t("sum")}`,
                hide: !data?.order?.details.condition_cost,
              },
              {
                left: <Typography variant="h4">{t("total")}</Typography>,
                right: (
                  <Typography variant="h4">
                    {(data?.order?.details.total_cost || 0) + ` ${t("sum")}`}
                  </Typography>
                ),
              },
            ]}
            mt={1}
          />
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0,
          bgcolor: "white",
          borderRadius: "16px 16px",
          boxShadow: "-4px -8px 30px rgba(69, 69, 69, 0.06)",
          p: 2.5,
        }}
      >
        <Button
          fullWidth
          variant="stroke"
          color="error"
          onClick={() => {
            deleteOrder([orderID], {
              onSuccess: () => {
                setSearchParams({
                  history: searchParams.get("history") || "finished",
                });
              },
            });
          }}
          loading={isDeleting}
        >
          <DeleteIcon /> {t("Delete data")}
        </Button>
      </Box>
    </Box>
  );
};

export default OrderHistoryDetail;
