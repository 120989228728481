import { IconType } from "../../types/utility.types";

const AppLogo: IconType = (props) => (
  <svg
    width="132"
    height="36"
    viewBox="0 0 132 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.02062 0H123.979C128.391 0 132 3.60108 132 7.99981V28C132 32.399 128.39 36 123.979 36H8.02062C3.61073 36 0 32.4003 0 28V7.99981C0 3.59991 3.6094 0 8.02062 0Z"
      fill="#FCE300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.8357 0H123.979C128.391 0 132 3.59991 132 7.99981V28C132 32.4001 128.391 36 123.979 36H67.8357C63.4245 36 59.8151 32.4001 59.8151 28V7.99981C59.8151 3.59991 63.4245 0 67.8357 0Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.4132 23.3769C77.6626 23.7953 76.8968 24.0045 76.115 24.0045C75.3074 24.0045 74.6695 23.7554 74.2021 23.2575C73.7347 22.7591 73.5006 22.0329 73.5006 21.0792V13.6378H78.9205V10.9028H73.5006V6.00585H70.1851V10.9028H66.7738V13.6378H70.1851V21.3016C70.1851 23.2096 70.6951 24.6564 71.7155 25.6423C72.7353 26.628 74.1279 27.121 75.8918 27.121C77.1458 27.121 78.453 26.718 79.8131 25.9126L78.4132 23.3769ZM92.5366 22.6844C93.5788 21.6787 94.0996 20.38 94.0996 18.7888C94.0996 17.1988 93.5788 15.9005 92.5366 14.8947C91.4959 13.8876 90.2836 13.3841 88.9023 13.3841C87.4151 13.3841 86.1818 13.8771 85.2044 14.8623C84.2274 15.8476 83.7378 17.1578 83.7378 18.7888C83.7378 20.4214 84.2274 21.7316 85.2044 22.7167C86.1818 23.7021 87.4151 24.1951 88.9023 24.1951C90.2836 24.1951 91.4959 23.6914 92.5366 22.6844ZM97.4144 26.6757H94.0996V24.8632C92.6536 26.3689 90.7403 27.121 88.3598 27.121C86.2144 27.121 84.3699 26.3418 82.8293 24.7841C81.2887 23.2254 80.5178 21.228 80.5178 18.7888C80.5178 16.373 81.2938 14.3795 82.8449 12.8114C84.3972 11.2425 86.235 10.458 88.3598 10.458C90.7403 10.458 92.6536 11.2102 94.0996 12.7159V10.9036H97.4144V26.6757ZM116.009 26.6757H112.023L107.879 20.9834L103.735 26.6757H99.7808L105.455 18.9481L99.7808 10.8709H103.735L107.879 16.8496L112.023 10.8709H116.009L110.334 18.9481L116.009 26.6757ZM118.213 10.9033H121.528V26.6757H118.213V10.9033ZM121.369 5.07492C121.773 5.47742 121.974 5.96536 121.974 6.53779C121.974 7.13176 121.778 7.61884 121.385 8.00082C120.992 8.38226 120.487 8.57247 119.871 8.57247C119.254 8.57247 118.744 8.38226 118.34 8.00082C117.937 7.61884 117.734 7.13176 117.734 6.53779C117.734 5.96536 117.937 5.47742 118.34 5.07492C118.744 4.6721 119.254 4.47116 119.871 4.47116C120.466 4.47116 120.966 4.6721 121.369 5.07492Z"
      fill="#FCE300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.8454 11.3849C35.0038 12.5299 35.5828 14.0139 35.5828 15.8371V25.8861H32.2991V16.5047C32.2991 15.3601 31.9643 14.4428 31.2947 13.7539C30.6251 13.0649 29.7696 12.7207 28.7279 12.7207C27.4105 12.7207 26.3691 13.1763 25.6036 14.088C24.8386 14.9997 24.456 16.3247 24.456 18.0631V25.8861H21.14V16.5047C21.14 15.3601 20.8054 14.4428 20.1357 13.7539C19.466 13.0649 18.6108 12.7207 17.569 12.7207C16.2727 12.7207 15.247 13.1763 14.4924 14.088C13.7379 14.9997 13.3608 16.3247 13.3608 18.0631V25.8861H10.0447V10.1127H13.3608V11.8938C14.5723 10.4097 16.262 9.66794 18.4301 9.66794C19.5775 9.66794 20.614 9.92205 21.5386 10.431C22.4631 10.9396 23.1807 11.6499 23.6904 12.5613C25.0725 10.6325 27.0384 9.66794 29.589 9.66794C31.268 9.66794 32.6868 10.24 33.8454 11.3849ZM44.137 31.2921H40.4387L43.021 25.5044L36.1977 10.081H39.8963L44.8381 21.4657L49.9075 10.081H53.6057L44.137 31.2921Z"
      fill="black"
    />
  </svg>
);

export default AppLogo;
