import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "../../../../components/buttons";
import DownArrow from "../../../../components/icons/down-arrow";
import { useTranslation } from "react-i18next";
import LanguageControlInner from "./language-control-inner";
import { languages } from "../../../../configs/languages";

interface LanguageControlProps extends React.ComponentProps<typeof Button> {}

const LanguageControl: React.FC<LanguageControlProps> = ({ sx, ...props }) => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="gray"
        size="small"
        onClick={handleClick}
        endIcon={<DownArrow />}
        startIcon={
          languages?.find((lang) => lang.value === i18n.language)?.icon
        }
        sx={{
          py: "7px !important",
          whiteSpace: "nowrap",

          ...sx,
        }}
        {...props}
      >
        {languages?.find((lang) => lang.value === i18n.language)?.label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorPosition={{ top: 300, left: 1050 }}
        PaperProps={{
          sx: {
            top: "90px !important",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <LanguageControlInner languages={languages} />
      </Popover>
    </>
  );
};

export default LanguageControl;
