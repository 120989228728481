import { Box, Paper } from "@mui/material";
import styled from "styled-components";
import theme from "../../../../configs/theme.config";

export const HiderBg = styled.div`
  width: 100%;
  height: ${theme.spacing(3)};
  position: absolute;
  top: 0px;
  background-color: #f8f8f8;
  z-index: 1;
`;

export const MainPanelWrapper = styled(Paper)<{
  isFirstPage: boolean;
  isHeightAuto?: boolean;
  isMultiorder?: boolean;
}>`
	position: absolute;
	z-index: 30;
	flex-direction: column;
	display: flex;
	width: 375px;
	left: ${({ theme }) => `${theme.spacing(3)}`};
	top: ${({ isMultiorder }) => (isMultiorder ? "164px" : "96px")};
	/* ${({ isFirstPage, isHeightAuto }) =>
    isFirstPage && !isHeightAuto ? "bottom: 42px;" : ""} */
	transition: margin 1s ease 0s;
	max-height: ${({ isMultiorder }) =>
    isMultiorder ? "calc(100vh - 206px)" : "calc(100vh - 138px)"};
	overflow-x: hidden;
	overflow-y: hidden;
	/* height: ${({ isFirstPage, isHeightAuto }) =>
    isFirstPage && !isHeightAuto ? "100%" : "auto"}; */
	border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
	background-color: ${({ theme, isFirstPage }) =>
    isFirstPage
      ? theme.palette.background.default
      : theme.palette.common.white};
`;

export const MainPanelAction = styled(Box)<{ isTransparent?: boolean }>`
  position: absolute;
  bottom: 0;
  z-index: 32;
  left: 0;
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(2.5)}`};
  background-color: ${({ theme, isTransparent }) =>
    isTransparent ? "transparent" : theme.palette.common.white};
`;

export const MainPanelContent = styled.div`
  height: 100%;
  overflow: scroll;
  scrollbar-width: 0;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0;
  }
`;

export const PanelItem = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  transition: all 0.3s linear;
`;
