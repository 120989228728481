import { useQuery } from "react-query";
import { useAppSelector } from "../../redux/hook";
import request from "../request";
import { PaymentType } from "../../redux/slices/order-slice";
import { RoutePointsType } from "../../types/global.types";

export interface OrderDetailType {
  order: {
    id: number | null;
    time_order_start: string | null;
    card_id: number | null;
    time_order_finish: string | null;
    from_adres: string | null;
    to_adres: string | null;
    coordinates_a_x: number | null;
    coordinates_a_y: number | null;
    coordinates_b_x: number | null;
    coordinates_b_y: number | null;
    tariff_icon: string | null;
    tariff_id: number | null;
    tariff_name: string | null;
    category_name: string | null;
    min_sum_by_tarif: number | null;
    rating: number | null;
    route_points?: RoutePointsType | null;
    air_condition: {
      is_enabled: boolean | null;
      is_available: boolean | null;
      cost: number | null;
    };
    details: {
      distance_cost: number | null;
      waiting_cost: number | null;
      condition_cost: number | null;
      cashback_amount: number | null;
      tips_amount: number | null;
      surge_price: number | null;
      total_cost: number | null;
      payment: {
        title: string | null;
        alias: PaymentType;
      };
      order_rate: number | null;
      duration: number | null;
      distance: number | null;
    };
    driver: {
      driver_id: number | null;
      name: string | null;
      phone: string | null;
      photo: string | null;
      count_rate: number | null;
      rating: number | null;
    };
    car: {
      government_num_car: string | null;
      model_car: string | null;
      color_car: string | null;
    };
    promo: {
      code: string | null;
      message: string | null;
      amount: null;
    };
    delivery_detail: null;
  };
}

export const useGetOrderDetail = (id?: number | null | string) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery(
    ["order-detail", id],
    () =>
      request.private
        .get<OrderDetailType>(`v1/orders/${id}/`)
        .then((res) => res.data),
    {
      enabled: isAuthenticated && !!id,
    }
  );
};
