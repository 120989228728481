import { useMutation } from "react-query";
import request from "../request";

export interface useAuthorizeType {
  phone_number?: string;
  code: string;
  fcm_token: string;
}

export interface authResponseType {
  expires_in: number;
  id: number;
  refresh_token: string;
  token: string;
}

export const useAuthorize = () =>
  useMutation(({ phone_number, code, fcm_token }: useAuthorizeType) =>
    request.public
      .post<authResponseType>(
        `/v1/token?phone_number=${phone_number}&code=${code}&fcm_token=${fcm_token}`
      )
      .then((res) => res.data)
  );
